// --------------------------------------------------------------------------------
// <copyright file="index.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import Vue from 'vue';
import { createPinia, defineStore, PiniaVuePlugin } from 'pinia';
import Customer from '@/models/customer';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { localStorageService } from '@/library-services/localStorage.service';

localStorageService.removeVuex();
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export interface RootState {
  spinners: number;
  termsAcceptedSession: boolean;
  userInfoLoaded: boolean;
  customers: Customer[];
}

export const useRootStore = defineStore('root', {
  state: (): RootState => ({
    spinners: 0,
    termsAcceptedSession: false,
    userInfoLoaded: false,
    customers: [],
  }),
  actions: {
    showSpinner() {
      this.spinners++;
    },
    removeSpinner() {
      if (this.spinners > 0) {
        this.spinners--;
      }
    },
    setTermsAcceptedSession(value: boolean) {
      this.termsAcceptedSession = value;
    },
    setUserInfoLoaded(value: boolean) {
      this.userInfoLoaded = value;
    },
    setCustomers(value: Customer[]) {
      this.customers = value;
    },
  },
});

export default pinia;
