// --------------------------------------------------------------------------------
// <copyright file="misc.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { WidgetEntry } from '@/models/Charts/compositeWidgetDefinition';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Vue } from 'vue-property-decorator';
import { usersService } from '@/services/users.service';
import { currentLocale } from '@/utils/i18n';

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export function isEmpty(value: any): boolean {
  const safeObject = value ?? {};
  return (
    (typeof value === 'string' && !value.length) ||
    ((Array.isArray(safeObject) || isObject(safeObject)) && !Object.entries(safeObject).length)
  );
}

export function isObject(value: any): boolean {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export function isNil(object: any): object is null | undefined {
  return object === null || object === undefined;
}

export function omit<T>(object: T | null | undefined, keys: string[]): any {
  return omitBy(object, (key) => keys.includes(key));
}

export function omitBy<T>(object: T | null | undefined, predicate: (key: string) => boolean): any {
  if (isNil(object)) {
    return {};
  }

  const filteredEntries = Object.entries(object as any).filter(([key, _]) => !predicate(key));
  return Object.fromEntries(filteredEntries);
}

// Returns the value of up-to-two depth fields.
// getFieldPathValue(object, 'foo.bar') -> object['foo']['bar']
export function getFieldPathValue(object: any, fieldPath: string): any | null | undefined {
  if (fieldPath.includes('.')) {
    const [field, subfield] = fieldPath.toString().split('.');
    return object[field][subfield];
  } else {
    return object[fieldPath];
  }
}

export function loadScript(url: string) {
  const script = document.createElement('script');
  script.setAttribute('src', url);
  script.async = true;
  document.head.appendChild(script);
}

let uniqueIdCounter = 0;

export function uniqueId(): number {
  return uniqueIdCounter++;
}

export function valueOrDefault<T>(value: T | null | undefined, defaultValue: T) {
  return value ?? defaultValue;
}

export function addAutoRemovedEventListener(
  component: Vue,
  eventType: string,
  listener: EventListenerOrEventListenerObject,
) {
  window.addEventListener(eventType, listener);

  component.$once('hook:beforeDestroy', () => window.removeEventListener(eventType, listener));
}

/**
 * Takes an array of `WidgetEntry` or `null` entries and returns an indexed
 * list without the nulls. This is useful to leave easily add the index
 * to the `WidgetDefinition` and get rid of the nulls, but leaving the
 * gaps in there (as dashboards can have gaps).
 *
 * The function returns a `WidgetDefinition` list out of convenience.
 * Only one view makes actual use of the `WidgetEntry` as of the writing
 * of this comment. If this function returned `WidgetEntry[]`, this would
 * require us to refactor many views and potentially add many manual
 * casts to access `WidgetDefinition` methods.
 *
 * @param widgetDefinitions List of WidgetEntry or null entries
 * @returns Indexed and filtered list of WidgetDefinition types.
 */
export function indexedWidgetDefinitions(
  widgetDefinitions: Array<WidgetEntry | null>,
): WidgetDefinition[] {
  return widgetDefinitions
    .map((wd, index) => wd?.withIndex(index))
    .filter((wd): wd is WidgetDefinition => wd !== null);
}

export function downloadUrl(url: string, customDownloadFileName?: string) {
  const link = document.createElement('a');
  link.href = url;
  if (!isNil(customDownloadFileName)) {
    link.setAttribute('download', customDownloadFileName);
  }
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export async function downloadTermsAndConditionsPdf() {
  const url = await usersService.getTermsAndConditionsUrl(currentLocale());
  downloadUrl(url);
}

export function isVueComponent(c: any): c is Vue {
  return c?._isVue;
}
