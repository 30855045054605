
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { indexedWidgetDefinitions } from '@/utils/misc';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { dateRangeToDateStrings } from '@/utils/dates';
import NewFeatureBadge from '../NewFeatureBadge.vue';
import { BendLayoutTab } from './BendLayoutTab';
import { currentUser } from '@/utils/permissionUtils';
import { BetaFeature } from '@/models/enums/BetaFeature';
import BendSummary from '@/components/common/BendSummary.vue';

@Component({
  computed: {
    BendLayoutTab: () => BendLayoutTab,
  },
  components: {
    BendSummary,
    NewFeatureBadge,
    LayoutGrid,
    WidgetContainer,
  },
})
export default class BendLayout extends Vue {
  @Prop({ required: true })
  private activeTab!: BendLayoutTab;

  @Prop({ required: true })
  private selectedDevices!: string[];

  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private selectedTimespan!: FilterTimeSpanEnum;

  @Prop({ required: true })
  private selectedShifts!: number[];

  @Prop({ required: true })
  private selectedTimeAxisSpan!: FilterTimeAxisSpanEnum;

  private activeTabInternal = BendLayoutTab.BendOverview;
  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];
  private tableViewLayout = availableDashboardLayouts[DashboardLayout.OneByOne];
  // HACK: This shouldn't be needed but, without it, when we navigate
  // (not reload) to the Bend page, the widgets aren't rendered. It looks like
  // the dashboard-card elements aren't recreated after updating
  // widgetDefinition.
  private areWidgetDefinitionsReady = false;

  private widgetDefinitions: WidgetDefinition[] = [];

  @Watch('activeTab', { immediate: true })
  private updateActiveTabInternal() {
    this.activeTabInternal = this.activeTab;
    this.widgetDefinitions = this.createWidgetDefinitions();
    this.areWidgetDefinitionsReady = true;
  }

  private onTabChanged(tab: BendLayoutTab) {
    this.$emit('change:tab', tab);
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  private createWidgetDefinitions(): WidgetDefinition[] {
    const initialTimeFilter = this.isTrendsTabActive ? this.dateRangeString : this.selectedTimespan;

    return indexedWidgetDefinitions([
      new WidgetDefinition(
        this.isTrendsTabActive
          ? WidgetEnum.BendingAvailabilityHist
          : WidgetEnum.BendingAvailability,
        this.selectedDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(WidgetEnum.BendStatus, this.selectedDevices, undefined, []),
      new WidgetDefinition(
        this.isTrendsTabActive
          ? WidgetEnum.BendingTimeBetweenBendHist
          : WidgetEnum.BendingTimeBetweenBend,
        this.selectedDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        this.isTrendsTabActive
          ? WidgetEnum.BendingPerformancePartHist
          : WidgetEnum.BendingPerformancePart,
        this.selectedDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        WidgetEnum.BendingPerformance,
        this.selectedDevices,
        this.selectedTimespan,
        this.selectedShifts,
      ),
      new WidgetDefinition(
        this.isTrendsTabActive ? WidgetEnum.BendingPerformanceHist : WidgetEnum.BendingTopParts,
        this.selectedDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
    ]);
  }

  private updateAggregate(index: number, aggregates: number): void {
    this.$set(this.widgetDefinitions, index, this.widgetDefinitions[index].getCopy({ aggregates }));
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  @Watch('selectedDevices')
  @Watch('dateRangeString')
  @Watch('selectedTimespan')
  @Watch('selectedShifts')
  @Watch('selectedTimeAxisSpan')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((widgetDefinition) =>
      widgetDefinition.getCopy({
        deviceId: this.selectedDevices,
        timeFilter:
          this.isTrendsTabActive && widgetDefinition.widget !== WidgetEnum.BendingPerformance
            ? this.dateRangeString
            : this.selectedTimespan,
        shifts: this.selectedShifts,
        axisSpan: this.selectedTimeAxisSpan,
      }),
    );
  }

  @Watch('hasSummaryBetaFeature')
  private async onBetaToggled(isBetaEnabled: boolean, wasBetaEnabled: boolean) {
    if (wasBetaEnabled && !isBetaEnabled) {
      if (this.activeTab === BendLayoutTab.BendSummary) {
        this.changeToTab(BendLayoutTab.BendOverview);
      }
    } else if (this.activeTab === BendLayoutTab.BendOverview) {
      this.changeToTab(BendLayoutTab.BendSummary);
    }
  }

  private get ganttChartWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(WidgetEnum.BendStatesGanttTable, this.selectedDevices);
  }

  @Watch('hasBendIntradayStatesBetaFeature')
  private async onBetaToggledIntraday(isBetaEnabled: boolean, wasBetaEnabled: boolean) {
    if (wasBetaEnabled && !isBetaEnabled) {
      if (this.activeTab === BendLayoutTab.BendIntradayStates) {
        this.changeToTab(BendLayoutTab.BendOverview);
      }
    }
  }

  private changeToTab(tab: BendLayoutTab) {
    this.activeTabInternal = tab;
    this.onTabChanged(tab);
  }

  private get hasSummaryBetaFeature() {
    return currentUser().hasBetaFeature(BetaFeature.WorkCenterConsoleBendSummary);
  }

  private get isTrendsTabActive(): boolean {
    return this.activeTabInternal === BendLayoutTab.BendTrends;
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }

  private get hasBendIntradayStatesBetaFeature() {
    return currentUser().hasBetaFeature(BetaFeature.WorkCenterConsoleBendIntradayStates);
  }
}
