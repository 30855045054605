
import { Device } from '@/models/device';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { MachineType } from '@/models/enums/MachineType';
import { Shift } from '@/models/shift';
import { isEmpty, isNil } from '@/utils/misc';
import { devicesService } from '@/services/devices.service';
import { shiftsService } from '@/services/shifts.service';
import { areArraysEqual } from '@/utils/array';

@Component({
  methods: { isEmpty },
  components: {
    ClickMenu,
  },
})
export default class ShiftFilter extends Vue {
  @Prop()
  private machineType?: MachineType;

  @Prop({ required: true })
  private selectedDeviceIds!: string[];

  @Prop({ default: 'is-bottom-left' })
  private position!: string;

  @Prop({ required: true })
  private value!: number[];

  private deviceIdDhs: string[] = [];

  private mounted() {
    this.loadDeviceIdDhs();
  }

  private loadDeviceIdDhs() {
    this.deviceIdDhs = isEmpty(this.selectedDeviceIds)
      ? devicesService.store
          .get()
          .filter(
            (device: Device) =>
              !isNil(this.machineType) && Device.GetMachineType(device) === this.machineType,
          )
          .map((x: Device) => x.deviceId)
      : this.selectedDeviceIds;
  }

  private get shifts(): Shift[] {
    return shiftsService.store.getAllByDeviceIds(this.deviceIdDhs);
  }

  @Watch('selectedDeviceIds')
  private watchChange(newValue: string[], oldValue: string[]) {
    if (!areArraysEqual(oldValue, newValue)) {
      this.loadDeviceIdDhs();
      const newShiftIds = this.shifts.map((shift) => shift.id);
      this.$emit(
        'input',
        this.value.filter((selectedShiftId) => newShiftIds.includes(selectedShiftId)),
      );
    }
  }

  private get shiftNumbers(): number[] {
    return this.shifts.map((shift: Shift) => shift.id);
  }

  private get shiftNames(): string[] {
    return this.shifts.map((shift: Shift) => shift.name);
  }
}
