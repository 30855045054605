
import { AlertKPI, AlertKPIGroup } from '@/models/enums/AlertsEnums';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { alertsService } from '@/services/alerts.service';
import { Component, Vue, Prop } from 'vue-property-decorator';

/**
 * Field to select an alert KPI (metric).
 *
 * Emits 'input' and 'change' events with the selected value when the selection
 * changes.
 */
@Component
export default class AlertsMetricsSelect extends Vue {
  /**
   * KPI to show as selected.
   */
  @Prop({ default: '' })
  private value!: string;

  /**
   * If true, the field will be required and show a validation message if left
   * unfilled.
   */
  @Prop({ default: false, type: Boolean })
  private required!: boolean;

  /**
   * If true, the field will be disabled and the user won't be able to change
   * its value.
   */
  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  /**
   * If false it lists the KPI grouped by type (Health, Events...), otherwise it
   * shows them in a flat list
   */
  @Prop({ default: false, type: Boolean })
  private flatten!: boolean;

  /**
   * Console that the KPI belongs to
   */
  @Prop({ required: true })
  private console!: ConsoleEnum;

  private groups: AlertKPIGroup[] = [];

  private mounted(): void {
    this.getItems();
  }

  private getItems() {
    this.groups = alertsService.store.getKpiGroupsByConsole(this.console);
  }

  private handleSelect(value: AlertKPI) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
