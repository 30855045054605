// --------------------------------------------------------------------------------
// <copyright file="index.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { defineStore } from 'pinia';
import { TimeZone } from '@/models/timeZone';
import { CurrencyCode } from '@/models/currencyCode';
import { Measurement } from '@/models/measurement';
import { DeviceBaseModel } from '@/models/deviceBaseModel';
import { User } from '@/models/user';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { Tenant } from '@/models/tenant';
import { Device } from '@/models/device';
import { Shift } from '@/models/shift';
import { Target } from '@/models/target';
import { UserTarget } from '@/models/userTarget';
import { AlertKPI, AlertKPIGroup, AlertMetric } from '@/models/enums/AlertsEnums';
import { UserCustomNotificationStatus } from '@/models/customNotifications';
import { isNil } from '@/utils/misc';
import { AlertWorkflowNotification } from '@/models/AlertWorkflowNotification';

export type Notification =
  | UserAlertStatus
  | UserCustomNotificationStatus
  | AlertWorkflowNotification;

interface PersistentState {
  locale: string;
  timezones: TimeZone[];
  currencyCodes: CurrencyCode[];
  measurements: Measurement[];
  deviceBaseModels: DeviceBaseModel[];
  internalCurrentUser: User | null;
  notifications: Notification[];
  internalTenant: Tenant | null;
  internalDevices: Device[];
  shifts: Shift[];
  targets: Target[];
  userTargets: UserTarget[];
  kpiGroups: AlertKPIGroup[];
  eventModules: string[];
  eventCodes: string[];
}

export const usePersistentStore = defineStore('persistent', {
  state: (): PersistentState => ({
    locale: '',
    timezones: [],
    currencyCodes: [],
    measurements: [],
    deviceBaseModels: [],
    internalCurrentUser: null,
    notifications: [],
    internalTenant: null,
    internalDevices: [],
    shifts: [],
    targets: [],
    userTargets: [],
    kpiGroups: [],
    eventModules: [],
    eventCodes: [],
  }),
  actions: {
    setLocale(locale: string) {
      this.locale = locale;
    },
    setTimezones(timezones: TimeZone[]) {
      this.timezones = timezones;
    },
    setCurrencyCodes(currencyCodes: CurrencyCode[]) {
      this.currencyCodes = currencyCodes;
    },
    setMeasurements(measurements: Measurement[]) {
      this.measurements = measurements;
    },
    setDeviceBaseModels(deviceBaseModels: DeviceBaseModel[]) {
      this.deviceBaseModels = deviceBaseModels;
    },
    setCurrentUser(currentUser: User | null) {
      this.internalCurrentUser = currentUser;
    },
    setNotifications(notifications: Notification[]) {
      this.notifications = notifications;
    },
    setTenant(tenant: Tenant | null) {
      this.internalTenant = tenant;
    },
    setDevices(devices: Device[]) {
      this.internalDevices = devices;
    },
    addDevice(device: Device) {
      this.internalDevices = [...this.internalDevices, device];
    },
    setShifts(shifts: Shift[]) {
      this.shifts = shifts;
    },
    addShift(shift: Shift) {
      this.shifts = [...this.shifts, shift];
    },
    deleteShift(id: number) {
      this.shifts = this.shifts.filter((shift) => shift.id !== id);
    },
    deleteUserTarget(id: number) {
      this.userTargets = this.userTargets.filter((item) => item.id !== id);
    },
    setTargets(targets: Target[]) {
      this.targets = targets;
    },
    setUserTargets(userTargets: UserTarget[]) {
      this.userTargets = userTargets;
    },
    setKpiGroups(kpiGroups: AlertKPIGroup[]) {
      this.kpiGroups = kpiGroups;
    },
    setEventModules(eventModules: string[]) {
      this.eventModules = eventModules;
    },
    setEventCodes(eventCodes: string[]) {
      this.eventCodes = eventCodes;
    },
    resetState() {
      this.$reset();
    },
  },
  getters: {
    currentUser: (state): User | null => {
      if (isNil(state.internalCurrentUser)) {
        return null;
      }
      return User.GetCopy(state.internalCurrentUser as User);
    },
    devices: (state): Device[] =>
      state.internalDevices.map((device) => Device.GetCopy(device as Device)),
    tenant: (state): Tenant | null => {
      if (isNil(state.internalTenant)) {
        return null;
      }
      return Tenant.GetCopy(state.internalTenant as Tenant);
    },
    kpis: (state): AlertKPI[] => {
      return state.kpiGroups.flatMap((group) => group.alertKpis);
    },
    getAlertKpiByMetric() {
      return (metric: AlertMetric) => this.kpis.find((kpi) => kpi.kpi === metric);
    },
  },
  persist: true,
});
