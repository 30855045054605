
import { CutCurrentWorkloadDataMessage, MessageType } from '@/models/Charts/chartsData';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableWithActions2 from '../tableWithActions/TableWithActions2.vue';
import moment from 'moment';
import {
  addDataTestIdAttributeToTh,
  generateLocalizedCustomSearch,
  matchIgnoringCase,
} from '@/utils/table';
import { values } from '@/utils/enum';

@Component({
  components: {
    TableWithActions2,
  },
  methods: {
    addDataTestIdAttributeToTh,
    generateLocalizedCustomSearch,
  },
})
export default class CutCellControlActiveMessagesModal extends Vue {
  @Prop({ required: true })
  private messages!: CutCurrentWorkloadDataMessage[];

  private get cellControlMessages(): CutCellControlMessage[] {
    return this.messages.map((message) => ({
      source: message.messageSource,
      sourceCategory: this.getSourceCategoryKey(message.messageSource),
      text: message.messageText,
      type: message.messageLevel,
      tagType: this.getTagType(message.messageLevel),
      time: message.timestamp,
    }));
  }

  private getSourceCategoryKey(source: string): string {
    return sourceToCategoryKeyMap.get(source) ?? 'unknown';
  }

  private getSourceCategoryIcon(sourceCategory: string): string {
    return `images/icons/source-category/${sourceCategory}.svg`;
  }

  private getTagType(type: MessageType) {
    if (type === MessageType.Error) {
      return 'is-danger';
    }
    if (type === MessageType.Warning) {
      return 'is-warning';
    }
    return 'is-info';
  }

  private messageTypeSorter(
    messageA: CutCellControlMessage,
    messageB: CutCellControlMessage,
    isAscending: boolean,
  ): number {
    return isAscending
      ? values(MessageType).indexOf(messageB.type) - values(MessageType).indexOf(messageA.type)
      : values(MessageType).indexOf(messageA.type) - values(MessageType).indexOf(messageB.type);
  }

  private formatTimestamp(timestamp: Date): string {
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
  }

  private sourceAndCategoryCustomSearch(
    message: CutCellControlMessage,
    searchText: string,
  ): boolean {
    return (
      matchIgnoringCase(message.source, searchText) ||
      matchIgnoringCase(message.sourceCategory, searchText)
    );
  }
}

interface CutCellControlMessage {
  source: CutCurrentWorkloadDataMessage['messageSource'];
  sourceCategory: CutCurrentWorkloadDataMessage['messageSource'];
  text: CutCurrentWorkloadDataMessage['messageText'];
  type: CutCurrentWorkloadDataMessage['messageLevel'];
  tagType: string;
  time: CutCurrentWorkloadDataMessage['timestamp'];
}

const sourceToCategoryKeyMap = new Map([
  ['Nozzle Changer', 'nozzle-changer'],
  ['Rotary Axis', 'rotary-axis'],
  ['Kühlgerät', 'cooling-unit'],
  ['PLC Tablechange', 'table-change'],
  ['BySort', 'suction-big-part'],

  ...['HMI', 'PCS'].map((source) => [source, 'admin'] as [string, string]),

  ...[
    'CNC',
    'Edge Detection',
    'Axis',
    'Beam Path',
    'EtherCAT',
    'Gas Con',
    'Auto Focus CO2',
    'Capacitive sensing',
    'Capacitive sens',
    'Cut Control',
    'Regulated Pulsed Piercing',
    'PLC',
    'TwinSAFE',
    'Inbetriebnahmeassistent Antriebe',
    'CircularFormingTest',
    'Cross Jet',
    'Memory Terminal',
    'Dynamische Abnahme',
    'SlotMachine',
  ].map((source) => [source, 'machine'] as [string, string]),

  ...[
    'Lens Protection',
    'Cutting Head Fiber',
    'Auto Focus Fiber',
    'CuttingHead CO2',
    'Automatic Nozzle Centering',
    'Automatic Nozzl',
    'SSC',
    'Magnifications-Station',
    'Grundeinstellung Fokuslage',
  ].map((source) => [source, 'cutting-head'] as [string, string]),

  ...['CO2 Laser', 'Fiber Laser', 'ByLaser', 'Laser'].map(
    (source) => [source, 'laser'] as [string, string],
  ),

  ...[
    'ByTrans',
    'ByTower',
    'ByCross',
    'Byloader',
    'TransTower',
    'Handling Extern',
    'PartMarker',
    'PartMarkerOpcUaClient',
    'Automation',
  ].map((source) => [source, 'automation'] as [string, string]),

  ...['ThreadCuttingOpcUaClient', 'ThreadingControl', 'BZTCNC', 'ThreadCutting'].map(
    (source) => [source, 'thread-cutting'] as [string, string],
  ),
  ['ICP', 'vision'],
]);
