// --------------------------------------------------------------------------------
// <copyright file="misc.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { SignalrService } from '@/library-services/signalr.service';
import { SpinnerPluginService } from '@/library-services/spinner.service';
import AuthService from '@/services/auth.service';
import { isNil } from '@/utils/misc';
import { inject, onBeforeMount, onBeforeUnmount } from 'vue';

export function useSpinner(): SpinnerPluginService {
  const spinner = inject<SpinnerPluginService>('spinner');
  if (isNil(spinner)) {
    throw new Error('SpinnerPluginService is not provided');
  }
  return spinner;
}

export function useAuth(): AuthService {
  const auth = inject<AuthService>('auth');
  if (isNil(auth)) {
    throw new Error('AuthService is not provided');
  }
  return auth;
}

export function useSignalR(): SignalrService {
  const signalr = inject<SignalrService>('signalr');
  if (isNil(signalr)) {
    throw new Error('SignalrService is not provided');
  }
  return signalr;
}

function useAutoRemovedEventListener(
  eventType: string,
  listener: EventListenerOrEventListenerObject,
) {
  onBeforeMount(() => {
    window.addEventListener(eventType, listener);
  });

  onBeforeUnmount(() => {
    window.removeEventListener(eventType, listener);
  });
}

export function useAutoRemovedResizeListener(listener: EventListenerOrEventListenerObject) {
  useAutoRemovedEventListener('resize', listener);
}
