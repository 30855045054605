
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/models/user';
import { customersService } from '@/services/tenants.service';
import { isEmpty, isNil, uniqueId } from '@/utils/misc';
import { Logger } from '@/utils/logger';
import i18n from '@/i18n';
import { currentUser } from '@/utils/permissionUtils';

export interface EmailEntry {
  userId: number;
  email: string;
}

@Component({})
export default class EmailsTagInput extends Vue {
  @Prop({ default: false, type: Boolean })
  private required!: boolean;

  @Prop()
  private customerId?: number;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop()
  private value: string[] | null = null;

  @Prop({ default: false, type: Boolean })
  private selectAllInit!: boolean;

  @Prop({ default: true, type: Boolean })
  private showLabel!: boolean;

  @Prop({ default: () => () => true })
  private filterUsers!: (user: User, index?: number, array?: User[]) => boolean;

  @Prop({ default: false, type: Boolean })
  private loading!: boolean;

  private componentId = uniqueId();

  private users: User[] = [];
  private availableEmails: string[] = [];
  private userEmails: string[] = [];
  private nonSelectedEmails: string[] = [];

  private areFilteredCustomersLoading = false;

  private async mounted() {
    this.userEmails = this.value ?? [];

    await this.loadData();

    if (this.selectAllInit) {
      this.selectAll();
    }
  }

  private async loadData() {
    this.areFilteredCustomersLoading = true;
    this.users = await this.getFilteredCustomerUsers().finally(
      () => (this.areFilteredCustomersLoading = false),
    );
    this.availableEmails = this.users.map((user) => user.email);
    this.nonSelectedEmails = this.availableEmails.filter(
      (option) => this.userEmails.indexOf(option) === -1,
    );
  }

  private async getFilteredCustomerUsers(): Promise<User[]> {
    const customerId = currentUser().customerId ?? this.customerId;
    if (isNil(customerId)) {
      Logger.error('Missing customer id from which to get its users');
      throw new Error('Missing customer id from which to get its users');
    }
    return (await customersService.getUsers(customerId)).filter(this.filterUsers);
  }

  @Watch('value')
  initializeTag() {
    this.userEmails = this.value ?? [];
  }

  private selectAll() {
    this.userEmails = [...this.availableEmails];
    this.onSelectionChanged(this.userEmails);
  }

  private clearAll() {
    this.userEmails = [];
    this.onSelectionChanged(this.userEmails);
  }

  private updateNonSelectedEmails(text: string) {
    this.nonSelectedEmails = this.availableEmails.filter((option) => {
      return option.toLowerCase().includes(text.toLowerCase()) && !this.userEmails.includes(option);
    });
  }

  private onSelectionChanged(emails: string[]) {
    this.$emit(
      'input',
      emails.map(
        (email) =>
          ({
            userId: this.users.find((user) => user.email === email)!.id,
            email,
          } as EmailEntry),
      ),
    );
  }

  private get label(): string | undefined {
    if (!this.showLabel) {
      return undefined;
    }

    return i18n.t('inputs.alerts_email_select.label').toString();
  }

  private get areTagsEmpty() {
    return isEmpty(this.userEmails);
  }

  private get isLoading(): boolean {
    return this.loading || this.areFilteredCustomersLoading;
  }
}
