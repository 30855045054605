import { render, staticRenderFns } from "./SettingsMenu.vue?vue&type=template&id=699faef1&scoped=true"
import script from "./SettingsMenu.vue?vue&type=script&setup=true&lang=ts"
export * from "./SettingsMenu.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SettingsMenu.vue?vue&type=style&index=0&id=699faef1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699faef1",
  null
  
)

export default component.exports