
import { Component, Vue, Watch } from "vue-property-decorator";
import { User } from "@/models/user";
import { Device } from "@/models/device";
import { Routes } from "@/router/routes";
import { Location } from "@/models/location";
import { locationsService } from "@/services/locations.service";
import { FilterTimeSpanEnum } from "@/models/enums/FilterTimeSpanEnum";
import ShiftFilter from "@/components/ShiftFilter.vue";
import TimeSpanFilter from "@/components/TimeSpanFilter.vue";
import { MachineType } from "@/models/enums/MachineType";
import { DeviceStateData } from "@/models/deviceStateData";
import { isEmpty } from "@/utils/misc";
import { WidgetEnum } from "@/models/enums/WidgetEnum";
import { Logger } from "@/utils/logger";
import OverviewDeviceCard from "@/components/workCenterConsole/OverviewDeviceCard.vue";
import { devicesService } from "@/services/devices.service";
import { usersService } from "@/services/users.service";
import { customersService } from "@/services/tenants.service";
import { metricsService } from "@/services/metrics.service";
import { localStorageService } from "@/library-services/localStorage.service";
import { RequestCanceledError } from "@/services/requestCanceledError";
import { shiftsService } from "@/services/shifts.service";

@Component({
  methods: {
    isEmpty,
  },
  components: {
    ShiftFilter,
    TimeSpanFilter,
    OverviewDeviceCard,
  },
})
export default class WorkCenterConsoleOverview extends Vue {
  private states: DeviceStateData[] = [];
  private locations: Location[] = [];
  private timeHandle = -1;
  private statusHandle = -1;
  private now = new Date();
  private loading = false;
  private compactMode!: boolean;

  private selectedTimespan = FilterTimeSpanEnum.Day;
  private selectedShiftIds: number[] = [];

  private abortController = new AbortController();

  constructor() {
    super();
    this.compactMode = localStorageService.compactMode;
  }

  private async mounted() {
    this.locations = await locationsService.get(this.user.customerId);
    // This mustn't be awaited. Otherwise, the setInterval calls might be done
    // after the clean-up in beforeDestroy. This can happen if the user
    // navigates away before this call returns.
    this.getStates(true);

    if (this.statusHandle === -1) {
      this.statusHandle = window.setInterval(this.getStates, 30 * 1000);
    }
    if (this.timeHandle === -1) {
      this.timeHandle = window.setInterval(() => {
        this.now = new Date();
      }, 1000);
    }
  }

  private async getStates(firstExec: boolean = false) {
    this.loading = true;
    this.abortController.abort();
    this.abortController = new AbortController();

    try {
      const tenantIdDh = customersService.store.currentIdDh() ?? 0;

      this.states = await metricsService.getDevicesMetrics<DeviceStateData[]>(
        WidgetEnum.OverviewData,
        {
          tenantIdDh,
          deviceIds: this.deviceIds,
          shifts: this.selectedShiftIds,
          timeSpan: this.selectedTimespan,
          statesOnly: firstExec,
        },
        this.abortController,
      );
    } catch (e) {
      if (!(e instanceof RequestCanceledError)) {
        Logger.error(`Error loading device states`, e);
      }
    } finally {
      this.loading = false;
    }
    if (firstExec) {
      this.getStates();
    }
  }

  private get devicesInSelectedShifts(): Device[] {
    if (isEmpty(this.selectedShiftIds)) {
      return this.devices;
    }
    const shifts = shiftsService.store.getMatchingIds(this.selectedShiftIds);
    const relatedDevices = shifts.flatMap((shift) => shift.relatedDevices);
    return this.devices.filter((device) => relatedDevices.includes(device.id));
  }

  private get devices(): Device[] {
    if (isEmpty(this.locations)) {
      return [];
    }
    return devicesService.store
      .get()
      .sort(
        (a: Device, b: Device) =>
          (this.getLocationName(a).localeCompare(this.getLocationName(b)) ?? 0) ||
          a.deviceId[0].localeCompare(b.deviceId[0]) ||
          a.name.localeCompare(b.name),
      );
  }

  private getDeviceState(device: Device) {
    return this.states.find((state) => state.deviceid === device.deviceId);
  }

  private getLocationName(device: Device) {
    return this.locations.find((location) => location.id === device.locationId)!.name;
  }

  private getShiftName(shiftId: number) {
    return shiftsService.store.getNameById(shiftId);
  }

  private get deviceIds(): string[] {
    return this.devices.map((device) => device.deviceId);
  }

  private get isFirstLoad() {
    return this.loading && isEmpty(this.states);
  }

  // FIXME: Code duplication with Chart.onClickEvent
  private goToDevice(device: Device) {
    let route: Routes;

    switch (device.getType()) {
      case MachineType.Cut:
        route = Routes.Cut;
        break;
      case MachineType.Bend:
        route = Routes.Bend;
        break;
      case MachineType.Tube:
        route = Routes.Tubes;
        break;
      default:
        Logger.error('Wrong device type', device.getType());
        return;
    }

    this.$router.push({
      name: route,
      // FIXME: Use URL query parameters instead
      params: {
        selectedDeviceStringId: device.deviceId,
      },
    });
  }

  @Watch('compactMode')
  private onCompactModeChange() {
    localStorageService.compactMode = this.compactMode;
  }
  private get user(): User {
    return usersService.store.current();
  }

  private beforeDestroy() {
    clearInterval(this.timeHandle);
    clearInterval(this.statusHandle);
    this.abortController.abort();
  }
}
