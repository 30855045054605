
import { Component, Prop, Vue } from 'vue-property-decorator';
import DevicesSelect from '@/components/inputs/DevicesSelect.vue';
import EmailsTagInput, { EmailEntry } from '@/components/inputs/EmailsTagInput.vue';
import { Alert } from '@/models/alert';
import AlertsMetricsSelect from '../inputs/AlertsMetricsSelect.vue';
import AlertsOperatorsSelect from '../inputs/AlertsOperatorsSelect.vue';
import AlertsActionsSelect from '../inputs/AlertsActionsSelect.vue';
import { alertsService } from '@/services/alerts.service';
import { Tenant } from '@/models/tenant';
import { DeviceModelType } from '@/models/deviceBaseModel';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';

@Component({
  components: {
    DevicesSelect,
    EmailsTagInput,
    AlertsMetricsSelect,
    AlertsOperatorsSelect,
    AlertsActionsSelect,
  },
  computed: {
    ConsoleEnum: () => ConsoleEnum,
  },
})
export default class AlertCreationModal extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  private alert = Alert.GetEmpty();
  private thresholdValid = true;
  private readonly deviceTypeLaser = DeviceModelType.Laser;

  private isSubmitting = false;

  private mounted() {
    this.alert = Alert.GetEmpty();
    this.thresholdValid = true;
  }

  private get isEnabled() {
    if (this.alert && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity() && this.thresholdValid && this.isEmailValid();
    }
    return false;
  }

  private handleChange(event: any): void {
    this.alert = { ...this.alert, [event.target.name]: event.target.value };
  }

  private checkValidity(input: string) {
    const thresholdValue: number = +input;
    this.thresholdValid = !isNaN(thresholdValue) && input !== '';
  }

  private onEmailSelectionChanged(emailsAndIds: EmailEntry[]) {
    this.alert = {
      ...this.alert,
      recipients: emailsAndIds.map((item) => item.userId),
    };
  }

  private async onSubmit() {
    this.isSubmitting = true;
    this.alert.tenantId = this.tenant.id;
    if (this.alert.deviceId === 0) {
      this.alert.deviceId = null;
    }
    alertsService
      .create(this.alert)
      .then(() => {
        this.$emit('save');
        this.$emit('close');
      })
      .finally(() => (this.isSubmitting = false));
  }

  private isEmailValid() {
    const alertAction = this.alert.action!.toString();
    return alertAction === '1' || (alertAction === '2' && this.alert.recipients.length !== 0);
  }
}
