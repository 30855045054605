// --------------------------------------------------------------------------------
// <copyright file="e-service-center-router.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import Router from '@/router';
import { Routes } from '@/router/routes';
import { CareTab as CareConsoleTab } from '@/views/careConsole/commons/navigation';
import { CutLayoutTab } from '@/components/common/CutLayoutTab';
import { useRouter } from '@/composables/router';
import VueRouter from 'vue-router';

export function useEServiceCenterRouter() {
  const router = useRouter();

  return new EServiceCenterRouter(router);
}

class EServiceCenterRouter {
  constructor(private router: VueRouter) {}

  async goToHome() {
    await this.router.push({ name: Routes.Index });
  }

  /**
   * Navigates to the Inbox page setting the table filters to show entries for
   * the specified equipment.
   *
   * The customer name filter is also set as the equipment names aren't
   * necessarily unique, even in the same customer.
   *
   * @param customerName Name of the customer owning the equipment.
   * @param equipmentName Name of the equipment.
   */
  async goToInboxFilteredByEquipment(customerName: string, equipmentName: string) {
    await this.router.push({
      name: Routes.EServiceCenterInbox,
      query: {
        customerName,
        equipmentName,
      },
    });
  }

  async goToCustomerEquipment(customerIdDh: number) {
    await this.router.push({
      name: Routes.EServiceCenterCustomerEquipment,
      params: { customerIdDh: customerIdDh.toString() },
    });
  }

  async goToEquipmentCareConsole(customerIdDh: number, equipmentStringId: string) {
    await this.router.push({
      name: Routes.EServiceCenterEquipmentView,
      params: {
        customerIdDh: customerIdDh.toString(),
        equipmentId: equipmentStringId,
      },
    });
  }

  async goToEquipmentAlertTabOnCareConsole(customerIdDh: number, equipmentStringId: string) {
    await this.router.push({
      name: Routes.EServiceCenterEquipmentView,
      params: {
        customerIdDh: customerIdDh.toString(),
        equipmentId: equipmentStringId,
      },
      hash: `#${CareConsoleTab[CareConsoleTab.Alerts]}`,
    });
  }

  async setCareConsoleDevice(equipmentId: string, hash: string) {
    await this.router.replace({
      name: Routes.EServiceCenterEquipmentView,
      params: { equipmentId },
      hash,
    });
  }

  async goToEquipmentWorkCenterConsole(customerIdDh: number, equipmentStringId: string) {
    await this.router.push({
      name: Routes.EServiceCenterEquipmentView,
      params: {
        customerIdDh: customerIdDh.toString(),
        equipmentId: equipmentStringId,
      },
      hash: `#${CutLayoutTab[CutLayoutTab.CutOverview]}`,
    });
  }
}

export const router = new EServiceCenterRouter(Router);
