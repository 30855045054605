// --------------------------------------------------------------------------------
// <copyright file="signalr.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ToastProgrammatic as Toast } from 'buefy';
import { notificationsService } from '../services/notifications.service';
import { UserCustomNotificationStatus } from '@/models/customNotifications';
import moment from 'moment';
import { User } from '@/models/user';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { AlertWorkflowNotification } from '@/models/AlertWorkflowNotification';
import { Logger } from '@/utils/logger';
import { usersService } from '@/services/users.service';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import i18n from '@/i18n';
import { isNil } from '@/utils/misc';
import { configurationService } from '@/services/configuration.service';
import { Notification } from '@/store/persistent/index';

export class SignalrService {
  private connection: HubConnection;

  constructor() {
    const spaConfig = configurationService.getConfig();

    this.connection = new HubConnectionBuilder()
      .withUrl(spaConfig.signalrHubEndpoint)
      .configureLogging(LogLevel.Information)
      .build();

    this.connection.on('Send', (message: any) => this.messageReceived(message));
    this.connection.on('Alert', () => this.alertNotified());
  }

  private get user(): User {
    return usersService.store.current();
  }

  private get userId(): number {
    return this.user.id;
  }

  private get groupName(): number | null {
    return this.user.customerId;
  }

  async connect() {
    await this.connection.start();
  }

  async signIn() {
    await this.connection.invoke('SignIn', this.userId);
    if (!isNil(this.groupName)) {
      await this.connection.invoke('AddToCustomer', this.groupName);
    }

    // FIXME: This should probably be handled by the caller
    window.onbeforeunload = () => {
      this.disconnect().catch((reason) =>
        Logger.warn('Error disconnecting from SignalR. Probably nothing to worry about.'),
      );
    };
  }

  async disconnect() {
    if (
      [HubConnectionState.Disconnected, HubConnectionState.Disconnecting].includes(
        this.connection.state,
      )
    ) {
      return;
    }
    try {
      await this.connection.invoke('SignOut', this.userId);
      if (!isNil(this.groupName)) {
        await this.connection.invoke('RemoveFromCustomer', this.groupName);
      }
    } finally {
      await this.connection.stop();
    }
  }

  private messageReceived(message: string) {
    Toast.open({
      duration: 5000,
      message,
      position: 'is-top',
      type: 'is-warning is-light',
    });
  }

  private alertNotified() {
    notificationsService.get().then((notifications) => {
      // Take the last one. Endpoint sorts them by descending timestamp
      const message = this.generateNotificationMessage(notifications[0]);

      if (!message) {
        return;
      }

      Toast.open({
        duration: 5000,
        message,
        position: 'is-top',
        type: 'is-warning is-light',
      });

      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(message);
      });
    });
  }

  private generateNotificationMessage(notification: Notification) {
    if (notification instanceof UserCustomNotificationStatus) {
      return this.generateUserCustomNotificationStatusMessage(notification);
    } else if (notification instanceof AlertWorkflowNotification) {
      return this.generateAlertWorkflowNotificationMessage(notification);
    } else if (notification instanceof UserAlertStatus) {
      return this.generateUserAlertStatusNotificationMessage(notification);
    }

    Logger.error('Invalid notification type', notification);
  }

  private generateUserCustomNotificationStatusMessage(notification: UserCustomNotificationStatus) {
    const timestampNotification: string = this.formatTimestampNotification(
      notification.timestampNotification,
    );
    return `${timestampNotification} ${notification.messageTxt}`;
  }

  private generateAlertWorkflowNotificationMessage(notification: AlertWorkflowNotification) {
    const params = {
      deviceName: notification.deviceName,
      customerName: notification.customerName,
    };
    return i18n.t('alert.alert_workflow_notification_message', params).toString();
  }

  private generateUserAlertStatusNotificationMessage(notification: UserAlertStatus) {
    const params: any = {
      metric: i18n.t(`alert.metric.${notification.metric}`),
      operator: i18n.t(`alert.operator.${notification.operator}`),
      thresholdValue: notification.thresholdValue.toString(),
      device: notification.deviceName ?? i18n.t('alert.all_devices'),
      currentValue: i18n.n(notification.currentValue, { maximumSignificantDigits: 2 }),
      timestampNotification: this.formatTimestampNotification(notification.timestampNotification),
    };

    return i18n.t('alert.message', params).toString();
  }

  private formatTimestampNotification(timestampNotification: string | Date) {
    return moment(`${timestampNotification}+00:00`).format('YYYY-MM-DD, HH:mm:ss');
  }
}
