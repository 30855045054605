// --------------------------------------------------------------------------------
// <copyright file="TargetsMetric.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum TargetsMetric {
  None = 0,
  LaserOee = 1,
  LaserStarveBlock = 2,
  LaserCuttingTime = 3,
  SalesFunnelMonthlyPaid = 4,
  SalesFunnelR1 = 5,
  SalesFunnelR2 = 6,
  SalesFunnelR3 = 7,
  SalesFunnelR4 = 8,
  SalesUnpaidDistribution = 9,
  LaserProductivityHist = 10,
  TubeStarveBlock = 11,
  TubeCuttingTime = 12,
  TubeOee = 13,
}
