// --------------------------------------------------------------------------------
// <copyright file="topSalespeopleDetailGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
import { EChartsOption, SeriesOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { SalesTopBySalesData } from './chartsData';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { mockTopSalespeopleData } from './mockWidgetSelectorData';
import { abbreviateNumber } from '@/utils/number';
import { YAXisOption } from 'echarts/types/dist/shared';
import { DEFAULT_TOP_NUMBER } from './salesTopBySalesGenerator';
import { getColor } from '@/utils/color';
import { metricsService } from '@/services/metrics.service';

export const OTHERS_SALESPERSON = 'Others';

interface SeriesDataItem {
  value: number;
  itemStyle: { color: string | undefined };
}

export class TopSalespeopleDetailGenerator extends ChartGenerator<SalesTopBySalesData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getCachedTopSalespeopleData(
      this.tenantIdDh,
      params?.topNumber || DEFAULT_TOP_NUMBER,
      startDate,
      endDate,
      this.controller,
    );
  }

  override updateOptions(
    data: SalesTopBySalesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      legend: {
        show: true,
        type: 'scroll',
        bottom: 0,
      },
      tooltip: {
        confine: true,
      },
      xAxis: {
        type: 'value' as const,
        axisLabel: {
          margin: 4,
          formatter: abbreviateNumber,
          fontSize: parameters.clientWidth! < 357 ? 10 : 12,
        },
      },
      yAxis: {
        type: 'category' as const,
        inverse: true,
        data: this.getSalespeopleNames(data),
        axisLabel: {
          overflow: 'truncate',
          width: 130,
        },
      } as YAXisOption, // overflow=truncate not recognized
      grid: {
        top: 16,
        bottom: 40,
        left: 8,
        containLabel: true,
      },
      series: this.generateSeries(data, parameters.valueKey as keyof SalesTopBySalesData),
    };
  }

  private getSalespeopleNames(data: SalesTopBySalesData[]): string[] {
    return data.map((dataItem) => dataItem.Salesperson);
  }

  private generateSeries(
    data: SalesTopBySalesData[],
    field: keyof SalesTopBySalesData,
  ): SeriesOption[] {
    return [
      {
        type: 'bar' as const,
        name: i18n.t(`report.business_console.${field}`).toString(),
        data: this.generateSeriesData(data, field),
      },
    ];
  }

  private generateSeriesData(
    data: SalesTopBySalesData[],
    field: keyof SalesTopBySalesData,
  ): SeriesDataItem[] {
    return data.map((dataItem, i) => ({
      value: dataItem[field] as number, // we only get numeric fields
      itemStyle: {
        // The data item index (i) corresponds to the salesperson index.
        // We have one data item per salesperson.
        color: this.getSalespersonColor(data, i),
      },
    }));
  }

  private getSalespersonColor(data: SalesTopBySalesData[], salespersonIndex: number): string {
    const salespersonName = this.getSalespeopleNames(data)[salespersonIndex];
    // Sort salespeople to ensure they get the same color as in
    // SalesPerSalespersonTimelineGenerator chart.
    const sortedSalespeopleNames = this.getColorSortedSalespeopleNames(data);
    const salespersonSortedIndex = sortedSalespeopleNames.indexOf(salespersonName);
    // Skip two first colors used in "Amount vs. Nº of quotes conversion by
    // salesperson" chart to make clear they aren't related.
    return getColor(salespersonSortedIndex + 2, 0);
  }

  /**
   * Returns sorted salespeople names but with 'Others' at the end.
   *
   * We need charts from this generator and from
   * SalesPerSalespersonTimelineGenerator to assign the same colors to
   * salespeople. Given that the other generator doesn't receive 'Others'
   * salesperson, here we have to move it to the end to avoid color mismatches.
   */
  private getColorSortedSalespeopleNames(data: SalesTopBySalesData[]): string[] {
    const sortedWithoutOthers = this.getSalespeopleNames(data)
      .filter((name) => name !== OTHERS_SALESPERSON)
      .sort((a, b) => a.localeCompare(b));
    return sortedWithoutOthers.concat(OTHERS_SALESPERSON);
  }

  override getMockData(): SalesTopBySalesData[] {
    return mockTopSalespeopleData();
  }
}
