
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isEmpty, uniqueId } from '@/utils/misc';
import { Subsidiary } from '@/models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';
import i18n from '@/i18n';

@Component({})
export default class SubsidiariesTagInput extends Vue {
  @Prop({ default: false, type: Boolean })
  private required!: boolean;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop()
  private value: string[] | null = null;

  @Prop({ default: true, type: Boolean })
  private showLabel!: boolean;

  private componentId = uniqueId();

  private availableSubsidiaries: Subsidiary[] = [];
  private subsidiariesNames: string[] = [];
  private nonSelectedSubsidiaries: string[] = [];

  private areSubsidiariesLoading = false;

  private async mounted() {
    this.subsidiariesNames = this.value ?? [];

    await this.loadData();
  }

  private async loadData() {
    this.areSubsidiariesLoading = true;
    this.availableSubsidiaries = await subsidiariesService
      .get()
      .finally(() => (this.areSubsidiariesLoading = false));
    this.nonSelectedSubsidiaries = this.availableSubsidiaries
      .filter((option) => this.subsidiariesNames.indexOf(option.name) === -1)
      .map((c) => c.name);
  }

  @Watch('value')
  initializeTag() {
    this.subsidiariesNames = this.value ?? [];
  }

  private selectAll() {
    this.subsidiariesNames = [...this.availableSubsidiaries.map((c) => c.name)];
    this.onSelectionChanged(this.subsidiariesNames);
  }

  private clearAll() {
    this.subsidiariesNames = [];
    this.onSelectionChanged(this.subsidiariesNames);
  }

  private updateNonSelectedSubsdiaries(text: any) {
    this.nonSelectedSubsidiaries = this.availableSubsidiaries
      .filter((option) => {
        return (
          option.name.toLowerCase().includes(text.toLowerCase()) &&
          !this.subsidiariesNames.includes(option.name)
        );
      })
      .map((c) => c.name);
  }

  private onSelectionChanged(subsidiaries: string[]) {
    this.$emit(
      'input',
      subsidiaries.map((subsidiaryName) => ({
        id: this.availableSubsidiaries.find((c) => c.name === subsidiaryName)?.id ?? undefined,
        name: subsidiaryName,
      })),
    );
  }

  private get label(): string | undefined {
    if (!this.showLabel) {
      return undefined;
    }

    return i18n.t('inputs.alerts_subsidiary_select.label').toString();
  }

  private get areTagsEmpty() {
    return isEmpty(this.subsidiariesNames);
  }

  private get isLoading(): boolean {
    return this.areSubsidiariesLoading;
  }
}
