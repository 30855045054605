
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Alert } from '@/models/alert';
import CareAlertCreationWizard from '@/components/alerts/CareAlertCreationWizard.vue';
import CareAlertEditionModal from '@/components/alerts/CareAlertEditionModal.vue';
import { alertsService } from '@/services/alerts.service';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isEmpty, isNil } from '@/utils/misc';
import i18n from '@/i18n';
import { listToCommaSeparatedString } from '@/utils/array';
import { translateDateGrouping } from '@/utils/dates';
import {
  matchesDateGrouping,
  matchesMetric,
  matchesOperator,
  matchesThreshold,
  matchesVariable,
  formatWithUnit,
  translateKpi,
  translateOperator,
  translateVariable,
} from '@/utils/alerts';
import { dialogService } from '@/library-services/dialog.service';
import { modalService } from '@/library-services/modal.service';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import TableActionButton2 from '@/components/tableWithActions/TableActionButton2.vue';
import AlertFilters from './AlertFilters.vue';
import { AlertFilterValues } from './commons/model';
import {
  createArrayMatcher,
  createCustomMatcherWithTransformation,
  createTextListMatcher,
  createTextListMatcherForEnum,
  FilterMatcher,
} from './commons/matchers';
import { matchIgnoringCase } from '@/utils/table';

@Component({
  methods: {
    matchesDateGrouping,
    matchesMetric,
    matchesOperator,
    matchesVariable,
    matchesThreshold,
    formatWithUnit,
    listToCommaSeparatedString,
    isNil,
    translateDateGrouping,
    translateKpi,
    translateOperator,
    translateVariable,
  },
  components: { TableActionButton2, TableWithActions2, AlertFilters },
})
export default class CareConsoleAlerts extends Vue {
  @Ref('table')
  private table!: TableWithActions2;

  @Ref('alertFilters')
  private alertFilters!: AlertFilters;

  private alerts: Alert[] = [];
  private filterMatchers: Record<keyof AlertFilterValues, FilterMatcher> = {
    tenantName: createTextListMatcher('tenantName'),
    deviceName: createTextListMatcher('deviceName'),
    metric: createTextListMatcherForEnum('metric', translateKpi),
    operator: createTextListMatcherForEnum('operator', translateOperator),
    threshold: createCustomMatcherWithTransformation('threshold', 'metric', formatWithUnit),
    dateGrouping: this.multipleDateGroupingMatcher,
    eventSources: createArrayMatcher('eventSources'),
    eventCodes: createArrayMatcher('eventCodes'),
    variable: createCustomMatcherWithTransformation('variable', 'metric', translateVariable),
  };

  private isLoading = false;

  private created() {
    this.updateAlerts();
  }

  private async updateAlerts() {
    this.isLoading = true;
    await alertsService
      .getCareConsoleAlerts()
      .then((data) => (this.alerts = data))
      .finally(() => (this.isLoading = false));
  }

  private createAlert() {
    modalService.open({
      parent: this,
      component: CareAlertCreationWizard as any,
      events: {
        save: () => {
          this.alertFilters.clearAllFilters();
          this.updateAlerts();
        },
      },
    });
  }

  private editAlert(alert: Alert) {
    modalService.open({
      parent: this,
      component: CareAlertEditionModal,
      events: {
        save: () => this.updateAlerts(),
      },
      props: {
        alert: Alert.GetCopy(alert),
      },
    });
  }

  private onDeleteClicked(alert: Alert): void {
    const messageParams = {
      deviceName: alert.deviceName,
      metric: i18n.t(`alert.metric.${alert.metric}`),
    };

    dialogService.confirmWithPromise({
      title: i18n.t('dialog.confirm_title').toString(),
      message: i18n.t('alert.dialog.delete_message', messageParams).toString(),
      confirmText: i18n.t('dialog.confirm_delete').toString(),
      cancelText: i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteDevice(alert.id!),
    });
  }

  private async deleteDevice(alertId: number) {
    await alertsService
      .delete(alertId)
      .then(() => (this.alerts = this.alerts.filter((alert) => alert.id !== alertId)));
  }

  private multipleDateGroupingMatcher(
    alert: Alert,
    filterValue: FilterTimeAxisSpanEnum[],
  ): boolean {
    if (isEmpty(filterValue)) {
      return true;
    }

    return filterValue.some((dateGrouping) =>
      matchIgnoringCase(
        translateDateGrouping(alert.dateGrouping as FilterTimeAxisSpanEnum),
        translateDateGrouping(dateGrouping),
      ),
    );
  }

  /**
   * We do not use it as Getter because the operation of the application is not correct,
   * we believe that it caches the value and causes that the button is not disabled correctly.
   * Because if you paint a console.log before the return and declare it as Get, the operation is correct.
   */
  private areFiltersOpen() {
    return this.table?.areFiltersOpen();
  }
}
