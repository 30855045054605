import { render, staticRenderFns } from "./CustomDashboard.vue?vue&type=template&id=219f639e&scoped=true"
import script from "./CustomDashboard.vue?vue&type=script&lang=ts"
export * from "./CustomDashboard.vue?vue&type=script&lang=ts"
import style0 from "./CustomDashboard.vue?vue&type=style&index=0&id=219f639e&prod&lang=scss&scoped=true"
import style1 from "./CustomDashboard.vue?vue&type=style&index=1&id=219f639e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219f639e",
  null
  
)

export default component.exports