// --------------------------------------------------------------------------------
// <copyright file="modal.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ModalProgrammatic } from 'buefy';
import { BModalComponent, BModalConfig } from 'buefy/types/components';
import Vue, { DefineComponent } from 'vue';
import ModalWrapper from '@/components/ModalWrapper.vue';

type CompositionApiModalConfig = Omit<BModalConfig, 'component'> & {
  // I know how this looks but the amount of `any`s is really not random
  component: typeof Vue | DefineComponent<any, any, any, any, any, any, any, any>;
};

class ModalService {
  /**
   * Helper function that opens a Buefy modal from a regular, class-based component.
   * How the modal component is written is irrelevant.
   * @param params
   * @returns the modal instance
   */
  open(params: BModalConfig): BModalComponent {
    return ModalProgrammatic.open({
      hasModalCard: true,
      trapFocus: true,
      ...params,
    });
  }

  /**
   * Singleton class containing helper functions to handle modals in components written in
   * Composition API
   */
  compositionApi = new (class {
    /**
     * Helper function that opens a Buefy modal from a component written in Composition API.
     * How the modal component is written is irrelevant.
     * @param params
     * @param app The Vue app instance, as returned by the getCurrentInstance() function
     */
    open(params: CompositionApiModalConfig, app: { proxy: Vue } | null): void {
      const div = document.createElement('div');
      document.body.appendChild(div);
      const modalApp = new Vue({
        render(h) {
          return h(ModalWrapper, {
            props: {
              component: params.component,
              customClass: params.customClass,
              hasModalCard: params.hasModalCard ?? true,
              trapFocus: params.trapFocus ?? true,
              canCancel: params.canCancel,
              events: {
                close() {
                  modalApp.$destroy();
                  params.onCancel?.();
                },
              },
            },
          });
        },
        provide: {
          spinner: Vue.prototype.$spinner,
          auth: Vue.prototype.$auth,
          signalr: Vue.prototype.$signalr,
        },
      });
      // Opening the new instance requires some context copying. I'm afraid we will what needs to
      // be copied when we run into issues.
      // For the time being, if _i18n is not manually copied, localization does not work in modal
      // components.
      (modalApp as any)._i18n = (app!.proxy as any)._i18n;
      modalApp.$mount(div);
    }
  })();
}

export const modalService = new ModalService();
