// --------------------------------------------------------------------------------
// <copyright file="aggregates.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum Aggregate {
  Max = 'max',
  Min = 'min',
  Average = 'average',
}

/*
  Saving the array of strings in the widget definitions led
  to reactivity and memory issues, hence it is easier to simply
  encode the selection of the aggregates as a number with this
  bit of bitwise logic
*/
export function arrayToNumber(aggregates: Aggregate[]): number {
  let serializedAggregates = 0;

  if (aggregates.indexOf(Aggregate.Max) !== -1) {
    serializedAggregates += 4;
  }
  if (aggregates.indexOf(Aggregate.Min) !== -1) {
    serializedAggregates += 2;
  }
  if (aggregates.indexOf(Aggregate.Average) !== -1) {
    serializedAggregates += 1;
  }

  return serializedAggregates;
}

export function numberToArray(n: number | undefined) {
  const aggregates: Aggregate[] = [];

  /* eslint-disable no-bitwise */
  if (((n ?? 0) & 4) !== 0) {
    aggregates.push(Aggregate.Max);
  }
  if (((n ?? 0) & 2) !== 0) {
    aggregates.push(Aggregate.Min);
  }
  if (((n ?? 0) & 1) !== 0) {
    aggregates.push(Aggregate.Average);
  }

  /* eslint-enable no-bitwise */
  return aggregates;
}
