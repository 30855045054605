
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { values } from '@/utils/enum';
import { translateFilterTimeAxisSpanEnum } from '@/utils/i18n';
import { isEmpty, isNil, uniqueId } from '@/utils/misc';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  methods: {
    translateFilterTimeAxisSpanEnum,
  },
})
export default class DateGroupingSelect extends Vue {
  @Prop({ default: () => [] })
  private value?: FilterTimeAxisSpanEnum | FilterTimeAxisSpanEnum[];

  @Prop({ default: false, type: Boolean })
  private required!: boolean;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  private multiple!: boolean;

  private componentId = uniqueId();

  private readonly dateGroupingOptions: FilterTimeAxisSpanEnum[] = values(
    FilterTimeAxisSpanEnum,
  ).filter((value) => value !== FilterTimeAxisSpanEnum.None);

  private handleSelect(value: FilterTimeAxisSpanEnum[]) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  private get multipleSelectionText() {
    return isEmpty(this.value)
      ? ''
      : (this.value as FilterTimeAxisSpanEnum[]).map(translateFilterTimeAxisSpanEnum).join(', ');
  }

  private isDateGroupingActive(dateGrouping: FilterTimeAxisSpanEnum): boolean {
    if (isNil(this.value)) {
      return false;
    }

    if (Array.isArray(this.value)) {
      return this.value.includes(dateGrouping);
    }

    return this.value === dateGrouping;
  }
}
