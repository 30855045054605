// --------------------------------------------------------------------------------
// <copyright file="alerts.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Alert } from '@/models/alert';
import { AlertAction, AlertKPI, AlertKPIGroup, AlertMetric } from '@/models/enums/AlertsEnums';
import { AlertsDefinition } from '@/models/alertsDefinition';
import { usePersistentStore } from '@/store/persistent';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';

class AlertsService {
  store = new (class {
    kpiGroups() {
      return usePersistentStore().kpiGroups;
    }

    getKpiGroupsByConsole(console: ConsoleEnum) {
      return this.kpiGroups().map((kpiGroup) => ({
        name: kpiGroup.name,
        alertKpis: kpiGroup.alertKpis.filter((kpi) => kpi.console === console),
      }));
    }

    getAlertKpiByMetric(metric: AlertMetric): AlertKPI {
      return usePersistentStore().getAlertKpiByMetric(metric)!;
    }

    getAlertMetricUnit(metric: AlertMetric): string {
      return this.getAlertKpiByMetric(metric).unit;
    }

    update(kpiGroups: AlertKPIGroup[]) {
      usePersistentStore().setKpiGroups(kpiGroups);
    }
  })();

  getCareConsoleAlerts(): Promise<Alert[]> {
    return axios.get<Alert[]>(`${BaseUrl}/alerts/cc`).then((response: any) => {
      return response.data;
    });
  }

  getWorkCenterConsoleAlerts(tenantId: number): Promise<Alert[]> {
    return axios
      .get<Alert[]>(`${BaseUrl}/alerts/wcc?tenantId=${tenantId}`)
      .then((response: any) => {
        return response.data;
      });
  }

  delete(id: number): Promise<Alert[]> {
    return axios.delete(`${BaseUrl}/alerts/${id}`).then((response: any) => {
      return response.data;
    });
  }

  update(alert: Alert) {
    return axios.put(`${BaseUrl}/alerts/`, alert).then((response: any) => {
      return response.data;
    });
  }

  create(alert: Alert) {
    return axios.post(`${BaseUrl}/alerts/wcc`, alert).then((response: any) => {
      return response.data;
    });
  }

  createMultiple(alertsDefinition: AlertsDefinition) {
    return axios.post(`${BaseUrl}/alerts/cc`, alertsDefinition).then((response: any) => {
      return response.data;
    });
  }

  getAlertEmail(alertId: number): Promise<string[]> {
    return axios.get<string[]>(`${BaseUrl}/alerts/emailrecipients/${alertId}`).then((response) => {
      return response.data;
    });
  }

  async getAlertKPIs(): Promise<AlertKPIGroup[]> {
    const alertKpis = await axios
      .get<AlertKPIGroup[]>(`${BaseUrl}/alerts/kpis`)
      .then((response) => response.data);
    this.store.update(alertKpis);
    return alertKpis;
  }

  subscribe(equipmentId: number): Promise<void> {
    return this.subscribeAll([equipmentId]);
  }

  unsubscribe(equipmentId: number): Promise<void> {
    return this.unsubscribeAll([equipmentId]);
  }

  async subscribeAll(selectedEquipmentIds: number[]): Promise<void> {
    return axios.put(`${BaseUrl}/alerts/equipment/subscribe`, {
      action: AlertAction.WebAndEmail,
      ids: selectedEquipmentIds,
    });
  }

  async unsubscribeAll(selectedEquipmentIds: number[]): Promise<void> {
    return axios.put(`${BaseUrl}/alerts/equipment/unsubscribe`, selectedEquipmentIds);
  }
}

export const alertsService = new AlertsService();
