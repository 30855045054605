
import { isVueComponent } from '@/utils/misc';
import { getCurrentInstance, h, onBeforeMount, onBeforeUnmount, PropType } from 'vue';

export default {
  props: {
    component: {
      type: Object as PropType<Vue>,
    },
    name: {
      type: String,
      default: 'default',
    },
    scoped: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object as PropType<Record<string, any>>,
    },
    tag: {
      type: String,
      default: 'div',
    },
    event: {
      type: String,
      default: 'hook:updated', // vue:updated in Vue 3
    },
  },
  // Typescript and ESLint seem to be conspiring against me.
  // The IDE infers the type of props just fine but typescript doesn't, so it shows an error.
  // Ignoring this error with ts-ignore shows another eslint error.
  // Hopefully disabling the eslint rule will work :/
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Parameter 'props' implicitly has an 'any' type
  setup(props) {
    const instance = getCurrentInstance();

    function refresh() {
      instance?.proxy?.$forceUpdate();
    }

    onBeforeMount(() => {
      if (isVueComponent(props.component)) {
        props.component!.$on(props.event, refresh);
      }
    });

    onBeforeUnmount(() => {
      if (isVueComponent(props.component)) {
        props.component!.$off(props.event, refresh);
      }
    });

    return function render() {
      if (isVueComponent(props.component)) {
        return h(
          props.tag,
          {},
          props.scoped
            ? props.component!.$scopedSlots[props.name]!(props.props)
            : props.component!.$slots[props.name],
        );
      }
      return h();
    };
  },
};
