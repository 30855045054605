
import { isEmpty } from '@/utils/misc';
import { Component, Prop, Vue } from 'vue-property-decorator';

const ALL_SWITCH_INDEX = -1;

@Component({
  computed: {
    ALL_SWITCH_INDEX: () => ALL_SWITCH_INDEX,
  },
  methods: {
    isEmpty,
  },
})
export default class ClickMenu extends Vue {
  @Prop({ default: '' })
  private icon!: string;

  @Prop({ default: 'mdi' })
  private iconPack!: string;

  @Prop()
  private ids!: Array<string | number>;

  @Prop()
  private names!: string[];

  @Prop({ default: '' })
  private title!: string;

  // Array of ids for multiple, or a number/string for single
  @Prop({ default: () => [] })
  private value!: Array<string | number> | string | number | null;

  // Either 'single' or 'multiple'
  @Prop({ default: 'multiple' })
  private type!: string;

  @Prop({ default: 'is-bottom-left' })
  private position!: string;

  @Prop({ default: 'is-medium' })
  private size!: string;

  @Prop({ default: () => [] })
  private itemIcons!: string[];

  @Prop({ default: 'mdi' })
  private itemIconPack!: string;

  private get allChecked() {
    return Array.isArray(this.value) && this.value.length === this.ids.length;
  }

  private change(index: number, id: string | number, isSwitchedOn: boolean) {
    if (!Array.isArray(this.value)) {
      // This will never apply as value will always be an array for multiple selection
      return;
    }
    if (index === ALL_SWITCH_INDEX) {
      this.$emit('input', isSwitchedOn ? [...this.ids] : []);
    } else if (isSwitchedOn) {
      this.$emit('input', [...this.value, id]);
    } else {
      this.$emit(
        'input',
        this.value.filter((x: number | string) => x !== id),
      );
    }
  }
}
