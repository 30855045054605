
import { Tenant } from '@/models/tenant';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import EmailsTagInput, { EmailEntry } from '@/components/inputs/EmailsTagInput.vue';
import { tenantsService } from '@/services/tenants.service';
import { User } from '@/models/user';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { isEmpty } from '@/utils/misc';
import { TenantReport } from '@/models/tenantReport';

@Component({
  components: {
    EmailsTagInput,
  },
})
export default class CustomerEmailReport extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  @Ref('emailsTagInput')
  private emailsTagInput!: EmailsTagInput;

  private hasDailyReport: boolean = false;
  private hasWeeklyReport: boolean = false;
  private hasMonthlyReport: boolean = false;
  private hasQuarterlyReport: boolean = false;
  private hasAnnualReport: boolean = false;

  private originalEmails: string[] = [];
  private selectedEmails: string[] = [];

  private mounted() {
    this.hasDailyReport = this.tenant.hasDailyReport;
    this.hasWeeklyReport = this.tenant.hasWeeklyReport;
    this.hasMonthlyReport = this.tenant.hasMonthlyReport;
    this.hasQuarterlyReport = this.tenant.hasQuarterlyReport;
    this.hasAnnualReport = this.tenant.hasAnnualReport;
    const loading = this.$buefy.loading.open({
      container: this.emailsTagInput,
    });
    tenantsService
      .getReportUsers(this.tenant.id)
      .then((users) => {
        const emails = users.map((user) => user.email);
        this.originalEmails = [...emails];
        this.selectedEmails = [...emails];
      })
      .finally(() => loading.close());
  }

  private get noReportActivated(): boolean {
    return !(
      this.hasDailyReport ||
      this.hasWeeklyReport ||
      this.hasMonthlyReport ||
      this.hasQuarterlyReport ||
      this.hasAnnualReport
    );
  }

  private get changesOccurred(): boolean {
    const smallerList =
      this.selectedEmails.length < this.originalEmails.length
        ? this.selectedEmails
        : this.originalEmails;
    const largerList =
      this.selectedEmails.length >= this.originalEmails.length
        ? this.selectedEmails
        : this.originalEmails;
    // Low complexity email cross check
    const emailHash = Object.fromEntries(smallerList.map((e) => [e, 1]));
    let emailsHaveChanged = false;
    for (const e of largerList) {
      if (!(e in emailHash)) {
        emailsHaveChanged = true;
        break;
      }
    }
    return (
      this.tenant.hasDailyReport !== this.hasDailyReport ||
      this.tenant.hasWeeklyReport !== this.hasWeeklyReport ||
      this.tenant.hasMonthlyReport !== this.hasMonthlyReport ||
      this.tenant.hasQuarterlyReport !== this.hasQuarterlyReport ||
      this.tenant.hasAnnualReport !== this.hasAnnualReport ||
      emailsHaveChanged
    );
  }

  private get formIsValid(): boolean {
    if (this.noReportActivated) {
      return true;
    } else {
      return !isEmpty(this.selectedEmails);
    }
  }

  private onEmailInput(emails: EmailEntry[]) {
    this.selectedEmails = [...emails.map((email) => email.email)];
  }

  private undo() {
    this.hasDailyReport = this.tenant.hasDailyReport;
    this.hasWeeklyReport = this.tenant.hasWeeklyReport;
    this.hasMonthlyReport = this.tenant.hasMonthlyReport;
    this.hasQuarterlyReport = this.tenant.hasQuarterlyReport;
    this.hasAnnualReport = this.tenant.hasAnnualReport;
    this.selectedEmails = [...this.originalEmails];
    this.emailsTagInput.initializeTag();
  }

  private save() {
    const selectedEmails = this.noReportActivated ? [] : this.selectedEmails;
    tenantsService
      .updateReport(
        new TenantReport(
          this.tenant.id,
          this.hasDailyReport,
          this.hasWeeklyReport,
          this.hasMonthlyReport,
          this.hasQuarterlyReport,
          this.hasAnnualReport,
          selectedEmails,
        ),
      )
      .then(async () => {
        if (this.noReportActivated) {
          this.selectedEmails = [];
        }
        this.originalEmails = [...selectedEmails];
        this.tenant.hasDailyReport = this.hasDailyReport;
        this.tenant.hasWeeklyReport = this.hasWeeklyReport;
        this.tenant.hasMonthlyReport = this.hasMonthlyReport;
        this.tenant.hasQuarterlyReport = this.hasQuarterlyReport;
        this.tenant.hasAnnualReport = this.hasAnnualReport;
        await tenantsService.store.update(this.tenant);
        this.$emit('tenant-updated', this.tenant);
      });
  }

  private filterUsersWithoutWCC(user: User): boolean {
    return user.consoles.includes(ConsoleEnum.WCC) || user.isGlobalAdmin;
  }
}
