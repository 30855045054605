
import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetSelector, { AddWidgetPayload } from '@/components/Charts/WidgetSelector.vue';
import DashboardDefinition, {
  availableDashboardLayouts,
  DashboardLayout,
} from '@/models/Charts/dashboardDefinition';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { getWidgetMachineType } from '@/models/widgetMachineType';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { MachineType } from '@/models/enums/MachineType';
import { areArraysEqual } from '@/utils/array';
import LayoutGrid from './LayoutGrid.vue';
import { addAutoRemovedEventListener, isEmpty } from '@/utils/misc';
import LayoutDropdown from './LayoutDropdown.vue';
import { LayoutSlot } from '@/views/dashboard/layout-grid';
import { DashboardMode as Mode } from '@/views/dashboard/dashboardMode';
import i18n from '@/i18n';
import { DialogProgrammatic as Dialog } from 'buefy';

@Component({
  computed: {
    Mode: () => Mode,
  },
  components: {
    WidgetContainer,
    LayoutGrid,
    LayoutDropdown,
  },
  methods: {
    isEmpty,
  },
})
export default class CustomDashboard extends Vue {
  @Prop({ required: true })
  private dashboardDefinition!: DashboardDefinition;

  @Prop({ required: true })
  private dashboardsCount!: number;

  @Prop({ default: false })
  private currentView!: boolean;

  @Prop({ default: Mode.Normal })
  private mode!: Mode;

  @Prop({ default: false, type: Boolean })
  private autoplay!: boolean;

  private dashboardDefinitionCopy!: DashboardDefinition;
  private editName = '';
  private notUniqueView = true;
  private nWidgets = 0;

  private mounted() {
    this.editName = this.dashboardDefinition.name;
    this.nWidgets = this.dashboardDefinition.widgetDefinitions.length;
    addAutoRemovedEventListener(this, 'beforeunload', this.onBeforeUnload);
  }

  private confirmDeleteDashboard(): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('view.delete_prompt').toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t('dialog.confirm_cancel').toString(),
      type: 'is-danger white-font',
      onConfirm: () => this.deleteDashboard(),
    });
  }

  private deleteDashboard() {
    this.$emit('delete-dashboard');
  }

  private onLayoutChanged(layout: DashboardLayout): void {
    const dashboardDefinition = this.dashboardDefinition.clone();
    dashboardDefinition.layout = layout;
    dashboardDefinition.clearWidgets();
    this.dashboardDefinition = dashboardDefinition;
  }

  private onAddWidget(index: number) {
    if (!this.areThereFreeSlots) {
      this.$buefy.dialog.alert({
        message: this.$i18n.t('widget.limit_exceeded').toString(),
        confirmText: 'OK',
      });
      return;
    }

    this.openWidgetSelector(index);
  }

  private onEditWidget(index: number) {
    this.openWidgetSelector(index, true);
  }

  private openWidgetSelector(index: number, isReplacingWidget = false) {
    this.$buefy.modal.open({
      parent: this,
      component: WidgetSelector,
      hasModalCard: false,
      canCancel: ['escape'],
      props: {
        selectedSlot: this.layout[index],
      },
      events: {
        'add-widget': (addWidgetPayload: AddWidgetPayload) => {
          if (isReplacingWidget) {
            this.onDeleteWidget(index);
          }
          this.addWidget(addWidgetPayload, index);
        },
      },
    });
  }

  private addWidget(payload: AddWidgetPayload, index: number) {
    const { widget, deviceId } = payload;
    const newWd = new WidgetDefinition(
      widget,
      deviceId ?? '',
      getWidgetMachineType(widget) === MachineType.FactoryOverview
        ? FilterTimeSpanEnum.None
        : FilterTimeSpanEnum.Day,
      [],
    );
    newWd.index = index;
    const dashboardDefinition = this.dashboardDefinition.clone();
    dashboardDefinition.addWidget(newWd);
    this.dashboardDefinition = dashboardDefinition;
    ++this.nWidgets;
  }

  private onDeleteWidget(index: number) {
    const dashboardDefinition = this.dashboardDefinition.clone();
    dashboardDefinition.deleteWidget(index);
    this.dashboardDefinition = dashboardDefinition;
    --this.nWidgets;
  }

  private edit() {
    this.dashboardDefinitionCopy = this.dashboardDefinition.clone();
    this.$emit('mode-update', Mode.Edition);
    if (this.dashboardsCount === 1) {
      this.notUniqueView = false;
    }
  }

  private saveDashboard() {
    if (this.dashboardDefinition.widgetDefinitions.length === 0) {
      this.showErrorDialog('dashboard.alert_zero_widget');
    } else if (isEmpty(this.editName)) {
      this.showErrorDialog('dashboard.alert_no_title');
    } else {
      this.$emit('mode-update', Mode.Normal);
      this.dashboardDefinition.name = this.editName;
      this.persistDashboard();
    }
  }

  private persistDashboard() {
    this.$emit('save-dashboard', this.dashboardDefinition);
  }

  private onChange(wd: WidgetDefinition, index: number) {
    this.$set(this.dashboardDefinition.widgetDefinitions, index, wd);
    this.persistDashboard();
  }

  private doParamUpdate() {
    this.persistDashboard();
  }

  private showErrorDialog(textKey: string): void {
    this.$buefy.dialog.alert({
      title: this.$i18n.t('dashboard.alert_title').toString(),
      message: this.$i18n.t(textKey).toString(),
      confirmText: this.$i18n.t('dashboard.alert_confirm').toString(),
      type: 'is-primary white-font',
    });
  }

  private get isDashboardModified(): boolean {
    if (this.mode === Mode.Normal) {
      return false;
    }
    if (this.mode === Mode.Creation) {
      return this.dashboardDefinition.widgetDefinitions.length > 0;
    }
    if (!this.dashboardDefinitionCopy) {
      return false;
    }
    return (
      !areArraysEqual(
        this.dashboardDefinition.widgetDefinitions,
        this.dashboardDefinitionCopy.widgetDefinitions,
      ) || this.name !== this.editName
    );
  }

  private async promptCancelEdition() {
    if (this.isDashboardModified) {
      if (!(await this.confirmEditionCancellation())) {
        return;
      }
    }
    this.cancelEdition();
  }

  private confirmEditionCancellation(): Promise<boolean> {
    return new Promise((resolve) => {
      Dialog.confirm({
        title: this.$t('dashboard.cancel_dialog.title').toString(),
        message: this.$t('dashboard.cancel_dialog.text').toString(),
        cancelText: this.$t('dashboard.cancel_dialog.continue').toString(),
        confirmText: this.$t('dashboard.cancel_dialog.exit').toString(),
        type: 'is-success',
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  }

  private cancelEdition() {
    if (this.mode === Mode.Edition) {
      this.editName = this.name;
      this.dashboardDefinition.widgetDefinitions = [
        ...this.dashboardDefinitionCopy.widgetDefinitions,
      ];
      this.nWidgets = this.dashboardDefinition.widgetDefinitions.length;
    }
    this.$emit('cancel-dashboard');
    this.$emit('mode-update', Mode.Normal);
  }

  private async toggleAutoplay() {
    this.$emit('autoplay', !this.autoplay);
  }

  private async onBeforeUnload(event: Event) {
    if (this.isDashboardModified) {
      event.preventDefault();
      event.returnValue = false;
      return i18n.t('dashboard.cancel_edition_text').toString();
    }
  }

  async onBeforeRouteLeave(): Promise<boolean> {
    if (this.isDashboardModified) {
      const hasConfirmedCancellation = await this.confirmEditionCancellation();
      if (hasConfirmedCancellation) {
        this.cancelEdition();
      }
      return false;
    } else {
      if (this.mode === Mode.Creation) {
        this.cancelEdition();
      }
      return true;
    }
  }

  private get gridLayout(): LayoutSlot[] {
    return availableDashboardLayouts[this.dashboardDefinition.layout];
  }

  private get widgetDefinitions(): WidgetDefinition[] {
    return this.dashboardDefinition.widgetDefinitions;
  }

  private get name(): string {
    return !!this.dashboardDefinition ? this.dashboardDefinition.name : '';
  }

  private get layout(): LayoutSlot[] {
    return availableDashboardLayouts[this.dashboardDefinition.layout];
  }

  private get areThereFreeSlots(): boolean {
    return this.dashboardDefinition.widgetDefinitions.length < this.layout.length;
  }

  private threeDotsInnerPosition(index: number): string {
    switch (this.getCardsLayoutType()) {
      case 'one-times-one':
        return 'is-left';
      case 'one-times-two':
        return index === 1 ? 'is-left' : 'is-right';
      case 'two-times-two':
        return index === 1 || index === 3 ? 'is-left' : 'is-right';
      case 'two-times-three':
        return index === 2 || index === 5 ? 'is-left' : 'is-right';
      default:
        return 'is-right';
    }
  }

  private widgetHelpPosition(index: number): string {
    switch (this.getCardsLayoutType()) {
      case 'one-times-one':
        return 'is-bottom-right';
      case 'one-times-two':
        return index === 1 ? 'is-bottom-left' : 'is-bottom-right';
      case 'two-times-two':
        return index === 1 || index === 3 ? 'is-bottom-left' : 'is-bottom-right';
      case 'two-times-three':
        return index === 2 || index === 5 ? 'is-bottom-left' : 'is-bottom-right';
      default:
        return 'is-bottom-right';
    }
  }

  private getCardsLayoutType() {
    const n = this.widgetDefinitions.length;
    if (n === 1) {
      return 'one-times-one';
    } else if (n >= 0 && n <= 2) {
      return 'one-times-two';
    } else if (n >= 3 && n <= 4) {
      return 'two-times-two';
    } else {
      return 'two-times-three';
    }
  }

  private get isEditorEnabled() {
    return [Mode.Creation, Mode.Edition].includes(this.mode);
  }
}
