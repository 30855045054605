// --------------------------------------------------------------------------------
// <copyright file="router.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { isNil } from '@/utils/misc';
import { getCurrentInstance, Ref, shallowRef, watch } from 'vue';
import { Route } from 'vue-router';

/* When we migrate to Vue 3, this file will be deleted and we will replace the composables imports to own composables of Vue */
export function useRouter() {
  const instance = getCurrentInstance();

  if (isNil(instance)) {
    throw new Error('useRouter must be called within a setup function');
  }

  return instance.proxy?.$router;
}

export function useRoute(): Ref<Route> {
  const instance = getCurrentInstance();

  if (isNil(instance)) {
    throw new Error('useRoute must be called within a setup function');
  }

  const route = shallowRef(instance.proxy.$route);
  watch(
    () => instance.proxy.$route,
    () => {
      route.value = instance.proxy.$route;
    },
  );

  return route;
}
