// --------------------------------------------------------------------------------
// <copyright file="WidgetEnum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { useRootStore } from '@/store';
import WidgetDefinition, { CompatibleWidgetDefinition } from '../Charts/widgetDefinition';
import QuickSearchItem from '@/models/metrics/quickSearchItem';
import Customer from '../customer';
import { FilterTimeSpanEnum } from './FilterTimeSpanEnum';
import moment from 'moment';
import {
  ImportantMessageLevel,
  QuotesAbcCustomersData,
  QuotesSizeFilterData,
} from '../Charts/chartsData';
import { LaserOutputAndScrapTimelineChartMode } from './LaserOutputAndScrapTimelineChartMode';
import { LineChartMode } from './LineChartMode';
import WidgetFactory from '../Charts/widgetFactory';
import { getWidgetMachineType } from '../widgetMachineType';
import { MachineType } from './MachineType';
import { WidgetType } from './WidgetType';
import { DEFAULT_DAYS_PER_INTERVAL } from '../Charts/polStatusDueDateGenerator';
import { AggregateEnum } from './AggregateEnum';
import { metricsService } from '@/services/metrics.service';
import { isNil } from '@/utils/misc';
import { tenantsService } from '@/services/tenants.service';

export type ChildUpdater<T> = (
  value: T,
  wd: WidgetDefinition,
) => ParamConfiguration | Promise<ParamConfiguration>;

export type ParamValue = boolean | number | number[] | string | string[] | ImportantMessageLevel[];

export type RetrocompatibilityFunction<T> = (config: CompatibleWidgetDefinition) => T | undefined;
export interface ParamType<T> {
  defaultValue?: T;
  retrocompatibilityMap?: RetrocompatibilityFunction<T>;
  validation?: (value: T) => boolean;
  children?: Record<string, ChildUpdater<T>>;
}

export interface ParamRadio extends ParamType<boolean> {
  type: 'radio';
}

export interface ParamNumber extends ParamType<number> {
  type: 'number';
  min?: number;
  max?: number;
}

export interface ParamList extends ParamType<string> {
  type: 'list';
  values: string[];
}

export interface ParamSlider extends ParamType<number[]> {
  type: 'slider';
  tickValues: number[];
}

export type ParamConfiguration = ParamNumber | ParamRadio | ParamList | ParamSlider;

export interface Params {
  [key: string]: ParamConfiguration;
}

export enum DetailedViewEnum {
  // Laser
  CutStatesIntraday = 'laser_states_time_intraday',
  IntradayOeeTime = 'laser_oee_intraday',
  CumulativeCuttingTime = 'laser_cumulative_cutting_time',
  CumulativeCuttingTimePerShift = 'laser_cumulative_cutting_time_per_shift',
  LaserTodaysGas = 'laser_cutting_time_per_gas',

  // Quotes
  QuotesCustomerExpandedView = 'quotes_abc_customers',

  // Sales
  SalesCustomerGroupsDetailed = 'abc_customer_sales',

  // Tubes
  TubesCumulativeCuttingTime = 'tubes_cumulative_cutting_time',
  TubesCumulativeCuttingTimePerShift = 'tubes_cumulative_cutting_time_per_shift',
  TubesCuttingTimePerGas = 'tubes_cutting_time_per_gas',
}

export enum WidgetEnum {
  // Work center console > Cut
  LaserAvailability = 'laser_availability',
  LaserProductivity = 'laser_productivity',
  LaserOee = 'laser_oee',
  LaserStarveBlock = 'laser_starve_block',
  LaserOutputScrap = 'laser_output_scrap',
  LaserCuttingTime = 'laser_cutting_time',
  LaserAvailabilityHist = 'laser_availability_hist',
  LaserProductivityHist = 'laser_productivity_hist',
  LaserOeeHist = 'laser_oee_hist',
  LaserCuttingTimeHist = 'laser_cutting_time_hist',
  LaserStarveBlockHist = 'laser_starve_block_hist',
  LaserOutputAndScrapTimeline = 'laser_output_scrap_hist',
  CutStatus = 'laser_status',
  LaserCurrentWorkload = 'cutting_info',
  LaserStatesGantt = 'laser_states_gantt',
  LaserStatesGanttTable = 'laser_states_gantt_table',

  // Technology console
  TechnologyOverviewCuttingHoursTimeline = 'cutting_hours',
  TechnologyOverviewLaserConsumptionTimeline = 'overview_laser_consumption_timeline',
  TechnologyOverviewDrawerCycleTimes = 'overview_drawer_tab_bars',
  TechnologyOverviewSscFigures = 'overview_ssc_figures',
  TechnologyTemperatures = 'temperature_tab',
  TechnologyDrawerInformationTimeline = 'drawer_status_timeline',
  TechnologyDrawerCycleTimes = 'drawer_tab_bars',
  TechnologyLaserConsumptionTimeline = 'laser_tab_consumption',
  TechnologyLaserUsedVsNotUsedTimes = 'laser_tab_avail_hist',
  TechnologyLaserPowerUtilizationTimes = 'laser_tab_hist',
  TechnologyDriveCurrentTimeline = 'drives_tab_timeline',
  TechnologyDriveCurrentDifferenceHistogram = 'drives_tab_diffs_hist',
  TechnologyDriveAccumulatedCurrentDifferenceTimeline = 'drives_tab_accum_diffs',

  // Care console
  CareAlertOccurrencesTable = 'care_alert_occurrences_table',
  CareHealthTimeline = 'health_tab_all_timelines',
  CareHealthIndicators = 'health_tab_metrics',
  CareEventsTimeline = 'error_tab_bars_timeline',
  CareEventsByModule = 'error_tab_bars_by_module',
  CareCuttingHeadDiodesAndDrawerCyclesTimeline = 'cutting_head_drawer_diodes',
  CareCuttingHeadEventsTimeline = 'cutting_head_events_timeline',
  CareCuttingHeadTemperatures = 'ssc_temperatures',
  CareLaserConsumptionTimeline = 'care_laser_tab_consumption',
  CareLaserConsumptionAvailableVsUsedTimeline = 'care_laser_tab_avail_vs_used',
  CareLaserConsumptionPowerUtilizationTimeline = 'care_laser_tab_power_utilization_hist',
  CareOverviewDevice = 'care_overview_device',
  CareOverviewHealthIndicators = 'overview_tab_health_metrics',
  CareOverviewModuleEventsIndicators = 'overview_tab_events',

  EventsTable = 'error_tab_table',
  EventsTableExpandedView = 'events-table-expanded-view', // Only used on WidgetHelp
  CareErrorsAndWarningsBarChart = 'error_tab_bars', // Only used as ProcedureName

  // Work center console > Bend
  BendingAvailability = 'bending_availability',
  BendingTimeBetweenBend = 'bending_time_between_bend',
  BendingTopParts = 'bending_top_parts',
  BendingPerformancePart = 'bending_performance_part',
  BendingAvailabilityHist = 'bending_availability_hist',
  BendingTimeBetweenBendHist = 'bending_time_between_bend_hist',
  BendingPerformancePartHist = 'bending_performance_part_hist',
  BendingPerformanceHist = 'bending_performance_hist',
  BendingPerformance = 'bending_performance',
  BendStatus = 'bending_status',
  BendingNumberOfBendsTimeline = 'bending_bends_hist',
  BendStatesGanttTable = 'bend_states_gantt_table',
  BendSheetPartsGanttTable = 'bend_sheet_parts_gantt_table',
  BendProductivityFigures = 'bend_productivity_figures',
  BendShiftStatistics = 'bend_shift_statistics',
  BendRankingOfParts = 'bend_ranking_of_parts',
  BendFlowSpeedStatesGanttTable = 'bend_speed_flow_states_gantt_table',
  BendStatusOverview = 'bend_states_overview',

  // Work center console > Tube
  TubeAvailability = 'tubes_availability',
  TubeProductivity = 'tubes_productivity',
  TubeOee = 'tubes_oee',
  TubeStarveBlock = 'tubes_starve_block',
  TubeOutputScrap = 'tubes_output_scrap',
  TubeCuttingTime = 'tubes_cutting_time',
  TubeAvailabilityHist = 'tubes_availability_hist',
  TubeProductivityHist = 'tubes_productivity_hist',
  TubeOeeHist = 'tubes_oee_hist',
  TubeCuttingTimeHist = 'tubes_cutting_time_hist',
  TubeStarveBlockHist = 'tubes_starve_block_hist',
  TubeOutputScrapHist = 'tubes_output_scrap_hist',
  TubeStatus = 'tubes_status',
  TubeStatesGantt = 'tubes_states_gantt',

  // Generic
  CameraView = 'camera_view',
  FactoryOverview = 'factory_overview',
  OverviewData = 'overview_data',

  // Business console
  SalesMap = 'map_sales',
  QuotesMap = 'map_quotes',
  OpenSalesFunnel = 'open_sales_funnel',
  BusinessOverviewFigures = 'business_figures',

  // Business console > Quotes
  QuotesAbcCustomers = 'quotes_abc_customers',
  QuotesConvertedAmountTimeline = 'amount_vs_converted_amount',
  QuotesConversionRates = 'quotes_conversion_rates',
  QuotesConversionRatesGauge = 'quotes_conversion_rates_gauge',
  QuotesCustomerEvolution = 'a_group_amount_converted',
  QuotesSize = 'quotes_size',
  QuotesSizeFilter = 'quotes_size_filter',
  QuotesFigures = 'quotes_figures',
  QuotesFiguresDetailed = 'quotes_figures_detailed',
  QuotesTopQuotedMaterialsTimeline = 'quotes_materials',
  QuotesQuotedMaterialsList = 'quotes_materials_list',
  QuotesConvertedMap = 'map_converted_quotes',
  QuotesTopSalespeopleDetailed = 'top_salespeople_detailed',

  // Business console > Sales
  SalesCustomerGroups = 'abc_customer_sales',
  SalesTopSalespeople = 'top_salespeople',
  SalesFunnel = 'sales_funnel',
  SalesFigures = 'sales_figures',
  SalesTimeline = 'sales_timeline',
  SalesUnpaidDistribution = 'sales_unpaid_distribution',
  AmountVsNumberOfQuotesBySalesperson = 'amount_vs_number_of_quotes_by_salesperson',
  SalesAmountBySalesperson = 'sales_amount_by_sales_person',
  SalesItemChangesBySalesperson = 'sales_item_changes_by_sales_person',
  NumberOfSalesBySalesperson = 'number_of_sales_by_sales_person',
  SalesPerSalespersonTimeline = 'sales_per_salesperson',

  // Shop floor console > Manufacturing
  ManufacturingPOStatus = 'manufacturing_po_status',
  ManufacturingFinishedPOEstimatedVsActualPOL = 'manufacturing_PO_time_estimated_vs_executed',
  ManufacturingReleasedPOStatus = 'manufacturing_released_po_status',
  ManufacturingPOLStatusDueDate = 'manufacturing_POL_status_due_date',
  ManufacturingFigures = 'manufacturing_kpi',
  ManufacturingPOFinishedDueDate = 'manufacturing_finished_PO_due_date',

  // Dummy
  Dummy = 'dummy',
  NumberOfBendsKpiWidget = 'bending_number_of_bends_per_device',
  BendsPerPartKpiWidget = 'bending_bends_per_part_per_device',
  BendingToolChangesKpiWidget = 'bending_tool_changes_per_device',
  BendingProductionOutputKpiWidget = 'bending_production_output_per_device',
}

export function findWidgetType(widget: WidgetEnum) {
  switch (widget) {
    case WidgetEnum.CameraView:
      return WidgetType.CameraView;
    case WidgetEnum.SalesMap:
    case WidgetEnum.QuotesMap:
    case WidgetEnum.QuotesConvertedMap:
      return WidgetType.Map;
    case WidgetEnum.CareHealthIndicators:
    case WidgetEnum.CareOverviewDevice:
    case WidgetEnum.CareOverviewHealthIndicators:
    case WidgetEnum.CareOverviewModuleEventsIndicators:
    case WidgetEnum.BendingPerformance:
    case WidgetEnum.CareAlertOccurrencesTable:
    case WidgetEnum.QuotesFigures:
    case WidgetEnum.SalesFigures:
    case WidgetEnum.BusinessOverviewFigures:
    case WidgetEnum.ManufacturingFigures:
    case WidgetEnum.QuotesFiguresDetailed:
    case WidgetEnum.TechnologyOverviewSscFigures:
    case WidgetEnum.BendStatus:
    case WidgetEnum.CutStatus:
    case WidgetEnum.TubeStatus:
    case WidgetEnum.EventsTable:
    case WidgetEnum.BendProductivityFigures:
    case WidgetEnum.BendStatusOverview:
    case WidgetEnum.BendShiftStatistics:
    case WidgetEnum.BendRankingOfParts:
      return WidgetType.Slot;
    case WidgetEnum.LaserCurrentWorkload:
    case WidgetEnum.LaserStatesGanttTable:
    case WidgetEnum.EventsTableExpandedView:
    case WidgetEnum.BendStatesGanttTable:
      return WidgetType.Table;
    case WidgetEnum.NumberOfBendsKpiWidget:
    case WidgetEnum.BendsPerPartKpiWidget:
    case WidgetEnum.BendingToolChangesKpiWidget:
    case WidgetEnum.BendingProductionOutputKpiWidget:
      return WidgetType.KPI;
    default:
      return WidgetType.Echarts;
  }
}

export interface ModalConfiguration {
  type: string;
  propsData: string[];
  mapParamsToProps?: (params: any) => any;
}

type IsExpandableFn = (wd: WidgetDefinition) => boolean;
type QuickSearchMethod = (wd: WidgetDefinition) => Promise<QuickSearchItem[]>;
const KPI_WIDGET_MIN_HEIGHT = '200px';

export class Widget {
  params: Params = {};
  modalConfiguration?: ModalConfiguration;
  dblClickModalConfiguration?: ModalConfiguration;
  quickSearchModalConfiguration?: ModalConfiguration;

  isDeviceDependent = false;
  defaultDatepickerInterval: [string, string] = [
    moment().subtract(6, 'days').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ];

  private datepickerEnabled = false;
  private timeMenuEnabled = false;
  private shiftMenuEnabled = false;
  private axisSpanFilterEnabled = false;
  private _minHeight: string = 'unset';

  private static readonly DEFAULT_EXPANDABLE_FN = () => false;

  constructor(
    public widget: WidgetEnum,
    public isExpandableFn: IsExpandableFn = Widget.DEFAULT_EXPANDABLE_FN,
    public quickSearchMethod?: QuickSearchMethod,
  ) {}

  static createKpiWidget(
    widget: WidgetEnum,
    isExpandableFn: (wd: WidgetDefinition) => boolean = Widget.DEFAULT_EXPANDABLE_FN,
    quickSearchMethod?: QuickSearchMethod,
  ): Widget {
    return new Widget(widget, isExpandableFn, quickSearchMethod).withMinHeight(
      KPI_WIDGET_MIN_HEIGHT,
    );
  }

  getModalConfiguration: () => ModalConfiguration | undefined = () => undefined;

  makeExpandable(modalConfiguration: ModalConfiguration): this {
    this.isExpandableFn = () => true;
    this.modalConfiguration = modalConfiguration;
    return this;
  }

  makeExpandableIf(
    fn: (wd: WidgetDefinition) => boolean,
    modalConfiguration: ModalConfiguration,
  ): this {
    this.isExpandableFn = fn;
    this.modalConfiguration = modalConfiguration;
    return this;
  }

  makeExpandableOnDblClick(modalConfiguration: ModalConfiguration): this {
    this.dblClickModalConfiguration = modalConfiguration;
    return this;
  }

  withQuickSearchFunction(
    fn: (wd: WidgetDefinition) => Promise<QuickSearchItem[]>,
    modalConfiguration: ModalConfiguration,
  ): this {
    this.quickSearchMethod = fn;
    this.quickSearchModalConfiguration = modalConfiguration;
    return this;
  }

  withParams(params: Params): this {
    this.params = params;
    return this;
  }

  withDatepicker(defaultDatepickerInterval?: [string, string]): this {
    if (!isNil(defaultDatepickerInterval)) {
      this.defaultDatepickerInterval = defaultDatepickerInterval;
    }
    this.datepickerEnabled = true;
    return this;
  }

  withTimeMenu(): this {
    this.timeMenuEnabled = true;
    return this;
  }

  withShiftMenu(): this {
    this.shiftMenuEnabled = true;
    return this;
  }

  withAxisSpanFilter(): this {
    this.axisSpanFilterEnabled = true;
    return this;
  }

  /**
   * Defines the minimum height for the widget.
   *
   * It should bet set on widgets that need a minimum height for being
   * visualized properly.
   *
   * @param minHeight String with any of the valid values of CSS's min-with
   *   property.
   */
  withMinHeight(minHeight: string): this {
    this._minHeight = minHeight;
    return this;
  }

  makeDeviceDependent(): this {
    this.isDeviceDependent = true;
    return this;
  }

  get widgetType(): WidgetType {
    return findWidgetType(this.widget);
  }

  get machineType(): MachineType {
    return getWidgetMachineType(this.widget);
  }

  get isEcharts(): boolean {
    return this.widgetType === WidgetType.Echarts;
  }

  get isMap(): boolean {
    return this.widgetType === WidgetType.Map;
  }

  get isSlot(): boolean {
    return this.widgetType === WidgetType.Slot;
  }

  get isLineChart(): boolean {
    return WidgetFactory.createOptions(this.widget).isLineChart ?? false;
  }

  get hasDatepicker(): boolean {
    return (
      this.datepickerEnabled &&
      (this.machineType !== MachineType.Undefined || !this.isDeviceDependent)
    );
  }

  get hasTimeMenu(): boolean {
    return (
      this.timeMenuEnabled &&
      this.widget !== WidgetEnum.LaserStatesGantt &&
      this.widget !== WidgetEnum.TubeStatesGantt &&
      (![MachineType.Undefined, MachineType.FactoryOverview].includes(this.machineType) ||
        !this.isDeviceDependent)
    );
  }

  get hasShiftMenu(): boolean {
    return (
      this.shiftMenuEnabled &&
      this.widget !== WidgetEnum.LaserStatesGantt &&
      this.widget !== WidgetEnum.TubeStatesGantt &&
      (this.machineType !== MachineType.Undefined || !this.isDeviceDependent)
    );
  }

  get hasAxisSpanFilter(): boolean {
    return this.axisSpanFilterEnabled;
  }

  /**
   * Returns the minimum height defined by the widget.
   *
   * The value might be any of the CSS's min-with property.
   */
  get minHeight(): string {
    return this._minHeight;
  }

  get hasDownload(): boolean {
    return this.isLineChart && this.isEcharts;
  }

  get hasAggregates(): boolean {
    return this.isLineChart;
  }
}

const yearToDateInterval: [string, string] = [
  moment().startOf('year').format('YYYY-MM-DD'),
  moment().format('YYYY-MM-DD'),
];

const lineChartRetrocompatibilityMap: RetrocompatibilityFunction<string> = (
  config: CompatibleWidgetDefinition,
) => {
  return config.stackedColumns === true
    ? LineChartMode.StackedColumns
    : LineChartMode.NonStackedLines;
};

const stackedColumnParam: ParamConfiguration = {
  type: 'list',
  defaultValue: LineChartMode.NonStackedLines,
  values: Object.values(LineChartMode),
  retrocompatibilityMap: lineChartRetrocompatibilityMap,
};

const widgets: { [key in WidgetEnum]: Widget } = {
  // WCC
  // Cut
  [WidgetEnum.LaserAvailability]: new Widget(WidgetEnum.LaserAvailability)
    .makeExpandableIf((wd: WidgetDefinition) => wd.timeFilter === FilterTimeSpanEnum.Day, {
      type: 'cut-states-intraday',
      propsData: ['selectedShifts', 'selectedDeviceIds'],
    })
    .withTimeMenu()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.LaserProductivity]: new Widget(WidgetEnum.LaserProductivity)
    .withTimeMenu()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.LaserOee]: new Widget(WidgetEnum.LaserOee)
    .makeExpandableIf((wd) => wd.timeFilter === FilterTimeSpanEnum.Day, {
      type: 'oee-intraday',
      propsData: ['selectedDeviceIds', 'selectedShifts'],
    })
    .withTimeMenu()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.LaserStarveBlock]: new Widget(WidgetEnum.LaserStarveBlock)
    .withTimeMenu()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.LaserOutputScrap]: new Widget(WidgetEnum.LaserOutputScrap)
    .withTimeMenu()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.LaserCuttingTime]: new Widget(WidgetEnum.LaserCuttingTime)
    .makeExpandableIf((wd: WidgetDefinition) => wd.timeFilter === FilterTimeSpanEnum.Day, {
      type: 'cut-intraday',
      propsData: ['selectedShifts', 'selectedDeviceIds'],
    })
    .withTimeMenu()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.LaserAvailabilityHist]: new Widget(WidgetEnum.LaserAvailabilityHist)
    .withParams({
      mode: {
        ...stackedColumnParam,
        defaultValue: LineChartMode.StackedColumns,
      },
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.LaserProductivityHist]: new Widget(WidgetEnum.LaserProductivityHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.LaserOeeHist]: new Widget(WidgetEnum.LaserOeeHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.LaserCuttingTimeHist]: new Widget(WidgetEnum.LaserCuttingTimeHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.LaserStarveBlockHist]: new Widget(WidgetEnum.LaserStarveBlockHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.LaserOutputAndScrapTimeline]: new Widget(WidgetEnum.LaserOutputAndScrapTimeline)
    .withParams({
      mode: {
        type: 'list',
        defaultValue: LaserOutputAndScrapTimelineChartMode.RawMaterialUtilization,
        values: Object.values(LaserOutputAndScrapTimelineChartMode),
        retrocompatibilityMap: (config) => {
          if (config.stackedColumns !== undefined) {
            return config.stackedColumns
              ? LaserOutputAndScrapTimelineChartMode.StackedColumns.toString()
              : LaserOutputAndScrapTimelineChartMode.NonStackedLines.toString();
          }
        },
      },
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.CutStatus]: new Widget(WidgetEnum.CutStatus).makeDeviceDependent(),
  [WidgetEnum.LaserCurrentWorkload]: new Widget(WidgetEnum.LaserCurrentWorkload),
  [WidgetEnum.LaserStatesGantt]: new Widget(WidgetEnum.LaserStatesGantt),
  [WidgetEnum.LaserStatesGanttTable]: new Widget(WidgetEnum.LaserStatesGanttTable),

  // Technology
  [WidgetEnum.TechnologyOverviewCuttingHoursTimeline]: new Widget(
    WidgetEnum.TechnologyOverviewCuttingHoursTimeline,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyOverviewLaserConsumptionTimeline]: new Widget(
    WidgetEnum.TechnologyOverviewLaserConsumptionTimeline,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyOverviewDrawerCycleTimes]: new Widget(
    WidgetEnum.TechnologyOverviewDrawerCycleTimes,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyOverviewSscFigures]: new Widget(
    WidgetEnum.TechnologyOverviewSscFigures,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyTemperatures]: new Widget(WidgetEnum.TechnologyTemperatures)
    .makeDeviceDependent()
    .withParams({
      aggregate: {
        type: 'list',
        values: Object.values(AggregateEnum),
        defaultValue: 'max',
      },
    }),
  [WidgetEnum.EventsTable]: new Widget(WidgetEnum.EventsTable),
  // Unused. Added just to stop the compiler from complaining
  [WidgetEnum.EventsTableExpandedView]: new Widget(WidgetEnum.EventsTable),
  // CareErrorsAndWarningsBarChart is not used as a standalone widget, but is
  // required to reference a specific SP. We also need to add the WidgetEnum
  // here to avoid a compilation error.
  [WidgetEnum.CareErrorsAndWarningsBarChart]: new Widget(WidgetEnum.CareErrorsAndWarningsBarChart),
  [WidgetEnum.TechnologyDrawerInformationTimeline]: new Widget(
    WidgetEnum.TechnologyDrawerInformationTimeline,
  )
    .makeDeviceDependent()
    .withParams({
      aggregate: {
        type: 'list',
        values: Object.values(AggregateEnum),
        defaultValue: AggregateEnum.Q95.toString(),
      },
    }),
  [WidgetEnum.TechnologyDrawerCycleTimes]: new Widget(
    WidgetEnum.TechnologyDrawerCycleTimes,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyLaserConsumptionTimeline]: new Widget(
    WidgetEnum.TechnologyLaserConsumptionTimeline,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyLaserUsedVsNotUsedTimes]: new Widget(
    WidgetEnum.TechnologyLaserUsedVsNotUsedTimes,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyLaserPowerUtilizationTimes]: new Widget(
    WidgetEnum.TechnologyLaserPowerUtilizationTimes,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyDriveCurrentTimeline]: new Widget(WidgetEnum.TechnologyDriveCurrentTimeline)
    .makeDeviceDependent()
    .withParams({
      aggregate: {
        type: 'list',
        values: Object.values(AggregateEnum),
        defaultValue: 'q95',
      },
    }),
  [WidgetEnum.TechnologyDriveCurrentDifferenceHistogram]: new Widget(
    WidgetEnum.TechnologyDriveCurrentDifferenceHistogram,
  ).makeDeviceDependent(),
  [WidgetEnum.TechnologyDriveAccumulatedCurrentDifferenceTimeline]: new Widget(
    WidgetEnum.TechnologyDriveAccumulatedCurrentDifferenceTimeline,
  ).makeDeviceDependent(),

  // Care console
  // This one is unused
  [WidgetEnum.CareAlertOccurrencesTable]: new Widget(WidgetEnum.CareAlertOccurrencesTable),
  [WidgetEnum.CareHealthTimeline]: new Widget(WidgetEnum.CareHealthTimeline),
  [WidgetEnum.CareHealthIndicators]: new Widget(
    WidgetEnum.CareHealthIndicators,
  ).makeDeviceDependent(),
  [WidgetEnum.CareEventsTimeline]: new Widget(
    WidgetEnum.CareEventsTimeline,
  ).makeExpandableOnDblClick({
    type: 'modal-wrapped-events-table-expanded-view',
    propsData: [
      'selectedDeviceIds',
      'selectedTimeFilter',
      'selectedDateGrouping',
      'widgetDefinition',
    ],
    mapParamsToProps: (params) => ({
      timestamp: Array.isArray(params.value) ? params.value[0] : params.name,
    }),
  }),
  [WidgetEnum.CareEventsByModule]: new Widget(
    WidgetEnum.CareEventsByModule,
  ).makeExpandableOnDblClick({
    type: 'modal-wrapped-events-table-expanded-view',
    propsData: [
      'selectedDeviceIds',
      'selectedTimeFilter',
      'selectedDateGrouping',
      'paramValues',
      'widgetDefinition',
    ],
    mapParamsToProps: (params) => ({ module: params.name }),
  }),
  [WidgetEnum.CareCuttingHeadDiodesAndDrawerCyclesTimeline]: new Widget(
    WidgetEnum.CareCuttingHeadDiodesAndDrawerCyclesTimeline,
  ),
  [WidgetEnum.CareCuttingHeadEventsTimeline]: new Widget(WidgetEnum.CareCuttingHeadEventsTimeline)
    .makeExpandable({
      type: 'modal-wrapped-events-table-expanded-view',
      propsData: [
        'selectedDeviceIds',
        'selectedTimeFilter',
        'selectedDateGrouping',
        'widgetDefinition',
      ],
    })
    .makeExpandableOnDblClick({
      type: 'modal-wrapped-events-table-expanded-view',
      propsData: [
        'selectedDeviceIds',
        'selectedTimeFilter',
        'selectedDateGrouping',
        'widgetDefinition',
      ],
      mapParamsToProps: (params) => ({
        timestamp: Array.isArray(params.value) ? params.value[0] : params.name,
      }),
    }),
  [WidgetEnum.CareCuttingHeadTemperatures]: new Widget(WidgetEnum.CareCuttingHeadTemperatures),
  [WidgetEnum.CareLaserConsumptionTimeline]: new Widget(
    WidgetEnum.CareLaserConsumptionTimeline,
  ),
  [WidgetEnum.CareLaserConsumptionAvailableVsUsedTimeline]: new Widget(
    WidgetEnum.CareLaserConsumptionAvailableVsUsedTimeline,
  ),
  [WidgetEnum.CareLaserConsumptionPowerUtilizationTimeline]: new Widget(
    WidgetEnum.CareLaserConsumptionPowerUtilizationTimeline,
  ),
  [WidgetEnum.CareOverviewDevice]: new Widget(WidgetEnum.CareOverviewDevice).makeDeviceDependent(),
  [WidgetEnum.CareOverviewHealthIndicators]: new Widget(
    WidgetEnum.CareOverviewHealthIndicators,
  ).makeDeviceDependent(),
  [WidgetEnum.CareOverviewModuleEventsIndicators]: new Widget(
    WidgetEnum.CareOverviewModuleEventsIndicators,
  ).makeDeviceDependent(),

  // Bend
  [WidgetEnum.BendingAvailability]: new Widget(WidgetEnum.BendingAvailability)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.BendingTimeBetweenBend]: new Widget(WidgetEnum.BendingTimeBetweenBend)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.BendingTopParts]: new Widget(WidgetEnum.BendingTopParts)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.BendingPerformancePart]: new Widget(WidgetEnum.BendingPerformancePart)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.BendingAvailabilityHist]: new Widget(WidgetEnum.BendingAvailabilityHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withAxisSpanFilter()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.BendingTimeBetweenBendHist]: new Widget(WidgetEnum.BendingTimeBetweenBendHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.BendingPerformancePartHist]: new Widget(WidgetEnum.BendingPerformancePartHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.BendingPerformanceHist]: new Widget(WidgetEnum.BendingPerformanceHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withAxisSpanFilter()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.BendingPerformance]: new Widget(WidgetEnum.BendingPerformance).makeDeviceDependent(),
  [WidgetEnum.BendStatus]: new Widget(WidgetEnum.BendStatus).makeDeviceDependent(),
  [WidgetEnum.BendingNumberOfBendsTimeline]: new Widget(WidgetEnum.BendingNumberOfBendsTimeline)
    .makeDeviceDependent()
    .withDatepicker()
    .withAxisSpanFilter()
    .withShiftMenu(),
  [WidgetEnum.BendStatesGanttTable]: new Widget(WidgetEnum.BendStatesGanttTable),
  [WidgetEnum.BendSheetPartsGanttTable]: new Widget(WidgetEnum.BendSheetPartsGanttTable),
  [WidgetEnum.BendProductivityFigures]: new Widget(WidgetEnum.BendProductivityFigures),
  [WidgetEnum.BendRankingOfParts]: new Widget(WidgetEnum.BendRankingOfParts),
  [WidgetEnum.BendShiftStatistics]: new Widget(WidgetEnum.BendShiftStatistics).withMinHeight(
    '490px',
  ),
  [WidgetEnum.BendFlowSpeedStatesGanttTable]: new Widget(WidgetEnum.BendFlowSpeedStatesGanttTable),
  [WidgetEnum.BendStatusOverview]: new Widget(WidgetEnum.BendStatusOverview),

  // Tube
  [WidgetEnum.TubeAvailability]: new Widget(WidgetEnum.TubeAvailability)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.TubeProductivity]: new Widget(WidgetEnum.TubeProductivity)
    .withTimeMenu()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.TubeOee]: new Widget(WidgetEnum.TubeOee)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.TubeStarveBlock]: new Widget(WidgetEnum.TubeStarveBlock)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.TubeOutputScrap]: new Widget(WidgetEnum.TubeOutputScrap)
    .makeDeviceDependent()
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.TubeCuttingTime]: new Widget(WidgetEnum.TubeCuttingTime)
    .makeDeviceDependent()
    .makeExpandableIf((wd: WidgetDefinition) => wd.timeFilter === FilterTimeSpanEnum.Day, {
      type: 'cut-intraday',
      propsData: ['selectedShifts', 'selectedDeviceIds'],
    })
    .withTimeMenu()
    .withShiftMenu(),
  [WidgetEnum.TubeAvailabilityHist]: new Widget(WidgetEnum.TubeAvailabilityHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withAxisSpanFilter()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.TubeProductivityHist]: new Widget(WidgetEnum.TubeProductivityHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.TubeOeeHist]: new Widget(WidgetEnum.TubeOeeHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withAxisSpanFilter()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.TubeCuttingTimeHist]: new Widget(WidgetEnum.TubeCuttingTimeHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withAxisSpanFilter()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.TubeStarveBlockHist]: new Widget(WidgetEnum.TubeStarveBlockHist)
    .withParams({
      mode: stackedColumnParam,
    })
    .withDatepicker()
    .withAxisSpanFilter()
    .withShiftMenu()
    .makeDeviceDependent(),
  [WidgetEnum.TubeOutputScrapHist]: new Widget(WidgetEnum.TubeOutputScrapHist)
    .withParams({
      mode: {
        type: 'list',
        defaultValue: LaserOutputAndScrapTimelineChartMode.NonStackedLines,
        values: Object.values(LaserOutputAndScrapTimelineChartMode),
        retrocompatibilityMap: (config) => {
          if (config.stackedColumns !== undefined) {
            return config.stackedColumns
              ? LaserOutputAndScrapTimelineChartMode.StackedColumns.toString()
              : LaserOutputAndScrapTimelineChartMode.NonStackedLines.toString();
          }
        },
      },
    })
    .withDatepicker()
    .withShiftMenu()
    .withAxisSpanFilter()
    .makeDeviceDependent(),
  [WidgetEnum.TubeStatus]: new Widget(WidgetEnum.TubeStatus).makeDeviceDependent(),
  [WidgetEnum.TubeStatesGantt]: new Widget(WidgetEnum.TubeStatesGantt),

  // Generic
  [WidgetEnum.CameraView]: new Widget(WidgetEnum.CameraView).makeDeviceDependent(),
  [WidgetEnum.FactoryOverview]: new Widget(WidgetEnum.FactoryOverview),
  [WidgetEnum.OverviewData]: new Widget(WidgetEnum.OverviewData),

  // BC
  // Overview
  [WidgetEnum.SalesMap]: new Widget(WidgetEnum.SalesMap).withDatepicker(yearToDateInterval),
  [WidgetEnum.QuotesMap]: new Widget(WidgetEnum.QuotesMap).withDatepicker(yearToDateInterval),
  [WidgetEnum.OpenSalesFunnel]: new Widget(WidgetEnum.OpenSalesFunnel).withAxisSpanFilter(),
  [WidgetEnum.BusinessOverviewFigures]: new Widget(
    WidgetEnum.BusinessOverviewFigures,
  ).withDatepicker(yearToDateInterval),

  // Quotes
  [WidgetEnum.QuotesAbcCustomers]: new Widget(WidgetEnum.QuotesAbcCustomers)
    .makeExpandable({
      type: 'quotes-customers-expanded-view',
      propsData: ['selectedTimeFilter'],
    })
    .makeExpandableOnDblClick({
      type: 'detailed-customer',
      propsData: ['selectedTimeFilter', 'selectedDateGrouping'],
      mapParamsToProps: (params) => params.data,
    })
    .withQuickSearchFunction(
      async (wd: WidgetDefinition) => {
        const [startDate, endDate] = wd.timeFilter as [string, string];
        const tenantId = tenantsService.store.currentIdDh()!;
        const controller = new AbortController();
        const data = await metricsService.getSMBSMetricsBC<QuotesAbcCustomersData[]>(
          WidgetEnum.QuotesAbcCustomers,
          tenantId,
          { date: moment().format('YYYY-MM-DD'), startDate, endDate },
          controller,
        );
        const customers =
          data?.map((customer: any) => new Customer(customer.name, customer.no_)) ?? [];
        useRootStore().setCustomers(customers);
        return customers.map((customer) => ({
          id: customer.no,
          name: customer.name,
        }));
      },
      {
        type: 'detailed-customer',
        propsData: ['selectedTimeFilter', 'selectedDateGrouping'],
      },
    )
    .withDatepicker(yearToDateInterval),
  [WidgetEnum.QuotesConvertedAmountTimeline]: new Widget(WidgetEnum.QuotesConvertedAmountTimeline)
    .withDatepicker(yearToDateInterval)
    .withAxisSpanFilter(),
  [WidgetEnum.QuotesConversionRates]: new Widget(WidgetEnum.QuotesConversionRates).withDatepicker(
    yearToDateInterval,
  ),
  [WidgetEnum.QuotesConversionRatesGauge]: new Widget(
    WidgetEnum.QuotesConversionRatesGauge,
  ).withDatepicker(yearToDateInterval),
  [WidgetEnum.QuotesCustomerEvolution]: new Widget(WidgetEnum.QuotesCustomerEvolution)
    .withDatepicker(yearToDateInterval)
    .withAxisSpanFilter(),
  [WidgetEnum.QuotesSize]: new Widget(WidgetEnum.QuotesSize)
    .withParams({
      groupNumber: {
        type: 'number',
        defaultValue: 5,
        min: 3,
        max: 10,
        children: {
          amountFilter: async (groupNumberValue: number, wd: WidgetDefinition) => {
            const tenantIdDh = tenantsService.store.currentIdDh()!;
            const dateRangeString = wd.timeFilter as [string, string];
            const controller = new AbortController();
            const data = await metricsService.getSMBSMetricsBC<QuotesSizeFilterData[]>(
              WidgetEnum.QuotesSizeFilter,
              tenantIdDh,
              {
                paramNumber: groupNumberValue,
                startDate: dateRangeString?.[0],
                endDate: dateRangeString?.[1],
              },
              controller,
            );
            const ticks = data.map((filter: QuotesSizeFilterData) => filter.group_limit);
            return {
              type: 'slider',
              tickValues: ticks,
            };
          },
        },
      },
    })
    .withDatepicker(yearToDateInterval),
  [WidgetEnum.QuotesSizeFilter]: new Widget(WidgetEnum.QuotesSizeFilter),
  [WidgetEnum.QuotesFigures]: new Widget(WidgetEnum.QuotesFigures).withDatepicker(
    yearToDateInterval,
  ),
  [WidgetEnum.QuotesFiguresDetailed]: new Widget(WidgetEnum.QuotesFiguresDetailed).withDatepicker(
    yearToDateInterval,
  ),
  [WidgetEnum.QuotesTopQuotedMaterialsTimeline]: new Widget(
    WidgetEnum.QuotesTopQuotedMaterialsTimeline,
  )
    .withDatepicker(yearToDateInterval)
    .withAxisSpanFilter(),
  [WidgetEnum.QuotesQuotedMaterialsList]: new Widget(WidgetEnum.QuotesQuotedMaterialsList)
    .withParams({
      numberOfMaterials: {
        type: 'number',
        defaultValue: 6,
        min: 1,
        max: 20,
      },
    })
    .withDatepicker(yearToDateInterval),

  [WidgetEnum.QuotesConvertedMap]: new Widget(WidgetEnum.QuotesConvertedMap).withDatepicker(
    yearToDateInterval,
  ),
  [WidgetEnum.QuotesTopSalespeopleDetailed]: new Widget(WidgetEnum.QuotesTopSalespeopleDetailed)
    .withParams({
      topNumber: {
        type: 'number',
        defaultValue: 3,
        min: 3,
        max: 9,
      },
    })
    .withDatepicker(yearToDateInterval),

  // Sales
  [WidgetEnum.SalesCustomerGroups]: new Widget(WidgetEnum.SalesCustomerGroups)
    .makeExpandable({
      type: 'detailed-customer-groups',
      propsData: ['selectedTimeFilter'],
    })
    .withDatepicker(yearToDateInterval),
  [WidgetEnum.SalesTopSalespeople]: new Widget(WidgetEnum.SalesTopSalespeople)
    .withParams({
      topNumber: {
        type: 'number',
        defaultValue: 3,
        min: 3,
        max: 9,
      },
    })
    .makeExpandable({
      type: 'detailed-top-sales-people',
      propsData: ['selectedTimeFilter', 'selectedDateGrouping', 'paramValues'],
    })
    .withDatepicker(yearToDateInterval),
  [WidgetEnum.AmountVsNumberOfQuotesBySalesperson]: new Widget(
    WidgetEnum.AmountVsNumberOfQuotesBySalesperson,
  ),
  [WidgetEnum.SalesAmountBySalesperson]: new Widget(WidgetEnum.SalesAmountBySalesperson),
  [WidgetEnum.SalesItemChangesBySalesperson]: new Widget(WidgetEnum.SalesItemChangesBySalesperson),
  [WidgetEnum.NumberOfSalesBySalesperson]: new Widget(WidgetEnum.NumberOfSalesBySalesperson),
  [WidgetEnum.SalesPerSalespersonTimeline]: new Widget(WidgetEnum.SalesPerSalespersonTimeline),
  [WidgetEnum.SalesFunnel]: new Widget(WidgetEnum.SalesFunnel)
    .withParams({
      ratio: {
        type: 'radio',
        defaultValue: false,
      },
    })
    .withDatepicker(yearToDateInterval)
    .withAxisSpanFilter(),
  [WidgetEnum.SalesFigures]: new Widget(WidgetEnum.SalesFigures).withDatepicker(yearToDateInterval),
  [WidgetEnum.SalesTimeline]: new Widget(WidgetEnum.SalesTimeline)
    .withDatepicker(yearToDateInterval)
    .withAxisSpanFilter(),
  [WidgetEnum.SalesUnpaidDistribution]: new Widget(WidgetEnum.SalesUnpaidDistribution)
    .withParams({
      belowTarget: {
        type: 'radio',
        defaultValue: false,
      },
    })
    .withDatepicker(yearToDateInterval),

  // SFC
  // Manufacturing
  [WidgetEnum.ManufacturingPOStatus]: new Widget(WidgetEnum.ManufacturingPOStatus).withDatepicker(
    yearToDateInterval,
  ),
  [WidgetEnum.ManufacturingFinishedPOEstimatedVsActualPOL]: new Widget(
    WidgetEnum.ManufacturingFinishedPOEstimatedVsActualPOL,
  )
    .withDatepicker(yearToDateInterval)
    .withAxisSpanFilter(),
  [WidgetEnum.ManufacturingReleasedPOStatus]: new Widget(
    WidgetEnum.ManufacturingReleasedPOStatus,
  ).withDatepicker(yearToDateInterval),
  [WidgetEnum.ManufacturingPOLStatusDueDate]: new Widget(WidgetEnum.ManufacturingPOLStatusDueDate)
    .withParams({
      daysPerInterval: {
        type: 'number',
        defaultValue: DEFAULT_DAYS_PER_INTERVAL,
        min: 1,
        max: 999,
      },
    })
    .withDatepicker(yearToDateInterval),
  [WidgetEnum.ManufacturingFigures]: new Widget(WidgetEnum.ManufacturingFigures).withDatepicker(
    yearToDateInterval,
  ),
  [WidgetEnum.ManufacturingPOFinishedDueDate]: new Widget(WidgetEnum.ManufacturingPOFinishedDueDate)
    .withParams({
      daysPerInterval: {
        type: 'number',
        defaultValue: DEFAULT_DAYS_PER_INTERVAL,
        min: 1,
        max: 999,
      },
    })
    .withDatepicker(yearToDateInterval),

  // Dummy
  [WidgetEnum.Dummy]: new Widget(WidgetEnum.Dummy).withMinHeight('290px'),
  [WidgetEnum.NumberOfBendsKpiWidget]: Widget.createKpiWidget(WidgetEnum.NumberOfBendsKpiWidget),
  [WidgetEnum.BendsPerPartKpiWidget]: Widget.createKpiWidget(WidgetEnum.BendsPerPartKpiWidget),
  [WidgetEnum.BendingToolChangesKpiWidget]: Widget.createKpiWidget(
    WidgetEnum.BendingToolChangesKpiWidget,
  ),
  [WidgetEnum.BendingProductionOutputKpiWidget]: Widget.createKpiWidget(
    WidgetEnum.BendingProductionOutputKpiWidget,
  ),
};

export { widgets };
