// --------------------------------------------------------------------------------
// <copyright file="utils.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { User } from '@/models/user';
import { usersService, UserUpdate } from '@/services/users.service';
import { isNil } from '@/utils/misc';
import { computed, onBeforeMount, PropsWithDefaults, ref } from 'vue';
import { EmitFn } from 'vue/types/v3-setup-context';

type UserWizardProps<T extends { user?: User | null }> = PropsWithDefaults<
  Readonly<T>,
  { user: null }
>;
type UserWizardEmit = EmitFn<('save' | 'close')[]>;

export function useUserWizard<T extends { user?: User | null }>(
  props: UserWizardProps<T>,
  emit: UserWizardEmit,
  defaultUser: User,
  onBeforeMountCallback?: () => void | Promise<void>,
) {
  const userCopy = ref<User | null>(null);

  onBeforeMount(() => {
    userCopy.value = props.user ?? defaultUser;

    onBeforeMountCallback?.();
  });

  async function submit(authorizedConsoles?: ConsoleEnum[]) {
    if (isEdit.value) {
      const updateUser: UserUpdate = User.GetCopy(userCopy.value! as User);
      updateUser.isAcceptedTermsOfAgreement = undefined;
      updateUser.isAutoplay = undefined;
      updateUser.autoplayPeriod = undefined;
      updateUser.dashboards = undefined;
      await usersService.update(updateUser, authorizedConsoles);
    } else {
      await usersService.create(userCopy.value! as User);
    }
    emit('save');
    emit('close');
  }

  const isEdit = computed(() => {
    return !isNil(props.user);
  });

  return { userCopy, submit, isEdit };
}
