// --------------------------------------------------------------------------------
// <copyright file="localStorage.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

const COMPACT_MODE = 'compactMode';

class LocalStorageService {
  private setItem(name: string, value: string) {
    localStorage.setItem(name, value);
  }

  private getItem(name: string): string | null {
    return localStorage.getItem(name);
  }

  private getBoolean(name: string): boolean {
    return this.getItem(name) === 'true';
  }

  private removeItem(name: string) {
    localStorage.removeItem(name);
  }

  get compactMode(): boolean {
    return this.getBoolean(COMPACT_MODE);
  }

  set compactMode(value: boolean) {
    this.setItem(COMPACT_MODE, `${value}`);
  }

  removeVuex() {
    this.removeItem('vuex');
  }
}

export const localStorageService = new LocalStorageService();
