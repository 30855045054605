
import { Component, Prop, Vue } from 'vue-property-decorator';
import DevicesSelect from '@/components/inputs/DevicesSelect.vue';
import EmailsTagInput, { EmailEntry } from '@/components/inputs/EmailsTagInput.vue';
import { Alert } from '@/models/alert';
import AlertsMetricsSelect from '../inputs/AlertsMetricsSelect.vue';
import AlertsOperatorsSelect from '../inputs/AlertsOperatorsSelect.vue';
import AlertsActionsSelect from '../inputs/AlertsActionsSelect.vue';
import { alertsService } from '@/services/alerts.service';
import { Tenant } from '@/models/tenant';
import { AlertAction, AlertKPI } from '@/models/enums/AlertsEnums';
import { isEmpty } from '@/utils/misc';
import { DeviceModelType } from '@/models/deviceBaseModel';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';

@Component({
  computed: {
    AlertAction: () => AlertAction,
    ConsoleEnum: () => ConsoleEnum,
  },
  components: {
    DevicesSelect,
    EmailsTagInput,
    AlertsMetricsSelect,
    AlertsOperatorsSelect,
    AlertsActionsSelect,
  },
})
export default class AlertEditionModal extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  @Prop({ required: true })
  private alert!: Alert;

  private editAlert = Alert.GetEmpty();
  private thresholdValid = true;
  private defaultEmails: string[] = [];
  private selectedKpi: AlertKPI | null = null;
  private readonly deviceTypeLaser = DeviceModelType.Laser;

  private isSubmitting = false;
  private defaultEmailsAreLoading = false;

  private mounted() {
    this.editAlert = Alert.GetCopy(this.alert);

    if (!this.editAlert.deviceId) {
      this.editAlert.deviceId = 0;
    }
    this.selectedKpi = alertsService.store.getAlertKpiByMetric(this.editAlert!.metric);
    this.thresholdValid = true;

    this.loadDefaultEmails();
  }

  private async loadDefaultEmails() {
    this.defaultEmailsAreLoading = true;
    this.defaultEmails = await alertsService
      .getAlertEmail(this.alert.id!)
      .then((data) => (this.defaultEmails = data))
      .finally(() => (this.defaultEmailsAreLoading = false));
  }

  private get isEnabled() {
    if (this.editAlert && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity() && this.thresholdValid && this.isEmailValid();
    }
    return false;
  }

  private handleChange(event: any): void {
    this.editAlert = { ...this.editAlert, [event.target.name]: event.target.value };
  }

  private checkValidity(input: string) {
    const thresholdValue: number = +input;
    this.thresholdValid = !isNaN(thresholdValue) && input !== '';
  }

  private onEmailSelectionChanged(emailsAndIds: EmailEntry[]) {
    this.editAlert = {
      ...this.editAlert,
      recipients: emailsAndIds.map((item) => item.userId),
    };
  }

  private onSubmit() {
    this.isSubmitting = true;
    this.editAlert.tenantId = this.tenant.id;
    this.editAlert.metric = this.selectedKpi!.kpi;

    const alert = this.editAlert;
    if (alert.deviceId === 0) {
      alert.deviceId = null;
    }
    alertsService
      .update(alert)
      .then(() => {
        this.$emit('save');
        this.$emit('close');
      })
      .finally(() => (this.isSubmitting = false));
  }

  private isEmailValid(): boolean {
    const alertAction = this.editAlert.action;
    // FIXME: We get a string instead of AlertAction from AlertsActionsSelect
    return (
      // eslint-disable-next-line
      alertAction == AlertAction.Web ||
      // eslint-disable-next-line
      (alertAction == AlertAction.WebAndEmail && !isEmpty(this.editAlert.recipients))
    );
  }
}
