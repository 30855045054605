// --------------------------------------------------------------------------------
// <copyright file="app-insights.plugin.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { isNil } from '@/utils/misc';
import {
  ClickAnalyticsPlugin,
  IClickAnalyticsConfiguration,
} from '@microsoft/applicationinsights-clickanalytics-js';
import { ApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web';
import { PluginObject } from 'vue';
import VueRouter from 'vue-router';

export interface AppInsightsConfiguration {
  instrumentationKey: string;
  router: VueRouter;
  appInsightsConfig: IConfiguration & IConfig;
  enableClickAnalytics?: boolean;
}

export const appInsightsPlugin: PluginObject<AppInsightsConfiguration> = {
  install(Vue, options) {
    if (isNil(options)) {
      throw new Error('Configuration is needed to launch the Application Insights plugin');
    }

    const extensions = [...(options.appInsightsConfig.extensions ?? [])];
    const extensionConfig = {
      ...options.appInsightsConfig.extensionConfig,
    };

    if (options.enableClickAnalytics !== false) {
      const clickPluginInstance = new ClickAnalyticsPlugin();
      const clickPluginConfiguration: IClickAnalyticsConfiguration = {
        autoCapture: true,
        dataTags: {
          useDefaultContentNameOrId: true,
        },
      };
      extensions.push(clickPluginInstance);
      extensionConfig[clickPluginInstance.identifier] = clickPluginConfiguration;
    }

    const instrumentationKey =
      options.appInsightsConfig.instrumentationKey ?? options.instrumentationKey;
    const appInsightsConfig: IConfiguration & IConfig = {
      ...options.appInsightsConfig,
      instrumentationKey,
      extensions,
      extensionConfig,
    };
    options.appInsightsConfig.instrumentationKey ??= options.instrumentationKey;

    const appInsights = new ApplicationInsights({
      config: appInsightsConfig,
    });
    appInsights.loadAppInsights();

    setupPageTracking(options, appInsights);

    Object.defineProperty(Vue.prototype, '$appInsights', {
      get: function get() {
        return appInsights;
      },
    });
  },
};

function setupPageTracking(options: AppInsightsConfiguration, appInsights: ApplicationInsights) {
  const router = options.router;

  const baseName = '(Vue App)';

  router.beforeEach((route, from, next) => {
    const name = `${baseName} / ${route.name}${route.hash}`;
    appInsights.startTrackPage(name);
    next();
  });

  router.afterEach((route) => {
    const name = `${baseName} / ${route.name}${route.hash}`;
    const url = `${location.protocol}//${location.host}${route.fullPath}`;
    appInsights.stopTrackPage(name, url);
    appInsights.flush();
  });
}
