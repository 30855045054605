
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AlertsOperatorsSelect from '@/components/inputs/AlertsOperatorsSelect.vue';
import AlertsMetricsSelect from '@/components/inputs/AlertsMetricsSelect.vue';
import { isEmpty } from '@/utils/misc';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { translateFilterTimeAxisSpanEnum } from '@/utils/i18n';
import { AlertKPI, AlertOperator } from '@/models/enums/AlertsEnums';
import { translateUnit } from '@/utils/alerts';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';

@Component({
  methods: {
    isEmpty,
    translateFilterTimeAxisSpanEnum,
  },
  components: {
    AlertsMetricsSelect,
    AlertsOperatorsSelect,
  },
  computed: {
    ConsoleEnum: () => ConsoleEnum,
  },
})
export default class KpiSelectionForm extends Vue {
  @Prop({ default: null })
  private kpi!: AlertKPI | null;

  @Prop({ default: AlertOperator.None })
  private operator!: AlertOperator;

  @Prop({ default: null })
  private threshold!: string | null;

  @Prop({ default: FilterTimeAxisSpanEnum.None })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  @Ref('main-form')
  private mainForm!: HTMLFormElement;

  private readonly dateGroupingOptions: FilterTimeAxisSpanEnum[] = Object.values(
    FilterTimeAxisSpanEnum,
  )
    .filter((value) => !isNaN(Number(value)) && value !== FilterTimeAxisSpanEnum.None)
    .map(Number);

  isValid(): boolean {
    return this.mainForm.checkValidity();
  }

  private get kpiUnit(): string | undefined {
    return translateUnit(this.kpi?.unit ?? null, null);
  }
}
