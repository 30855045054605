
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Aggregate } from '@/models/metrics/aggregates';
import { values } from '@/utils/enum';

@Component
export default class AggregatesMenu extends Vue {
  @Prop({ default: () => [] })
  private selectedAggregates!: Aggregate[];

  @Prop({ default: 'is-right' })
  private position!: string;

  @Ref('dropdown')
  private dropdown: any;

  private aggregateOptions = values(Aggregate);

  toggle() {
    this.dropdown.toggle();
  }

  private onOptionSelected(aggregate: Aggregate, isSelected: boolean) {
    if (isSelected) {
      this.$emit('input', [...this.selectedAggregates, aggregate]);
    } else {
      this.$emit(
        'input',
        [...this.selectedAggregates].filter((other: string) => other !== aggregate),
      );
    }
  }
}
