
import ShiftFilter from '@/components/ShiftFilter.vue';
import TimeSpanFilter from '@/components/TimeSpanFilter.vue';
import i18n from '@/i18n';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Device } from '@/models/device';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { MachineType } from '@/models/enums/MachineType';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Aggregate, arrayToNumber, numberToArray } from '@/models/metrics/aggregates';
import { UserTarget } from '@/models/userTarget';
import { getWidgetMachineType } from '@/models/widgetMachineType';
import { devicesService } from '@/services/devices.service';
import { shiftsService } from '@/services/shifts.service';
import { userTargetsService } from '@/services/userTargets.service';
import { translateFilterTimeSpanEnum } from '@/utils/i18n';
import { isEmpty } from '@/utils/misc';
import moment from 'moment';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import ThreeDotsOptions from '../ThreeDotsOptions.vue';
import { usersService } from '@/services/users.service';
import { User } from '@/models/user';
import NewFeatureBadge from '@/components/NewFeatureBadge.vue';

@Component({
  components: {
    TimeSpanFilter,
    ShiftFilter,
    ThreeDotsOptions,
    NewFeatureBadge,
  },
  methods: {
    isEmpty,
  },
})
export default class WidgetCard extends Vue {
  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private isWidgetSelector!: boolean;

  @Prop({ required: true, type: Boolean })
  private selfManagedFilters!: boolean;

  @Prop({ required: true })
  private paramValues!: Record<string, any>;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Ref('bDatepicker')
  private bDatepicker: any;

  private datepickerDates: Date[] = [];

  private mounted() {
    if (Array.isArray(this.widgetDefinition.timeFilter)) {
      this.datepickerDates = this.widgetDefinition.timeFilter.map((x: string) => new Date(x));
    }
  }

  private onChangeDatepicker(payload: Date[]): void {
    const dates = payload.map((x: Date) =>
      moment(x).subtract(x.getTimezoneOffset(), 'minutes').format('YYYY-MM-DD'),
    ) as [string, string];
    const wd = this.widgetDefinition.getCopy({ timeFilter: dates });
    this.$emit('change', wd);
  }

  private onChangeTimespan(payload: FilterTimeSpanEnum): void {
    const wd = this.widgetDefinition.getCopy({ timeFilter: payload });
    this.$emit('change', wd);
  }

  private onChangeShift(payload: number[]): void {
    const wd = this.widgetDefinition.getCopy({ shifts: payload });
    this.$emit('change', wd);
  }

  private onChangeTarget(target: number[]): void {
    const wd = this.widgetDefinition.getCopy({ target });
    this.$emit('change', wd);
  }

  private onChangeTimeAxisSpan(axisSpan: FilterTimeAxisSpanEnum): void {
    const wd = this.widgetDefinition.getCopy({ axisSpan });
    this.$emit('change', wd);
  }

  private onChangeAggregates(payload: Aggregate[]): void {
    const aggregates = arrayToNumber(payload);
    this.$emit('aggregate-change', aggregates);
    const wd = this.widgetDefinition.getCopy({ aggregates });
    this.$emit('change', wd);
  }

  private toggleDatepicker() {
    this.bDatepicker.toggle();
  }

  // FIXME: Duplicated with Chart
  private getSalesTimelineTargets(): number[] | undefined {
    const monthlyPaid = this.getUserTarget(TargetsMetric.SalesFunnelMonthlyPaid)?.target1;
    const r3 = this.getUserTarget(TargetsMetric.SalesFunnelR3)?.target1;
    const r4 = this.getUserTarget(TargetsMetric.SalesFunnelR4)?.target1;

    if (monthlyPaid && r3 && r4) {
      return [monthlyPaid, r3, r4];
    } else {
      return undefined;
    }
  }

  private getUserTarget(targetMetric: TargetsMetric | null): UserTarget | undefined {
    return userTargetsService.store.get(targetMetric);
  }

  // FIXME: Duplicated with Chart
  private getDefaultTarget() {
    if (this.widgetDefinition.widget === WidgetEnum.SalesTimeline) {
      return this.getSalesTimelineTargets();
    } else {
      const nTargets = userTargetsService.store.getMetricNumberOfTargetValues(this.targetsMetric);
      if (nTargets === 0) {
        return;
      }
      const defaultTarget: number[] = [];
      const userTarget = this.getUserTarget(this.targetsMetric);

      if (userTarget) {
        defaultTarget.push(userTarget.target1);
        if (nTargets === 2 && !!userTarget.target2) {
          defaultTarget.push(userTarget.target2);
        }
      }

      return userTarget ? defaultTarget : undefined;
    }
  }

  // FIXME: Duplicated with Chart
  private getTargets() {
    return this.selfManagedFilters ? this.widgetDefinition.target : this.getDefaultTarget();
  }

  private get widgetTitle(): string {
    return i18n.t(`widget.${this.widgetDefinition.widget}`).toString();
  }

  private get hasExpandButton(): boolean {
    return this.widgetDefinition.canExpand();
  }

  private get hasDatepicker(): boolean {
    return this.selfManagedFilters && this.widgetDefinition.hasDatepicker;
  }

  private get hasTimeMenu(): boolean {
    return this.selfManagedFilters && this.widgetDefinition.hasTimeMenu;
  }

  private get hasShiftMenu(): boolean {
    return this.selfManagedFilters && this.widgetDefinition.hasShiftMenu;
  }

  private get hasTarget(): boolean {
    return this.targetsMetric !== TargetsMetric.None;
  }

  private get hasQuickSearch(): boolean {
    return !!this.widgetDefinition.quickSearchFunction;
  }

  private get hasParams(): boolean {
    return Object.keys(this.widgetDefinition.params).length > 0;
  }

  private get hasTagSection(): boolean {
    const widgetsWithLabels = [
      WidgetEnum.CameraView,
      WidgetEnum.BendStatus,
      WidgetEnum.CutStatus,
      WidgetEnum.TubeStatus,
    ];
    return (
      this.selfManagedFilters &&
      (this.hasShiftMenu || widgetsWithLabels.includes(this.widgetDefinition.widget))
    );
  }

  private get dateGrouping() {
    return this.widgetDefinition.axisSpan ?? FilterTimeAxisSpanEnum.Day;
  }

  private get aggregates(): string[] {
    return numberToArray(this.widgetDefinition.aggregates);
  }

  private get targetsMetric(): TargetsMetric | null {
    return !!this.widgetDefinition
      ? this.getWidgetTargetMetric(this.widgetDefinition.widget)
      : null;
  }

  private get quickSearchFunction() {
    return this.widgetDefinition.quickSearchFunction;
  }

  private get selectedTimespanTranslated() {
    return Array.isArray(this.widgetDefinition.timeFilter)
      ? ''
      : translateFilterTimeSpanEnum(this.widgetDefinition.timeFilter);
  }

  private getWidgetTargetMetric(widget: WidgetEnum): TargetsMetric {
    let widgetString = Object.keys(WidgetEnum).find(
      (key: string) => WidgetEnum[key as keyof typeof WidgetEnum] === widget,
    )!;

    // HACK (temporary): We don't want a target for LaserAvailability pie chart,
    // but it will be added in the future.
    if (widgetString !== 'LaserProductivityHist') {
      widgetString = widgetString.replace('Hist', '');
    }

    return widgetString in TargetsMetric
      ? TargetsMetric[widgetString as keyof typeof TargetsMetric]
      : TargetsMetric.None;
  }

  private getShiftName(id: number): string {
    return shiftsService.store.getNameById(id);
  }

  private get showThreeDots(): boolean {
    return (
      (this.widgetDefinition.hasDownload ||
        (this.hasTarget && this.selfManagedFilters) ||
        this.hasQuickSearch ||
        (this.widgetDefinition.hasAxisSpanFilter && this.selfManagedFilters) ||
        this.widgetDefinition.hasAggregates ||
        this.hasParams) &&
      !this.isWidgetSelector
    );
  }

  private get machineType(): MachineType {
    return getWidgetMachineType(this.widgetDefinition.widget);
  }

  private get deviceIds(): string[] {
    return this.widgetDefinition.deviceIds;
  }

  private get devices(): Device[] {
    return devicesService.store.get();
  }

  private get subtitle(): string {
    if (isEmpty(this.deviceIds)) {
      return i18n.t('report.overview').toString();
    } else {
      return this.devices.find((dev: Device) => dev.deviceId === this.deviceIds[0])?.name ?? '';
    }
  }

  private get showBigLoader(): boolean {
    return (
      this.isFetchingForTheFirstTime &&
      this.loading &&
      (this.widgetDefinition.isEcharts || this.widgetDefinition.isSlot)
    );
  }

  private get showSmallLoader(): boolean {
    return (
      !this.isFetchingForTheFirstTime &&
      this.loading &&
      (this.widgetDefinition.isEcharts || this.widgetDefinition.isSlot)
    );
  }

  private get user(): User {
    return usersService.store.current();
  }

  private get isBetaEnabled(): boolean {
    return this.user.isBetaEnabled;
  }
}
