
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/models/user';
import { Tenant } from '@/models/tenant';
import DashboardsCarousel from './dashboard/DashboardsCarousel.vue';
import { Route } from 'vue-router';
import { Routes } from '@/router/routes';
import { useRootStore } from '@/store';
import { tenantsService } from '@/services/tenants.service';
import { isNil } from '@/utils/misc';
import { usersService } from '@/services/users.service';

@Component({
  components: {
    DashboardsCarousel,
  },
})
export default class Index extends Vue {
  private mounted() {
    this.go();
  }

  @Watch('loadedInfoUser')
  @Watch('isTechnologyUser')
  @Watch('isSupportUser')
  private go() {
    if (!this.loadedInfoUser) {
      return;
    }

    if (this.isTechnologyUser) {
      this.$router.replace({
        name: Routes.TechnologyConsole,
      });
    } else if (this.isSupportUser) {
      this.$router.replace({
        name: Routes.EServiceCenter,
      });
    }
  }

  private get loadedInfoUser(): boolean {
    return useRootStore().userInfoLoaded;
  }

  private get termsAccepted(): boolean {
    return this.user.isAcceptedTermsOfAgreement || useRootStore().termsAcceptedSession;
  }

  private get hasTenant(): boolean {
    return !isNil(this.tenant);
  }

  private get isTechnologyUser(): boolean {
    return this.user.isTechnologyUser;
  }

  private get isSupportUser(): boolean {
    return this.user.isServiceUserType;
  }

  private get tenant(): Tenant | null {
    return tenantsService.store.current();
  }

  private get user(): User {
    return usersService.store.current();
  }

  private async beforeRouteLeave(to: Route, from: Route, next: (r?: boolean) => void) {
    const dashboardsCarousel = this.$refs.dashboard as DashboardsCarousel | undefined;
    const continueRoute = (await dashboardsCarousel?.onBeforeRouteLeave()) ?? true;
    next(continueRoute);
  }
}
