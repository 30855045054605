/* eslint-disable @typescript-eslint/no-empty-function */
/*
 * --------------------------------------------------------------------------------
 * <copyright file="modals.ts" company="Bystronic Laser AG">
 *  Copyright (C) Bystronic Laser AG 2021-2024
 * </copyright>
 * --------------------------------------------------------------------------------
 */

import { User, UserAuthenticationType } from '@/models/user';
import { Tenant } from '@/models/tenant';
import GlobalAdminWizard from '@/components/users/wizards/GlobalAdminWizard.vue';
import SubsidiaryAdminWizard from '@/components/users/wizards/SubsidiaryAdminWizard.vue';
import { tenantsService } from '@/services/tenants.service';
import CustomerUserWizard from '@/components/users/wizards/CustomerUserWizard.vue';
import i18n from '@/i18n';
import ServiceGlobalWizard from '@/components/users/wizards/ServiceGlobalWizard.vue';
import ServiceUserWizard from '@/components/users/wizards/ServiceUserWizard.vue';
import TechnologyWizard from '@/components/users/wizards/TechnologyWizard.vue';
import { currentUser } from '@/utils/permissionUtils';
import { modalService } from '@/library-services/modal.service';
import { Vue } from 'vue-property-decorator';

export function openUserWizard(
  parentComponent: Vue,
  component: any,
  user: User | null,
  subsidiaryId: number | null = null,
  customer: Tenant | null = null,
  onClose = () => {},
) {
  modalService.open({
    parent: parentComponent,
    component,
    hasModalCard: true,
    trapFocus: true,
    canCancel: ['x'],
    props: {
      user,
      subsidiaryId,
      customer,
    },
    events: {
      save: () => onClose(),
    },
  });
}

export function openGlobalAdminWizard(
  parentComponent: Vue,
  user: User | null = null,
  onClose = () => {},
) {
  openUserWizard(parentComponent, GlobalAdminWizard, user, null, null, onClose);
}

export async function openSubsidiaryAdminWizard(
  parentComponent: Vue,
  user: User | null = null,
  subsidiaryId: number | null = null,
  onClose = () => {},
) {
  openUserWizard(parentComponent, SubsidiaryAdminWizard, user, subsidiaryId, null, onClose);
}

export async function openCustomerUserWizard(
  parentComponent: Vue,
  user: User | null = null,
  onClose = () => {},
) {
  let customer: Tenant | null = null;
  if (currentUser().isCustomerAdmin && currentUser().customerId) {
    customer = await tenantsService.getById(currentUser().customerId);
  }
  openUserWizard(parentComponent, CustomerUserWizard, user, null, customer, onClose);
}

export function openServiceGlobalUserWizard(
  parentComponent: Vue,
  user: User | null = null,
  onClose = () => {},
) {
  if (user?.authenticationType === UserAuthenticationType.Local) {
    // TODO: Create a DialogComponent
    parentComponent.$buefy.dialog.alert({
      message: i18n.t('user_migrated_from_local_to_microsoft_message').toString(),
      onConfirm: () => {
        const fixedUser = User.GetCopy(user);
        fixedUser.authenticationType = UserAuthenticationType.Microsoft;
        return openUserWizard(parentComponent, ServiceGlobalWizard, fixedUser, null, null, onClose);
      },
    });
  } else {
    openUserWizard(parentComponent, ServiceGlobalWizard, user, null, null, onClose);
  }
}

export async function openServiceUserWizard(
  parentComponent: Vue,
  user: User | null = null,
  subsidiaryId: number | null = null,
  onClose = () => {},
) {
  if (user?.authenticationType === UserAuthenticationType.Local) {
    parentComponent.$buefy.dialog.alert({
      message: i18n.t('user_migrated_from_local_to_microsoft_message').toString(),
      onConfirm: () => {
        const fixedUser = User.GetCopy(user);
        fixedUser.authenticationType = UserAuthenticationType.Microsoft;
        openUserWizard(parentComponent, ServiceUserWizard, fixedUser, subsidiaryId, null, onClose);
      },
    });
  } else {
    openUserWizard(parentComponent, ServiceUserWizard, user, subsidiaryId, null, onClose);
  }
}

export function openTechnologyUserWizard(
  parentComponent: Vue,
  user: User | null = null,
  onClose = () => {},
) {
  if (user?.authenticationType === UserAuthenticationType.Local) {
    parentComponent.$buefy.dialog.alert({
      message: i18n.t('user_migrated_from_local_to_microsoft_message').toString(),
      onConfirm: () => {
        const fixedUser = User.GetCopy(user);
        fixedUser.authenticationType = UserAuthenticationType.Microsoft;
        openUserWizard(parentComponent, TechnologyWizard, fixedUser, null, null, onClose);
      },
    });
  } else {
    openUserWizard(parentComponent, TechnologyWizard, user, null, null, onClose);
  }
}
