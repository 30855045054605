// --------------------------------------------------------------------------------
// <copyright file="Mode.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum Mode {
  Create = 'create',
  Edit = 'edit',
  View = 'view',
}
