// --------------------------------------------------------------------------------
// <copyright file="FilterTimeAxisSpanEnum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

/**
 * Defines the date grouping (aggregation) across time of the data.
 *
 * For example, if we have data for a period of a week (7 values), with Day
 * grouping we'd get these 7 values, but with Week grouping we'd get just one
 * value that would be the sum of the 7.
 */
export enum FilterTimeAxisSpanEnum {
  None = 0,
  Hour = 6,
  Day = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  Year = 5,
}
