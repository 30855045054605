// --------------------------------------------------------------------------------
// <copyright file="devices.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import { currentUser } from '@/utils/permissionUtils';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { customersService } from '@/services/tenants.service';

export async function getAllDevices(): Promise<Device[]> {
  if (canCurrentUserHaveDevices()) {
    return await devicesService.getAll();
  } else {
    devicesService.store.updateAll([]);
    return Promise.resolve([]);
  }
}

export async function getAllDevicesByCustomerId(customerId: number): Promise<Device[]> {
  if (canCurrentUserHaveDevices()) {
    const devices = await devicesService.getAllByCustomerId(customerId);
    if (customersService.store.current()?.id === customerId) {
      devicesService.store.updateAll(devices);
    }
    return devices;
  } else {
    devicesService.store.updateAll([]);
    return Promise.resolve([]);
  }
}

function canCurrentUserHaveDevices() {
  return (
    currentUser().consoles.includes(ConsoleEnum.WCC) ||
    currentUser().isGlobalAdmin ||
    currentUser().isServiceUserType ||
    currentUser().isTechnologyUser
  );
}
