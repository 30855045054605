// --------------------------------------------------------------------------------
// <copyright file="users.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { NullUser, User } from '@/models/user';
import i18n from '@/i18n';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { useRootStore } from '@/store';
import moment from 'moment';
import { isEmpty, isNil } from '@/utils/misc';
import DashboardDefinition from '@/models/Charts/dashboardDefinition';
import { usePersistentStore } from '@/store/persistent';

export type UserData = Omit<User, 'dashboards'> & { customDashboardsConfiguration: string };

export type UserUpdate = Omit<
  User,
  'isAcceptedTermsOfAgreement' | 'isAutoplay' | 'autoplayPeriod' | 'dashboards'
> & {
  isAcceptedTermsOfAgreement?: boolean;
  isAutoplay?: boolean;
  autoplayPeriod?: number;
  dashboards?: DashboardDefinition[];
};

class UsersService {
  store = new (class {
    current(): User {
      return usePersistentStore().currentUser ?? NullUser;
    }

    update(user: User | null) {
      usePersistentStore().setCurrentUser(user);
    }

    acceptTermsAndConditions() {
      useRootStore().setTermsAcceptedSession(true);
    }
  })();

  async get(): Promise<User[]> {
    return axios
      .get<UserData[]>(`${BaseUrl}/users`)
      .then((response) => response.data.map((user) => this.deserializeUser(user)));
  }

  /**
   * Returns true if there's already a user with this email.
   */
  async emailExists(email: string): Promise<boolean> {
    return axios
      .get<boolean>(`${BaseUrl}/users/check-email/${email}`)
      .then((response) => response.data);
  }

  async delete(id: number): Promise<void> {
    return axios.delete(`${BaseUrl}/users/${id}`);
  }

  async update(user: UserUpdate, authorizedConsoles?: ConsoleEnum[]): Promise<void> {
    const locations = user.consoles.includes(ConsoleEnum.WCC) ? user.locations : [];
    const consoles = isNil(authorizedConsoles)
      ? []
      : authorizedConsoles.map((console) => ({
          console,
          enabled: user.consoles.includes(console),
        }));

    const activationDates = this.parseActivationDatesForUpdate(
      consoles?.map(({ console }) => console),
      user,
    );

    return axios
      .put(`${BaseUrl}/users/`, {
        ...user,
        consoles,
        locations,
        customDashboardsConfiguration: isNil(user.dashboards)
          ? undefined
          : user.serializeDashboards(),
        ...activationDates,
      })
      .then(() => {
        const currentUser = this.store.current();
        if (currentUser.equals(user as User)) {
          this.store.update({
            ...user,
            isAcceptedTermsOfAgreement:
              user.isAcceptedTermsOfAgreement ?? currentUser.isAcceptedTermsOfAgreement,
            isAutoplay: user.isAutoplay ?? currentUser.isAutoplay,
            autoplayPeriod: user.autoplayPeriod ?? currentUser.autoplayPeriod,
            dashboards: user.dashboards ?? currentUser.dashboards,
          });
        }
      });
  }

  async updateDashboards() {
    const currentUser = this.store.current();
    await axios.put(`${BaseUrl}/users/me/dashboards`, {
      customDashboardsConfiguration: currentUser.serializeDashboards(),
    });
  }

  create(user: User): Promise<void> {
    const locations = user.consoles.includes(ConsoleEnum.WCC) ? user.locations : [];

    return axios.post(`${BaseUrl}/users/`, {
      ...user,
      locations,
      wccActivationDate: this.parseNullableDate(user.wccActivationDate),
      wccEndDate: this.parseNullableDate(user.wccEndDate),
      bcActivationDate: this.parseNullableDate(user.bcActivationDate),
      bcEndDate: this.parseNullableDate(user.bcEndDate),
      sfcActivationDate: this.parseNullableDate(user.sfcActivationDate),
      sfcEndDate: this.parseNullableDate(user.sfcEndDate),
    });
  }

  async me(): Promise<User> {
    const response = await axios.get<UserData>(`${BaseUrl}/users/me`);
    const user = this.deserializeUser(response.data);
    this.store.update(user);
    return user;
  }

  /**
   * Stores that the user has accepted the terms and conditions.
   *
   * If rememberDecision is true, it saves the decision in the database
   * and sends and email with the terms and conditions document.
   *
   * @throws Error if sending the email has failed.
   */
  async acceptTermsAndConditions(rememberDecision = false): Promise<void> {
    await this.store.acceptTermsAndConditions();

    if (rememberDecision) {
      const user = User.GetCopy(this.store.current());
      user.isAcceptedTermsOfAgreement = true;
      await axios.put(`${BaseUrl}/users/me/accept-terms`);
      this.store.update(user);
      const errorMessage = await usersService.notifyTerms();

      if (!isEmpty(errorMessage)) {
        throw Error(errorMessage);
      }
    }
  }

  async notifyTerms(): Promise<string> {
    const response = await axios.post<string>(`${BaseUrl}/users/me/notify-terms`);
    return response.data;
  }

  async getTermsAndConditionsHtmlContent(lang: string): Promise<string> {
    const url = await this.getTermsAndConditionsHtmlContentUrl(lang);
    // Create a custom Axios instance to avoid sending an Authorization header
    // (added by the interceptor) to the blob storage, which would make it fail.
    const nonAuthorizationAxios = axios.create();
    const response = await nonAuthorizationAxios.get<string>(url);
    return response.data;
  }

  private async getTermsAndConditionsHtmlContentUrl(lang: string): Promise<string> {
    const response = await axios.get<string>(`${BaseUrl}/users/terms-html/${lang}`);
    return response.data;
  }

  async getTermsAndConditionsUrl(lang: string): Promise<string> {
    const response = await axios.get<string>(`${BaseUrl}/users/terms/${lang}`, {
      params: {
        customDownloadFileName: `${i18n.t('terms.terms_and_conditions')}.pdf`,
      },
    });
    return response.data;
  }

  async changePassword(id: number, email: string, password: string): Promise<void> {
    return axios.put(`${BaseUrl}/users/${id}/password`, { email, password });
  }

  async toggleBeta(): Promise<void> {
    return await axios.put(`${BaseUrl}/users/me/toggle-beta`).then(async () => {
      const me = this.store.current();
      me.isBetaEnabled = !me.isBetaEnabled;
      this.store.update(me);
    });
  }

  deserializeUser(userData: UserData): User {
    return new User(
      userData.id,
      userData.name,
      userData.customerId,
      userData.subsidiaryId,
      userData.email,
      userData.authenticationType,
      userData.password,
      userData.isAcceptedTermsOfAgreement,
      User.deserializeDashboards(userData.customDashboardsConfiguration),
      userData.timestampAcceptedTermsOfAgreement,
      userData.isAutoplay,
      userData.autoplayPeriod,
      this.parseNullableDate(userData.wccActivationDate) ?? null,
      this.parseNullableDate(userData.wccEndDate) ?? null,
      this.parseNullableDate(userData.bcActivationDate) ?? null,
      this.parseNullableDate(userData.bcEndDate) ?? null,
      this.parseNullableDate(userData.sfcActivationDate) ?? null,
      this.parseNullableDate(userData.sfcEndDate) ?? null,
      userData.consoles ?? [],
      userData.locations ?? [],
      userData.billable,
      '',
      '',
      userData.userType,
      userData.otherMemberships,
      userData.hasAccessToBeta,
      userData.isBetaEnabled,
      userData.betaFeatures,
    );
  }

  private parseNullableDate(date: Date | string | null): Date | undefined {
    return !!date ? moment(date).utcOffset(0, true).toDate() : undefined;
  }

  private parseActivationDatesForUpdate(consoles: ConsoleEnum[] | undefined, user: UserUpdate) {
    return consoles
      ?.map((console) => {
        switch (console) {
          case ConsoleEnum.WCC:
            return {
              wccActivationDate: this.parseNullableDate(user.wccActivationDate),
              wccEndDate: this.parseNullableDate(user.wccEndDate),
            };
          case ConsoleEnum.BC:
            return {
              bcActivationDate: this.parseNullableDate(user.bcActivationDate),
              bcEndDate: this.parseNullableDate(user.bcEndDate),
            };
          case ConsoleEnum.SFC:
            return {
              sfcActivationDate: this.parseNullableDate(user.sfcActivationDate),
              sfcEndDate: this.parseNullableDate(user.sfcEndDate),
            };
          default:
            return {};
        }
      })
      .reduce(
        (prev, curr) =>
          Object.assign(
            {
              wccActivationDate: undefined,
              wccEndDate: undefined,
              bcActivationDate: undefined,
              bcEndDate: undefined,
              sfcActivationDate: undefined,
              sfcEndDate: undefined,
            },
            prev,
            curr,
          ),
        {},
      );
  }
}

export const usersService = new UsersService();
