// --------------------------------------------------------------------------------
// <copyright file="bendShiftsStatisticsRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { BendShiftStatisticsData, ShiftStatistics } from '../chartsData';
import { mockBendShiftStatisticsData } from '../mockWidgetSelectorData';
import { isEmpty } from '@/utils/misc';

export class BendShiftsStatisticsRetriever extends DataRetriever<BendShiftStatisticsData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<BendShiftStatisticsData[] | null> {
    const data = await mockBendShiftStatisticsData(
      selectedDevices,
      selectedShifts,
      timeSpan as FilterTimeSpanEnum,
    );
    if (isEmpty(data)) {
      return null;
    }
    return this.calculateTotals(data!);

    // Pending implementation in the backend.
    // return metricsService.getDevicesMetrics<BendShiftStatisticsData[]>(
    //   this.procedure,
    //   {
    //     tenantIdDh: this.tenantIdDh,
    //     deviceIds: selectedDevices,
    //     shifts: selectedShifts,
    //     timeSpan: timeSpan as FilterTimeSpanEnum,
    //   },
    //   this.controller,
    // );
  }

  private calculateTotals(data: BendShiftStatisticsData[]): BendShiftStatisticsData[] {
    return data.map((shift) => {
      const initialStatistics: ShiftStatistics = {
        toolChanges: 0,
        weight: 0,
        weightHandled: 0,
        bendsHour: 0,
        highFlow: 0,
        lowFlow: 0,
        noFlow: 0,
        automatic: 0,
        semiAutomatic: 0,
        nonProductive: 0,
        highFlowPercent: 0,
        lowFlowPercent: 0,
        noFlowPercent: 0,
        automaticPercent: 0,
        semiAutomaticPercent: 0,
        nonProductivePercent: 0,
      };

      const deviceStatisticsTotals: ShiftStatistics = shift.deviceStatistics.reduce(
        (acc, device) => {
          Object.keys(acc).forEach((key) => {
            acc[key as keyof ShiftStatistics] += device[key as keyof ShiftStatistics];
          });
          return acc;
        },
        { ...initialStatistics },
      );

      const adjustPercentages = (fields: (keyof ShiftStatistics)[], total: number) => {
        if (total > 0) {
          fields.forEach((field) => {
            deviceStatisticsTotals[field] = (deviceStatisticsTotals[field] * 100) / total;
          });
        }
      };

      const bendingFlowFields: (keyof ShiftStatistics)[] = [
        'highFlowPercent',
        'lowFlowPercent',
        'noFlowPercent',
      ];
      const totalBendingFlowPercent = bendingFlowFields.reduce(
        (sum, field) => sum + deviceStatisticsTotals[field],
        0,
      );
      adjustPercentages(bendingFlowFields, totalBendingFlowPercent);

      const productionModeFields: (keyof ShiftStatistics)[] = [
        'automaticPercent',
        'semiAutomaticPercent',
        'nonProductivePercent',
      ];
      const totalProductionModePercent = productionModeFields.reduce(
        (sum, field) => sum + deviceStatisticsTotals[field],
        0,
      );
      adjustPercentages(productionModeFields, totalProductionModePercent);

      return {
        ...shift,
        deviceStatisticsTotals,
      };
    });
  }
}
