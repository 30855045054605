// --------------------------------------------------------------------------------
// <copyright file="main.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import './hooks';
import Vue from 'vue';
import App from '@/App.vue';
import pinia from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import { configurationService } from '@/services/configuration.service';
import * as AxiosForbiddenInterceptor from '@/interceptors/axios-interceptor-error';
import * as AxiosAuthInterceptor from '@/interceptors/axios-interceptor-auth';
import { authServicePlugin } from './plugins/auth.service.plugin';
import ECharts from 'vue-echarts';
import * as EchartsCore from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markArea';
import 'echarts/lib/component/markPoint';
import {
  BarChart,
  GaugeChart,
  LineChart,
  TreemapChart,
  ScatterChart,
  BoxplotChart,
  FunnelChart,
  PieChart,
  SunburstChart,
  CustomChart,
} from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  DataZoomComponent,
  AxisPointerComponent,
} from 'echarts/components';

import {
  Autocomplete,
  Button,
  Navbar,
  Menu,
  Icon,
  Table,
  Select,
  Field,
  Switch,
  Toast,
  Dialog,
  Input,
  Modal,
  Datetimepicker,
  Numberinput,
  Tabs,
  Checkbox,
  Radio,
  Sidebar,
  Datepicker,
  Progress,
  Collapse,
  Timepicker,
  Carousel,
  Dropdown,
  Taginput,
  Loading,
  Tag,
  Skeleton,
  Tooltip,
  Slider,
  Message,
  Breadcrumb,
  Image,
  ConfigProgrammatic,
} from 'buefy';
import { spinnerPlugin } from '@/plugins/spinner.plugin';
import { signalrPlugin } from '@/plugins/signalr.plugin';
import {
  email,
  length,
  max,
  max_value,
  min,
  min_value,
  oneOf,
  regex,
  required,
  size,
} from 'vee-validate/dist/rules';
import { configure, extend } from 'vee-validate';
import { password_repeat } from './utils/validation';
import { localeService } from '@/library-services/locale.service';
import environment from '@/utils/environment';
import { AppInsightsConfiguration, appInsightsPlugin } from './plugins/app-insights.plugin';

initApp();

async function setUpSignalR() {
  Vue.use(signalrPlugin);
  await Vue.prototype.$signalr.connect();
}

function addBuefyComponents() {
  const customIconConfig = {
    customIconPacks: {
      'bys-icon': {
        sizes: {
          default: '',
        },
        iconPrefix: 'bys-icon-',
      },
      'dashboard-icons': {
        iconPrefix: 'dashboard-icon-',
      },
    },
  };
  Vue.use(Autocomplete);
  Vue.use(Breadcrumb);
  Vue.use(Button);
  Vue.use(Carousel);
  Vue.use(Checkbox);
  Vue.use(Collapse);
  Vue.use(Datepicker);
  Vue.use(Datetimepicker);
  Vue.use(Dialog);
  Vue.use(Dropdown);
  Vue.use(Field);
  Vue.use(Icon);
  Vue.use(Image);
  Vue.use(Input);
  Vue.use(Loading);
  Vue.use(Menu);
  Vue.use(Message);
  Vue.use(Modal);
  Vue.use(Navbar);
  Vue.use(Numberinput);
  Vue.use(Progress);
  Vue.use(Radio);
  Vue.use(Select);
  Vue.use(Sidebar);
  Vue.use(Skeleton);
  Vue.use(Slider);
  Vue.use(Switch);
  Vue.use(Table);
  Vue.use(Tabs);
  Vue.use(Tag);
  Vue.use(Taginput);
  Vue.use(Timepicker);
  Vue.use(Toast);
  Vue.use(Tooltip);
  ConfigProgrammatic.setOptions(customIconConfig);
}

function addEchartsComponents() {
  EchartsCore.use([
    CanvasRenderer,
    BarChart,
    PieChart,
    LineChart,
    GaugeChart,
    TreemapChart,
    ScatterChart,
    BoxplotChart,
    FunnelChart,
    SunburstChart,
    CustomChart,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DatasetComponent,
    ToolboxComponent,
    DataZoomComponent,
    AxisPointerComponent,
    MarkPointComponent,
  ]);

  Vue.component('v-chart', ECharts);
}

// Sets up VeeValidate for form validation
function setUpVeeValidate() {
  // Load validation rules
  extend('required', required);
  extend('min', min);
  extend('max', max);
  extend('min_value', min_value);
  extend('max_value', max_value);
  extend('length', length);
  extend('regex', regex);
  extend('size', size);
  extend('email', email);
  extend('oneOf', oneOf);
  extend('password_repeat', password_repeat);

  // Translate VeeValidate validation messages with Vue i18n
  // Example here:
  // https://codesandbox.io/s/veevalidate-30-vuei18n-integration-9vs4l?from-embed=&file=/src/i18n.js
  // (the documentation is wrong)
  configure({
    defaultMessage: (fieldKey: string, values: any) => {
      // override the field name
      values._field_ = i18n.t(fieldKey);
      return i18n.t(`validation.${values._rule_}`, values).toString();
    },
  });
}

function setUpAppInsights() {
  const spaConfig = configurationService.getConfig();
  const appInsightsPluginConfig: AppInsightsConfiguration = {
    instrumentationKey: spaConfig.instrumentationKeyAppInsight,
    router,
    appInsightsConfig: {
      disableTelemetry: environment.isLocalDevelopmentEnvironment(),
    },
    enableClickAnalytics: false, // Pending further research
  };
  Vue.use(appInsightsPlugin, appInsightsPluginConfig);
}

async function initApp() {
  await configurationService.loadConfig();

  Vue.config.productionTip = false;

  addBuefyComponents();
  addEchartsComponents();
  setUpVeeValidate();
  await setUpSignalR();

  Vue.use(authServicePlugin);
  Vue.use(spinnerPlugin);
  setUpAppInsights();

  const app = new Vue({
    pinia,
    router,
    i18n,
    // TODO: When we migrate to Vue3, we should remove this provide and create a composable to use $spinner,
    // and change the injection of each components using composable.
    provide: {
      spinner: Vue.prototype.$spinner,
      auth: Vue.prototype.$auth,
      signalr: Vue.prototype.$signalr,
    },
    render: (h) => h(App),
    created() {
      AxiosAuthInterceptor.AuthInterceptor(this.$auth);
      AxiosForbiddenInterceptor.ResponseErrorInterceptor(this.$appInsights);
      i18n.locale = localeService.store.currentLocale || localeService.browserCurrentLocale;
    },
  });

  app.$mount('#app');

  Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occurred
    // info: Vue specific error information such as lifecycle hooks, events etc.
    if (err.message === 'canceled') {
      // Request canceled by user, do not track the exception
      return;
    }
    if (err instanceof Error) {
      app.$appInsights.trackException({ exception: err, vm, info });
    } else {
      app.$appInsights.trackException({
        exception: new Error(err),
        vm,
        info,
      });
    }

    throw err;
  };
}
