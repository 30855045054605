
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { Target } from '@/models/target';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import { userTargetsService } from '@/services/userTargets.service';

@Component
export default class TargetsMetricsSelect extends Vue {
  @Prop({ required: true })
  private console!: ConsoleEnum;

  @Prop({ default: '' })
  private value!: TargetsMetric;

  @Prop({ default: false, type: Boolean })
  private required!: boolean;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  private items: TargetsMetric[] = [];

  private mounted(): void {
    this.getItems();
  }

  private getItems() {
    this.items = userTargetsService.store
      .getAllTargets()
      .filter((t: Target) => t.consoleId === this.console)
      .map((t: Target) => t.targetMetric);
  }

  private handleSelect(value: TargetsMetric) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
