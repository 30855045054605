
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mode } from '@/models/enums/Mode';
import { ShiftModel } from '@/models/shiftModel';
import { shiftsService } from '@/services/shifts.service';
import { Shift } from '@/models/shift';
import { ShiftOverflowEnum } from '@/models/enums/ShiftOverflowEnum';
import { Tenant } from '@/models/tenant';
import { tenantsService } from '@/services/tenants.service';
import i18n from '@/i18n';

@Component
export default class ShiftFormModal extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  @Prop({ required: true })
  private shiftModel!: ShiftModel;

  @Prop({ required: true })
  private mode!: Mode;

  @Prop({ required: true })
  private shift!: Shift;

  private editableShift = Shift.GetEmpty();

  private isSubmitting = false;

  private async mounted() {
    if (this.isCreateMode) {
      this.editableShift.shiftModelId = this.shiftModel!.id;
    } else {
      this.editableShift = this.shift;
    }
  }

  private handleChange(name: string, value: any): void {
    this.editableShift = Shift.GetCopy(this.editableShift);
    this.$set(this.editableShift, name, value);
  }

  private handleDateChange(name: string, value: Date): void {
    this.editableShift = Shift.GetCopy(this.editableShift);
    this.$set(this.editableShift, `${name}Hh`, value.getHours());
    this.$set(this.editableShift, `${name}Mm`, value.getMinutes());
    this.updateOverflow();
  }

  private updateOverflow(): void {
    if (!this.requiresOverflowConfiguration) {
      this.$set(this.editableShift, 'overflow', ShiftOverflowEnum.Unspecified);
    }
  }

  private async onSubmit() {
    if (this.editableShift.overflow === null) {
      this.editableShift.overflow = ShiftOverflowEnum.Unspecified;
    }
    this.isSubmitting = true;
    try {
      if (this.isCreateMode) {
        await shiftsService.create(this.editableShift).then(async () => {
          if (this.tenant.id === tenantsService.store.current()?.id) {
            // When will this be false?
            await shiftsService.getByTenantId(tenantsService.store.current()!.id);
          }
        });
      } else if (this.isEditMode) {
        await shiftsService.update(this.editableShift).then(async () => {
          if (this.tenant.id === tenantsService.store.current()?.id) {
            await shiftsService.store.update(this.editableShift);
          }
        });
      }
      this.$emit('save');
      this.$emit('close');
    } finally {
      this.isSubmitting = false;
    }
  }

  private get isEnabled() {
    if (this.editableShift && this.$refs.form) {
      const refForm = this.$refs.form as HTMLFormElement;
      return (
        refForm.checkValidity() &&
        this.requiresOverflowConfiguration ===
          (this.editableShift.overflow !== null &&
            this.editableShift.overflow !== ShiftOverflowEnum.Unspecified)
      );
    }
    return false;
  }

  private get requiresOverflowConfiguration(): boolean {
    if (this.editableShift.timeToHh === 0 && this.editableShift.timeToMm === 0) {
      return false;
    }
    return (
      this.editableShift.timeFromHh > this.editableShift.timeToHh ||
      (this.editableShift.timeFromHh === this.editableShift.timeToHh &&
        this.editableShift.timeFromMm > this.editableShift.timeToMm)
    );
  }

  private get isEditMode(): boolean {
    return this.mode === Mode.Edit;
  }

  private get isCreateMode(): boolean {
    return this.mode === Mode.Create;
  }

  private get timeFromDate(): Date {
    const date = new Date();
    date.setHours(this.editableShift.timeFromHh);
    date.setMinutes(this.editableShift.timeFromMm);
    return date;
  }

  private get timeToDate(): Date {
    const date = new Date();
    date.setHours(this.editableShift.timeToHh);
    date.setMinutes(this.editableShift.timeToMm);
    return date;
  }

  private overflowTypes = Object.keys(ShiftOverflowEnum).filter(
    (item) => !isNaN(Number(item)) && Number(item) !== 0,
  );

  private get actionTitle() {
    if (this.isCreateMode) {
      return i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      return i18n.t('action.edit').toString();
    } else {
      return i18n.t('action.close').toString();
    }
  }
}
