// --------------------------------------------------------------------------------
// <copyright file="spinner.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { useRootStore } from '@/store';

export class SpinnerPluginService {
  showSpinner() {
    useRootStore().showSpinner();
  }

  removeSpinner() {
    useRootStore().removeSpinner();
  }
}
