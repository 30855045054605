// --------------------------------------------------------------------------------
// <copyright file="locale.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { localeChanged } from 'vee-validate';
import moment from 'moment/moment';
import { usePersistentStore } from '@/store/persistent';

/**
 * Handles the locale of the application.
 */
// export needed for mocking on unit tests
export class LocaleService {
  store = new (class {
    get currentLocale(): string {
      return usePersistentStore().locale;
    }

    async setCurrentLocale(locale: string) {
      usePersistentStore().setLocale(locale);
    }
  })();

  /**
   * Returns a list of the application's available locales.
   *
   * The locales are represented as two-letter language codes (ISO 639-1).
   */
  get availableLocales(): string[] {
    return i18n.availableLocales;
  }

  /**
   * Returns the two-letter language code (ISO 639-1) of the user's currently
   * selected language.
   *
   * For example, 'en' for English.
   */
  get currentLocale(): string {
    return i18n.locale;
  }

  /**
   * Sets the locale to be used by the application.
   *
   * @param locale two-letter language code (ISO 639-1). For example, 'en' for
   *   English.
   */
  async setCurrentLocale(locale: string) {
    i18n.locale = locale;
    moment.locale(locale);
    await this.store.setCurrentLocale(locale);
    localeChanged();
  }

  /**
   * Returns the two-letter language code (ISO 639-1) of the user's currently
   * selected language on the browser.
   *
   * For example, 'en' for English.
   */
  get browserCurrentLocale(): string {
    // Returns the 'en' in 'en-US'
    return window.navigator.language.split('-')[0];
  }
}

export const localeService = new LocaleService();
