// --------------------------------------------------------------------------------
// <copyright file="configuration.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { SpaConfiguration } from '@/models/spaConfiguration';

class ConfigurationService {
  private static readonly SPA_CONFIG_KEY = 'spa.config';

  async loadConfig() {
    await axios.get<SpaConfiguration>(`${BaseUrl}/spa-configuration`).then((response: any) => {
      sessionStorage.setItem(ConfigurationService.SPA_CONFIG_KEY, JSON.stringify(response.data));
    });
  }

  getConfig(): SpaConfiguration {
    const localSpaConfig = sessionStorage.getItem(ConfigurationService.SPA_CONFIG_KEY) ?? '{}';
    return JSON.parse(localSpaConfig);
  }
}

export const configurationService = new ConfigurationService();
