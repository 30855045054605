// --------------------------------------------------------------------------------
// <copyright file="quotesCustomerEvolutionGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import i18n from '@/i18n';
import { abbreviateNumber } from '@/utils/number';
import { QuotesCustomerEvolutionData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockQuotesCustomerEvolutionData } from './mockWidgetSelectorData';
import { isCategoryXAxis } from '@/utils/charts';
import { XAXisOption } from 'echarts/types/dist/shared';
import { metricsService } from '@/services/metrics.service';

export const NON_CONVERTED_COLOR = '#5470c6';
export const CONVERTED_COLOR = '#91cc75';

export default class QuotesCustomerEvolutionGenerator extends ChartGenerator<
  QuotesCustomerEvolutionData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getSMBSMetricsBC<QuotesCustomerEvolutionData[]>(
      this.procedure,
      this.tenantIdDh,
      { startDate, endDate, axisTimespan: timeAxisSpan, customerNo },
      this.controller,
    );
  }

  override updateOptions(
    data: QuotesCustomerEvolutionData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const dates = data.map((d) => d.date);
    const uniqueDates = dates
      .filter((date, index) => dates.indexOf(date) === index)
      .sort((a, b) => a.localeCompare(b));

    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    const customers = data.map((d) => d.bill_to_customer_no_);
    const uniqueCustomers = customers.filter((c, index) => customers.indexOf(c) === index);

    return {
      tooltip: {
        show: true,
        confine: true,
        formatter: (params: any) => {
          const [dataType, customer] = params.seriesId.split('-');
          const isConverted = dataType === 'converted';
          const date = isCategoryAxis ? params.name : params.value[0];
          const dataPoint = data.find(
            (point) => point.bill_to_customer_no_ === customer && point.date === date,
          )!;
          return `
          <div>
            <p style="text-transform: capitalize">${date}</p>
            <div style="display: flex; flex-direction: row">
              <div style="flex-grow: 1; padding-right: 3em">
                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${NON_CONVERTED_COLOR};"></span>
                <span>
                  ${!isConverted ? '<b>' : ''}${i18n.t(
            'report.business_console.non_converted_amount',
          )}${!isConverted ? '</b>' : ''}:
                </span>
              </div>
              <div>
                ${i18n.n(dataPoint.amount - dataPoint.converted_amount)}
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="flex-grow: 1; padding-right: 3em">
                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${CONVERTED_COLOR};"></span>
                <span>
                  ${isConverted ? '<b>' : ''}
                    ${i18n.t('report.business_console.converted_amount')}
                  ${isConverted ? '</b>' : ''}:
                </span>
              </div>
              <div>
                ${i18n.n(dataPoint.converted_amount)}
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="flex-grow: 1; padding-right: 3em">
                <span>${i18n.t('report.business_console.total_amount')}:</span>
              </div>
              <div>
                ${i18n.n(dataPoint.amount)}
              </div>
            </div>
          </div>
          `;
        },
      },

      legend: {
        bottom: 0,
        type: 'scroll',
      },

      grid: {
        containLabel: true,
        left: '3%',
        right: '4%',
        bottom: 40,
        top: 32,
      },

      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        data: isCategoryAxis ? uniqueDates : undefined,
        axisLabel: {
          hideOverlap: true,
        },
      } as XAXisOption, // type=category doesn't have data property

      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },

      dataZoom: [
        {
          show: false,
          realtime: true,
          height: '2',
          top: '95%',
        },
        {
          show: true,
          type: 'inside',
          realtime: true,
        },
      ],

      series: uniqueCustomers.flatMap((customer) => {
        const customerDataItems = data.filter(
          (dataItem) => dataItem.bill_to_customer_no_ === customer,
        );
        const mappedObjects = uniqueDates
          .map((date) => customerDataItems.find((dataItem) => dataItem.date === date)!)
          .filter((dataItem) => !!dataItem);
        return [
          {
            id: `converted-${customer}`,
            type: 'bar' as const,
            name: `${i18n.t('report.business_console.converted')}`,
            data: isCategoryAxis
              ? mappedObjects.map((dataItem) => dataItem?.converted_amount)
              : mappedObjects.map((dataItem) => [dataItem.date, dataItem?.converted_amount]),
            connectNulls: true,
            stack: customer,
            itemStyle: {
              color: CONVERTED_COLOR,
            },
          },
          {
            id: `nonconverted-${customer}`,
            type: 'bar' as const,
            name: `${i18n.t('report.business_console.non_converted')}`,
            data: isCategoryAxis
              ? mappedObjects.map((dataItem) =>
                  dataItem ? dataItem.amount - dataItem.converted_amount : dataItem,
                )
              : mappedObjects.map((dataItem) =>
                  dataItem
                    ? [dataItem.date, dataItem.amount - dataItem.converted_amount]
                    : dataItem,
                ),
            connectNulls: true,
            stack: customer,
            itemStyle: {
              color: NON_CONVERTED_COLOR,
            },
          },
        ];
      }),
    };
  }

  override getMockData(): QuotesCustomerEvolutionData[] | null {
    return mockQuotesCustomerEvolutionData();
  }
}
