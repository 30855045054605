// --------------------------------------------------------------------------------
// <copyright file="cuttingTimePerGasGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
import { Tenant } from '../tenant';
import { CuttingTimePerGasData } from './chartsData';
import { EChartsOption, BarSeriesOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { DetailedViewEnum } from '../enums/WidgetEnum';
import { metricsService } from '@/services/metrics.service';
import { tenantsService } from '@/services/tenants.service';

export class CuttingTimePerGasGenerator extends ChartGenerator<CuttingTimePerGasData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    return metricsService.getDevicesMetrics<CuttingTimePerGasData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: CuttingTimePerGasData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const seriesName = (key: string) => {
      if (data.every((x) => x.gas_type === null)) {
        return i18n.t('report.cutting_time').toString();
      } else if (this.procedure === DetailedViewEnum.LaserTodaysGas) {
        return Tenant.gasName(this.tenant, key);
      } else if (this.procedure === DetailedViewEnum.TubesCuttingTimePerGas && key !== null) {
        return key;
      }
      return i18n.t('report.unknown').toString();
    };

    const barSeries: BarSeriesOption[] = data
      .filter((d) => d.cutting_time !== 0)
      .map((d) => ({
        type: 'bar',
        name: seriesName(d.gas_type),
        data: [d.cutting_time],
        emphasis: {
          focus: 'series',
        },
      }));

    return {
      legend: {
        bottom: 0,
        selectedMode: barSeries.length !== 1,
      },
      title: {
        text: parameters.title,
        textStyle: {
          fontWeight: 'normal',
          fontFamily: 'Inter',
        },
      },
      tooltip: {
        trigger: 'axis',
        showContent: false,
        confine: true,
      },
      xAxis: {
        type: 'category',
        data: [i18n.t('today').toString()],
        axisPointer: {
          type: 'none',
        },
      },
      yAxis: {
        gridIndex: 0,
        name: parameters.yAxisName ?? '',
      },
      grid: { right: '50%' },
      series: [
        ...barSeries,
        {
          type: 'pie',
          id: 'pie',
          radius: '50%',
          center: ['75%', '50%'],
          emphasis: {
            focus: 'self',
          },
          label: {
            formatter: '{b}: {@value}h\n{d}%',
          },
          data: data
            .filter((d) => d.cutting_time !== 0)
            .map((d) => ({
              name: seriesName(d.gas_type),
              value: d.cutting_time,
            })),
        },
      ],
    };
  }

  private get tenant(): Tenant {
    return tenantsService.store.current()!;
  }
}
