
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { isEmpty, isNil } from '@/utils/misc';
import { Shift } from '@/models/shift';
import { Customer } from '@/models/tenant';
import TimeZoneIndicator from '@/components/TimeZoneIndicator.vue';

/**
 * Shows information about selected element in page filters allowing multiple
 * selection and other useful context information.
 *
 * Props:
 * - selectedEquipment: List of selected devices. When the list is empty, it's
 *   interpreted as if all the devices where selected (if not filtering, show
 *   all).
 * - customer: Current customer. When set the customer name is shown.
 * - timeZone: IANA time zone name. Shows the time zone name and its current
 *   time when set.
 * - selectedShifts: List of selected shifts. When the list is empty, it's
 *   interpreted as if no shift were selected. This differs from
 *   `selectedEquipment` because in this case, selecting all shifts doesn't
 *   include the case in which a device isn't assigned to any turn.
 * - showShifts: Enables showing selected shifts when set to `true`.
 */
@Component({
  components: { TimeZoneIndicator },
  methods: { isNil, isEmpty },
})
export default class MultipleSelectionFiltersHeader extends Vue {
  @Prop({ required: true })
  private selectedEquipment!: Device[];

  @Prop()
  private customer?: Customer | null;

  @Prop()
  private timeZone?: string | null;

  @Prop()
  private selectedShifts?: Shift[];

  @Prop({ default: true, type: Boolean })
  private showShifts!: boolean;
}
