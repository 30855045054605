
import { Component, Prop, Vue } from 'vue-property-decorator';
import { shiftModelsService } from '@/services/shiftModels.service';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/models/enums/Mode';
import { ShiftModel } from '@/models/shiftModel';
import ShiftFormModal from '@/components/shiftModels/ShiftFormModal.vue';
import ShiftModelFormModal from '@/components/shiftModels/ShiftModelFormModal.vue';
import { Shift } from '@/models/shift';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { shiftsService } from '@/services/shifts.service';
import { modalService } from '@/library-services/modal.service';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class ShiftModels extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private tenant!: Tenant;

  private shiftModels: ShiftModel[] = [];
  private isLoading = false;

  private async created() {
    await this.fetchShiftModels();
  }

  private async fetchShiftModels() {
    this.isLoading = true;
    this.shiftModels = await shiftModelsService
      .get(this.tenant.id)
      .finally(() => (this.isLoading = false));
  }

  private openShiftModelForm(shiftModel?: ShiftModel) {
    modalService.open({
      parent: this,
      component: ShiftModelFormModal,
      props: {
        tenant: this.tenant,
        shiftModel: shiftModel ?? ShiftModel.GetEmpty(),
        mode: shiftModel ? Mode.Edit : Mode.Create,
      },
      events: {
        save: async () => await this.fetchShiftModels(),
      },
    });
  }

  private async openShiftCreationForm(shiftModel: ShiftModel) {
    this.openShiftForm({
      tenant: this.tenant,
      shiftModel,
      shift: Shift.GetEmpty(),
      mode: Mode.Create,
    });
  }

  private async openShiftEditionForm(shift: Shift) {
    this.openShiftForm({
      tenant: this.tenant,
      shiftModel: this.shiftModels.find((sm) => sm.id === shift.shiftModelId)!,
      shift,
      mode: Mode.Edit,
    });
  }

  private openShiftForm(props: any) {
    modalService.open({
      component: ShiftFormModal,
      parent: this,
      props,
      events: {
        save: async () => await this.fetchShiftModels(),
      },
    });
  }

  private confirmDeleteShift(shiftId: number, name: string) {
    this.openDeleteEntityConfirmationDialog(
      name,
      this.$i18n.t('shift_model.shift.name').toString().toLowerCase(),
      async () => await this.deleteShift(shiftId),
    );
  }

  private confirmDeleteShiftModel(shiftModelId: number, name: string) {
    this.openDeleteEntityConfirmationDialog(
      name,
      this.$i18n.t('shift_model.name').toString().toLowerCase(),
      async () => await this.deleteShiftModel(shiftModelId),
    );
  }

  private openDeleteEntityConfirmationDialog(
    name: string,
    entity: string,
    onConfirm: () => Promise<void>,
  ) {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity,
          name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm,
    });
  }
  private async deleteShift(id: number) {
    await shiftsService.delete(id).then(async () => await this.fetchShiftModels());
  }

  private async deleteShiftModel(id: number) {
    await shiftModelsService.delete(id).then(async () => await this.fetchShiftModels());
  }
}
