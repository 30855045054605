
import GenericParam from './GenericParam.vue';
import { Component } from 'vue-property-decorator';
import { ParamNumber } from '@/models/enums/WidgetEnum';
import i18n from '@/i18n';

@Component
export default class NumberParam extends GenericParam<ParamNumber> {
  private lastValue?: number;

  private get value(): number {
    return this.paramValue ?? this.paramInfo.defaultValue ?? 0;
  }
  private sendUpdate(value: number) {
    if (this.lastValue !== value && this.isValid) {
      this.$emit('update', { [this.paramName]: value });
      this.lastValue = value;
    }
  }

  private get isValid(): boolean {
    return !this.isGreaterThanMax && !this.isLessThanMin;
  }

  private get isGreaterThanMax() {
    return this.paramInfo.max && this.value! > this.paramInfo.max;
  }

  private get isLessThanMin() {
    return this.paramInfo.min && this.value! < this.paramInfo.min;
  }

  private get validationMessage(): string | undefined {
    if (this.isValid) {
      return undefined;
    } else if (this.isGreaterThanMax) {
      return i18n.t('validation_message.max_value', { max_value: this.paramInfo.max }).toString();
    } else if (this.isLessThanMin) {
      return i18n.t('validation_message.min_value', { min_value: this.paramInfo.min }).toString();
    } else {
      return undefined;
    }
  }
}
