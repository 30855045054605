// --------------------------------------------------------------------------------
// <copyright file="shifts.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Shift } from '@/models/shift';
import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import { usePersistentStore } from '@/store/persistent';

class ShiftsService {
  store = new (class {
    get(): Shift[] {
      return usePersistentStore().shifts;
    }

    getById(id: number): Shift | undefined {
      return this.get().find((shift) => shift.id === id);
    }

    getNameById(shiftId: number) {
      const shift = this.getById(shiftId);
      return shift ? shift.name : '';
    }

    getMatchingIds(shifts: number[]): Shift[] {
      return this.get().filter((shift) => shifts.includes(shift.id));
    }

    getAllByDeviceIds(deviceIds: string[]) {
      const allDevices = devicesService.store.get();

      return this.get().filter((shift) =>
        shift.relatedDevices.some((deviceId) =>
          deviceIds.includes(Device.GetHubDeviceIdFromEntityDeviceId(deviceId, allDevices)!),
        ),
      );
    }

    getAllByShiftModelId(shiftModelId: number): Shift[] {
      return this.get().filter((shift) => shift.shiftModelId === shiftModelId);
    }

    async update(shift: Shift) {
      const index = this.get().findIndex((shiftItem) => shiftItem.id === shift.id);

      await this.setAll([...this.get().slice(0, index), shift, ...this.get().slice(index + 1)]);
    }

    async add(shift: Shift) {
      usePersistentStore().addShift(shift);
    }

    async setAll(shifts: Shift[]) {
      usePersistentStore().setShifts(shifts);
    }

    async delete(id: number) {
      usePersistentStore().deleteShift(id);
    }

    exists(shiftId: number): boolean {
      return this.get().some((shift) => shift.id === shiftId);
    }

    async clear() {
      usePersistentStore().setShifts([]);
    }
  })();

  async getByTenantId(tenantId: number | null): Promise<Shift[]> {
    const response = await axios.get<Shift[]>(`${BaseUrl}/shifts?tenantId=${tenantId}`);
    await this.store.setAll(response.data);
    return response.data;
  }

  async create(shift: Shift): Promise<void> {
    await axios.post(`${BaseUrl}/shifts/`, shift);

    await this.store.add(shift);
  }

  update(shift: Shift): Promise<void> {
    return axios.put(`${BaseUrl}/shifts`, shift);
  }

  async delete(id: number): Promise<void> {
    await axios.delete(`${BaseUrl}/shifts/${id}`);

    if (this.store.exists(id)) {
      await this.store.delete(id);
    }
  }
}

export const shiftsService = new ShiftsService();
