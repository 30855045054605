
import { Component, Prop, Vue } from 'vue-property-decorator';
import { dateRangeToDateStrings } from '@/utils/dates';
import AlertOccurrencesTable from '@/components/careConsole/AlertOccurrencesTable.vue';
import WidgetHelp from '@/components/Charts/WidgetHelp.vue';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { defaultDateRange } from '@/views/careConsole/commons/utils';
import WidgetContainer from '../Charts/widgetContainer/WidgetContainer.vue';

@Component({
  computed: {
    WidgetEnum: () => WidgetEnum,
  },
  components: {
    AlertOccurrencesTable,
    WidgetContainer,
    LayoutGrid,
    WidgetHelp,
  },
})
export default class Alerts extends Vue {
  @Prop({ required: true, default: () => defaultDateRange() })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private deviceId!: string;

  private gridLayout = availableDashboardLayouts[DashboardLayout.OneByOne];

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }

  private get widgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareAlertOccurrencesTable,
      this.deviceId!,
      this.dateRangeString,
      [],
    ).hideTitle();
  }
}
