// --------------------------------------------------------------------------------
// <copyright file="masters.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Measurement } from '@/models/measurement';
import { CurrencyCode } from '@/models/currencyCode';
import { TimeZone } from '@/models/timeZone';
import { usePersistentStore } from '@/store/persistent';

class MastersService {
  store = new (class {
    getCurrencyCodes(): CurrencyCode[] {
      return usePersistentStore().currencyCodes;
    }

    updateCurrencyCodes(currencyCodes: CurrencyCode[]) {
      usePersistentStore().setCurrencyCodes(currencyCodes);
    }

    getMeasurementUnits(): Measurement[] {
      return usePersistentStore().measurements;
    }

    updateMeasurementUnits(measurementUnits: Measurement[]) {
      usePersistentStore().setMeasurements(measurementUnits);
    }

    getTimezones(): TimeZone[] {
      return usePersistentStore().timezones;
    }

    updateTimezones(timezones: TimeZone[]) {
      usePersistentStore().setTimezones(timezones);
    }

    getEventModules(): string[] {
      return usePersistentStore().eventModules;
    }

    updateEventModules(eventModules: string[]) {
      usePersistentStore().setEventModules(eventModules);
    }

    getEventCodes(): string[] {
      return usePersistentStore().eventCodes;
    }

    updateEventCodes(eventCodes: string[]) {
      usePersistentStore().setEventCodes(eventCodes);
    }
  })();

  async getCurrencyCodes(): Promise<CurrencyCode[]> {
    const currencyCodes = await axios
      .get<CurrencyCode[]>(`${BaseUrl}/masters/currency-codes`)
      .then((response) => response.data);
    this.store.updateCurrencyCodes(currencyCodes);
    return currencyCodes;
  }

  async getMeasurementUnits(): Promise<Measurement[]> {
    const measurementUnits = await axios
      .get<Measurement[]>(`${BaseUrl}/masters/measurement-units`)
      .then((response) => response.data);
    this.store.updateMeasurementUnits(measurementUnits);
    return measurementUnits;
  }

  async getTimezones(): Promise<TimeZone[]> {
    const timezones = await axios
      .get<TimeZone[]>(`${BaseUrl}/masters/timezones`)
      .then((response) => response.data);
    this.store.updateTimezones(timezones);
    return timezones;
  }

  async getEventSources(): Promise<string[]> {
    return axios
      .get<string[]>(`${BaseUrl}/masters/event-sources`)
      .then((response) => response.data);
  }

  async getEventModules(): Promise<string[]> {
    const eventModules = await axios
      .get<string[]>(`${BaseUrl}/masters/event-modules`)
      .then((response) => response.data.slice().sort((a, b) => a.localeCompare(b)));
    this.store.updateEventModules(eventModules);
    return eventModules;
  }

  async getEventCodes(): Promise<string[]> {
    const eventCodes = await axios
      .get<string[]>(`${BaseUrl}/masters/event-codes`)
      .then((response) => response.data);
    this.store.updateEventCodes(eventCodes);
    return eventCodes;
  }
}

export const mastersService = new MastersService();
