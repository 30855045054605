
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Customer } from '@/models/tenant';
import { isEmpty, uniqueId } from '@/utils/misc';
import i18n from '@/i18n';
import { customersService } from '@/services/tenants.service';

@Component({})
export default class CustomersTagInput extends Vue {
  @Prop({ default: false, type: Boolean })
  private required!: boolean;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop()
  private value: string[] | null = null;

  @Prop({ default: true, type: Boolean })
  private showLabel!: boolean;

  private componentId = uniqueId();

  private availableCustomers: Customer[] = [];
  private customerNames: string[] = [];
  private nonSelectedCustomers: string[] = [];

  private areCustomersLoading = false;

  private async mounted() {
    this.customerNames = this.value ?? [];

    await this.loadData();
  }

  private async loadData() {
    this.areCustomersLoading = true;
    this.availableCustomers = await customersService
      .get()
      .finally(() => (this.areCustomersLoading = false));
    this.nonSelectedCustomers = this.availableCustomers
      .filter((option) => this.customerNames.indexOf(option.name) === -1)
      .map((c) => c.name);
  }

  @Watch('value')
  initializeTag() {
    this.customerNames = this.value ?? [];
  }

  private selectAll() {
    this.customerNames = [...this.availableCustomers.map((c) => c.name)];
    this.onSelectionChanged(this.customerNames);
  }

  private clearAll() {
    this.customerNames = [];
    this.onSelectionChanged(this.customerNames);
  }

  private updateNonSelectedCustomers(text: string) {
    this.nonSelectedCustomers = this.availableCustomers
      .filter((option) => {
        return (
          option.name.toLowerCase().includes(text.toLowerCase()) &&
          !this.customerNames.includes(option.name)
        );
      })
      .map((c) => c.name);
  }

  private onSelectionChanged(customers: string[]) {
    this.$emit(
      'input',
      customers.map((customerName) => ({
        id: this.availableCustomers.find((c) => c.name === customerName)?.id ?? undefined,
        name: customerName,
      })),
    );
  }

  private get label(): string | undefined {
    if (!this.showLabel) {
      return undefined;
    }

    return i18n.t('tenant.plural').toString();
  }

  private get areTagsEmpty() {
    return isEmpty(this.customerNames);
  }

  private get isLoading(): boolean {
    return this.areCustomersLoading;
  }
}
