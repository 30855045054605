
import { BModalComponent } from 'buefy/types/components';
import { ComponentPublicInstance, defineComponent, PropType } from 'vue';

/**
 * This wrapper component allows us to open modals from components written in Composition API.
 * The issue is that opening a Buefy modal programmatically requires us to specify a parent
 * element, which usually is set to "this". But "this" does not exist in Composition API.
 *
 * The ModalWrapper, along with the implementation of the modalService.compositionApi.open helper
 * function, fools the Buefy modal into thinking it's been opened using the old way. It basically
 * works as a reimplementation of Buefy's BModal component, by being basically a proxy between the
 * component with the modal's content and BModal itself.
 */
export default defineComponent({
  props: {
    component: {
      type: [Function, Object] as PropType<Vue>,
      required: true,
    },
    props: {
      type: Object as PropType<any>,
    },
    events: {
      type: Object as PropType<any>,
    },
    width: {
      type: Number,
    },
    hasModalCard: {
      type: Boolean,
      default: true,
    },
    canCancel: {
      type: [Array, Boolean] as PropType<string[] | boolean>,
    },
    onCancel: {
      type: Function as PropType<() => void>,
    },
    trapFocus: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
    },
    destroyOnHide: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * Returns all event listeners except for "close", which is proxied through BModal.
     * This is required because BModal automatically handles its unmounting and the animation.
     */
    listeners(): object {
      return Object.fromEntries(
        Object.entries(this.events).filter(([eventName, _]) => eventName !== 'close'),
      );
    },
  },
  methods: {
    onClose() {
      const ref = this.$refs.modal! as ComponentPublicInstance<BModalComponent>;
      ref.close();
    },
  },
});
