// --------------------------------------------------------------------------------
// <copyright file="notifications.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { CustomNotification, UserCustomNotificationStatus } from '@/models/customNotifications';
import moment from 'moment';
import { AlertWorkflowNotification } from '@/models/AlertWorkflowNotification';
import { Notification, usePersistentStore } from '@/store/persistent';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';

class NotificationsService {
  store = new (class {
    /** Returns all notifications of all types present in the store. */
    getAll(): Notification[] {
      return usePersistentStore().notifications;
    }

    update(notifications: Notification[]) {
      usePersistentStore().setNotifications(notifications);
    }
  })();

  async get(): Promise<Notification[]> {
    const [alertNotifications, alertWorkflowNotifications, customNotifications] = await Promise.all(
      [
        this.getUserAlertStatus(),
        this.getAlertWorkflowNotifications(),
        this.getUserCustomNotificationStatus(),
      ],
    );

    const sortedNotifications = [
      ...alertNotifications,
      ...alertWorkflowNotifications,
      ...customNotifications,
    ].sort((a, b) => moment(b.timestampNotification).diff(moment(a.timestampNotification)));
    this.store.update(sortedNotifications);
    return sortedNotifications;
  }

  private async getUserAlertStatus(): Promise<UserAlertStatus[]> {
    if (!hasCurrentUserPermission(UserType.CustomerAdmin)) {
      return [];
    }
    return axios.get<UserAlertStatus[]>(`${BaseUrl}/notifications`).then((response: any) => {
      return response.data.map(
        (x: any) =>
          new UserAlertStatus(
            x.id,
            x.deviceName,
            x.metric,
            x.operator,
            x.thresholdValue,
            x.currentValue,
            x.timestampNotification,
          ),
      );
    });
  }

  private async getAlertWorkflowNotifications(): Promise<AlertWorkflowNotification[]> {
    if (!hasCurrentUserPermission(UserType.ServiceUser)) {
      return [];
    }
    return axios
      .get<AlertWorkflowNotification[]>(`${BaseUrl}/notifications/care`)
      .then((response) =>
        response.data.map(
          (notificationData: any) =>
            new AlertWorkflowNotification(
              notificationData.deviceId,
              notificationData.deviceName,
              notificationData.customerName,
              notificationData.timestampNotification,
            ),
        ),
      );
  }

  private getUserCustomNotificationStatus(): Promise<UserCustomNotificationStatus[]> {
    return axios
      .get<UserCustomNotificationStatus[]>(`${BaseUrl}/notifications/custom`)
      .then((response: any) => {
        return response.data.map(
          (x: any) => new UserCustomNotificationStatus(x.id, x.messageTxt, x.timestampNotification),
        );
      });
  }

  async createCustomNotification(customNotification: CustomNotification) {
    const response = await axios.post(`${BaseUrl}/notifications/custom`, customNotification);
    return response.data;
  }

  dismiss(notificationId: number, isCustomNotification: boolean) {
    return axios
      .delete(`${BaseUrl}/notifications/${notificationId}/${isCustomNotification}`)
      .then((response: any) => {
        return response.data;
      });
  }

  dismissAll() {
    return axios.delete(`${BaseUrl}/notifications/`).then((response: any) => {
      return response.data;
    });
  }
}

export const notificationsService = new NotificationsService();
