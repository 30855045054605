// --------------------------------------------------------------------------------
// <copyright file="cumulativeLinesGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import moment from 'moment';
import { EChartsOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { MachineType } from '../enums/MachineType';
import { metricsService } from '@/services/metrics.service';
import { devicesService } from '@/services/devices.service';

export class CumulativeLinesGenerator extends ChartGenerator<any[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(selectedDevices: string[], selectedShifts: number[]) {
    return metricsService.getDevicesMetrics<any[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: FilterTimeSpanEnum.Day,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: any[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const devices = devicesService.store.get();
    const deviceType: MachineType = parameters.deviceType ?? MachineType.Cut;
    const activeDevices = devices.filter((d) => d.getType() === deviceType).map((d) => d.deviceId);
    const filteredActiveDevices = data.filter((dev) => activeDevices.includes(dev.deviceid));
    const lines = filteredActiveDevices
      .map((x) => x.deviceid)
      .filter((el, index, list) => list.indexOf(el) === index);
    const names = lines.map((line) => devices.find((d) => d.deviceId === line)!.name);
    const series = lines.map((key, index) => ({
      type: 'line' as const,
      name: names[index],
      symbolSize: 2,
      data: filteredActiveDevices
        .filter((d) => d.deviceid === key)
        .flatMap((d) => {
          const ts = d.timestamp;
          return [[ts, d.cut_time]];
        }),
    }));
    return {
      grid: {
        top: 70,
        containLabel: true,
      },
      title: {
        text: parameters.title,
        textStyle: {
          fontWeight: 'normal',
          fontFamily: 'Inter',
        },
      },
      xAxis: {
        type: 'time',
      },
      yAxis: {
        type: 'value',
        name: parameters.yAxisName ?? '',
      },
      tooltip: {
        show: true,
        confine: true,
        formatter(params: any) {
          const dateStr = moment(params.value[0]).format('YYYY-MM-DD HH:mm:SS');
          const cumTime = i18n.n(params.value[1], { maximumFractionDigits: 2 });
          const label =
            i18n.t('report.cum_cut_time').toString()[0].toUpperCase() +
            i18n.t('report.cum_cut_time').toString().slice(1).toLowerCase();
          return !!cumTime ? `${params.marker}${dateStr}<p>${label}: ${cumTime}h </p>` : '';
        },
      },
      legend: {
        show: true,
        bottom: 0,
        type: 'scroll',
        data: names,
      },
      series,
    };
  }
}
