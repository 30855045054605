
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AlertKPI } from '@/models/enums/AlertsEnums';

@Component
export default class VariableSelectionForm extends Vue {
  @Prop({ required: true })
  private kpi!: AlertKPI;

  @Prop({ default: null })
  private value!: string | null;

  private get kpiVariableOptions(): string[] {
    return this.kpi!.variableValues!;
  }
}
