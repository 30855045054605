
import { Device } from '@/models/device';
import { DeviceStateData } from '@/models/deviceStateData';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DeviceImage from '../devices/DeviceImage.vue';
import { getStateCss } from '@/utils/color';
import moment from 'moment';
import { isEmpty, isNil } from '@/utils/misc';
import { abbreviateNumber, formatPercentage } from '@/utils/number';
import {
  CutCurrentWorkloadDataPlanInfo,
  CutDeviceCurrentWorkloadData,
  MessageType,
} from '@/models/Charts/chartsData';
import { customersService } from '@/services/tenants.service';
import { Customer } from '@/models/tenant';
import ActionBubble, { BubbleType } from '../ActionBubble.vue';
import CutCellControlActiveMessagesModal from '../cut/CutCellControlActiveMessagesModal.vue';
import { modalService } from '@/library-services/modal.service';
import { metricsService } from '@/services/metrics.service';
import { currentCustomer, currentUser } from '@/utils/permissionUtils';
import { BetaFeature } from '@/models/enums/BetaFeature';
import OverviewDeviceCardKpi from './OverviewDeviceCardKpi.vue';
import OverviewDeviceCardStateKpi from './OverviewDeviceCardStateKpi.vue';
import OverviewDeviceCardKpiList from './OverviewDeviceCardKpiList.vue';
import { RequestCanceledError } from '@/services/requestCanceledError';

@Component({
  components: {
    ActionBubble,
    DeviceImage,
    OverviewDeviceCardKpi,
    OverviewDeviceCardStateKpi,
    OverviewDeviceCardKpiList,
  },
  methods: {
    abbreviateNumber,
    formatPercentage,
    isEmpty,
    isNil,
  },
})
export default class OverviewDeviceCard extends Vue {
  @Prop({ required: true })
  private device!: Device;

  @Prop({ required: true })
  private locationName!: string;

  @Prop({ required: true })
  private now!: Date;

  @Prop()
  private deviceState?: DeviceStateData;

  @Prop({ default: false })
  private loading!: boolean;

  @Prop({ default: true })
  private isFirstLoad!: boolean;

  @Prop({ default: false })
  private compactMode!: boolean;

  private workloadUpdateHandle = -1;
  private workloadData: CutDeviceCurrentWorkloadData | null = null;
  private workloadLoading = false;
  private isFirstWorkloadLoad = true;

  private controller = new AbortController();

  private COMPLETED_PROGRESS_VALUE = 1;

  private async mounted() {
    if (this.hasCurrentWorkloadBetaFeature && this.customerHasDataPlatform && this.device.isCut()) {
      await this.getWorkloadData();
      this.isFirstWorkloadLoad = false;

      if (this.workloadUpdateHandle === -1) {
        this.workloadUpdateHandle = window.setInterval(this.getWorkloadData, 30 * 1000);
      }
    }
  }

  private async getWorkloadData() {
    try {
      this.controller.abort();
      this.controller = new AbortController();
      this.workloadLoading = true;
      this.workloadData = await metricsService.getDeviceCurrentWorkload(
        this.device.deviceId,
        this.customer!.tenantIdDh,
        this.controller,
      );
    } catch (e) {
      // Ignore RequestCanceledError exceptions
      if (!(e instanceof RequestCanceledError)) {
        throw e;
      }
    } finally {
      this.workloadLoading = false;
    }
  }

  private get customer(): Customer | null {
    return customersService.store.current();
  }

  private get hasState(): boolean {
    return !isNil(this.deviceState?.last_state);
  }

  private get hasCuttingPlans(): boolean {
    return this.device.isCut() && !isEmpty(this.workloadData?.cuttingPlanInfo);
  }

  private get currentPlan(): CutCurrentWorkloadDataPlanInfo {
    return (
      this.workloadData?.cuttingPlanInfo.find(
        (plan) => plan.progress < this.COMPLETED_PROGRESS_VALUE,
      ) ??
      this.workloadData?.cuttingPlanInfo[0] ??
      CUTTING_PLAN_INFO_NULL_OBJECT
    );
  }

  private get navigationButtonIcon(): string {
    if (this.device.isCut()) {
      return 'cutting-color';
    }
    if (this.device.isBend()) {
      return 'bending-color';
    }
    if (this.device.isTube()) {
      return 'tube-color';
    }
    return '';
  }

  private get bubbleLabel(): string | undefined {
    if (isEmpty(this.workloadData?.currentMessages)) {
      return undefined;
    }
    return this.workloadData?.currentMessages.length.toString();
  }

  private get bubbleType(): BubbleType | undefined {
    if (isEmpty(this.workloadData?.currentMessages)) {
      return undefined;
    }
    if (this.workloadData?.currentMessages.some((x) => x.messageLevel === MessageType.Error)) {
      return 'is-danger';
    }
    if (this.workloadData?.currentMessages.some((x) => x.messageLevel === MessageType.Warning)) {
      return 'is-warning';
    }
    return 'is-info';
  }

  private stateStyle() {
    return getStateCss(this.deviceState!.last_state);
  }

  private getDeviceMessage(): string {
    const lastSeenUtc = this.deviceState!.last_ts_utc;
    const lastSeen = moment(`${lastSeenUtc}+00:00`);
    const now = moment(this.now);
    if (now.diff(lastSeen, 'hours') > 0) {
      return this.$tc('time_msg.hours', moment().diff(lastSeen, 'hours')).toString();
    } else if (now.diff(lastSeen, 'minutes') > 0) {
      return this.$tc('time_msg.minutes', moment().diff(lastSeen, 'minutes')).toString();
    } else {
      return this.$tc('time_msg.seconds', moment().diff(lastSeen, 'seconds')).toString();
    }
  }

  private openMessageModal() {
    modalService.open({
      parent: this,
      component: CutCellControlActiveMessagesModal,
      props: {
        messages: this.workloadData?.currentMessages,
      },
    });
  }

  private get hasCurrentWorkloadBetaFeature() {
    return currentUser().hasBetaFeature(BetaFeature.WorkCenterConsoleCutCurrentWorkload);
  }

  private get showWorkload() {
    return (
      this.hasCurrentWorkloadBetaFeature &&
      this.customerHasDataPlatform &&
      (this.hasCuttingPlans || this.workloadLoading)
    );
  }

  private get showMessagesIcon() {
    return (
      this.hasCurrentWorkloadBetaFeature && this.customerHasDataPlatform && this.device.isCut()
    );
  }

  private get customerHasDataPlatform(): boolean {
    return currentCustomer()?.hasDataPlatform ?? false;
  }

  private beforeDestroy() {
    window.clearInterval(this.workloadUpdateHandle);
    this.controller.abort();
  }
}

const CUTTING_PLAN_INFO_NULL_OBJECT: CutCurrentWorkloadDataPlanInfo = {
  name: '',
  totalParts: 1,
  completedParts: 0,
  totalRuns: 1,
  completedRuns: 0,
  progress: 0,
} as CutCurrentWorkloadDataPlanInfo;
