// --------------------------------------------------------------------------------
// <copyright file="chartsData.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { MachineState } from '@/models/deviceStateData';

export enum ImportantMessageLevel {
  Warning = 'WARNING',
  Error = 'ERROR',
}

export enum MessageType {
  // Must be ordered by severity (see
  // CutCellControlActiveMessagesModal.messageTypeSorter)
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
}

export interface EventTypeByModuleData {
  type: MessageType;
  module: string;
  count: number;
}

export interface EventTypeByTimeData {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  date: string;
  type: ImportantMessageLevel;
  count: number;
  byModule: ByModuleEventsData[];
}

export interface ByModuleEventsData {
  name: string;
  count: number;
}

export interface EventData {
  timestamp: string; // ISO date
  messageLevel: MessageType;
  messageSource: string;
  messageModule: string;
  description: string;
  errorCode: string;
  cause: string;
  solution: string;

  // These are set when using some date grouping
  occurrences?: number;
  firstEventTimestamp?: string;
  lastEventTimestamp?: string;
}

export interface SalesTimelineData {
  date: string;
  sold_amount: number;
}

export interface QuoteConversionTimelineData {
  date: string;
  amount: number;
  converted_amount: number;
}

export interface CutStatesIntradayData {
  timestamp: string;
  deviceid: string;
  wait_time: number;
  error_time: number;
  idle_time: number;
  stop_time: number;
  offline_time: number;
  work_time: number;
  last: boolean;
}

export interface CuttingHoursTimelineData {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;
  cuttingHours: number;
}

export interface CuttingTimePerGasData {
  cutting_time: number; // hours
  gas_type: string;
}

export interface DrawerCycleTimesData {
  lower_drawer_cutting_hours: number; // hours
  lower_drawer_cutting_hour_avg: number; // hours
  lower_drawer_removed_seconds: number; // seconds
  lower_drawer_removed_second_avg: number; // seconds

  upper_drawer_cutting_hours: number; // hours
  upper_drawer_cutting_hour_avg: number; // hours
  upper_drawer_removed_seconds: number; // seconds
  upper_drawer_removed_second_avg: number; // seconds

  lower_drawer_inserted_cycles: number;
  upper_drawer_inserted_cycles: number;

  lower_drawer_removed_cycles: number;
  upper_drawer_removed_cycles: number;
}

export interface DrawerInformationTimelineData {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;

  powerAmplitude: number | null; // Watts

  lowerDrawerPD: number | null;
  upperDrawerPD: number | null;

  lowerDrawerRemovedSeconds: number | null;
  upperDrawerRemovedSeconds: number | null;

  lowerDrawerRemovedTimes: number | null;
  upperDrawerRemovedTimes: number | null;
}

export interface CuttingHeadVersionInformation {
  // The following fields are only present when there's been a head version
  // change. When present, some of them might not have a value.
  cuttingHeadType?: string | null;
  // Will be non-null if there's been a version change
  cuttingHeadSoftwareVersion?: string;
  cuttingHeadSerialNumber?: string | null;
  cuttingHeadBaseProductNumber?: string | null;

  cuttingHeadVersionChangesCount?: number | null;
  cuttingHeadTypeChangesCount?: number | null;
  equipmentNumberChangesCount?: number | null;
}

export interface DriveAccumulatedCurrentDifferenceData extends CuttingHeadVersionInformation {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;
  // Can be null for cutting head version information entries
  accumulated_diff: number | null; // mA
}

export interface DriveCurrentDifferenceHistogramData extends CuttingHeadVersionInformation {
  k_diffs: number;
  counts: number;
}

export interface DriveCurrentTimelineData extends CuttingHeadVersionInformation {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;
  TempCapacitive: number; // ºC
  DriveCurrent: number; // mA
  diff_DriveCurrent: number; // mA
}

export interface FactoryOverviewData {
  deviceid: string;
  availability: number;
  productivity: number;
  timestamp_utc: string;
  state: string;
}

export interface FinishedPOData {
  date: string;
  estimated_time_hours: number;
  executed_time_hours: number;
  capacity: number;
}

const LASER_GANTT_STATES = ['work', 'error', 'wait', 'idle', 'stop', 'offline'];
const BEND_PRODUCTION_MODES = ['automatic', 'semi-automatic', 'non-productive'];
enum BendSpeedFlowStates {
  HighFlow = 'high-flow',
  LowFlow = 'low-flow',
  NoFlow = 'no-flow',
  Processing = 'processing',
}
const BEND_FLOW_SPEED = [
  BendSpeedFlowStates.HighFlow,
  BendSpeedFlowStates.LowFlow,
  BendSpeedFlowStates.NoFlow,
  BendSpeedFlowStates.Processing,
];

export { LASER_GANTT_STATES, BEND_PRODUCTION_MODES, BEND_FLOW_SPEED, BendSpeedFlowStates };

export type LaserGanttState = (typeof LASER_GANTT_STATES)[number];
export type BendState = (typeof BEND_PRODUCTION_MODES)[number];
export type FlowSpeedGanttState = (typeof BEND_FLOW_SPEED)[number];

// Used by LaserStatesGantt and IntradayOeeTime
export interface LaserGanttData {
  deviceid: string;
  duration: number;
  start_timestamp: number;
  end_timestamp: number;
  state: LaserGanttState;

  // Additional fields sent by `get_laser_states_gantt` SP
  /*
  shift_number: string;
  shift_name: string;
  */

  // Additional fields sent by `get_laser_oee_intraday` SP
  /*
  oee_factor: number;
  */
}

export interface GanttTableData<TState> {
  deviceid: string;
  startTimestamp: number;
  endTimestamp: number;
  state: TState;
}

export interface GroupedGanttTableData<TData> {
  deviceid: string;
  ganttData: Array<TData>;
}

export interface DataWithTimeSpan {
  isprimary: number; // No reason for this to not be written `isPrimary`.
}

export interface BendGroupedGanttTableData {
  deviceid: string;
  productionModeData: Array<ProductionModeGanttData>;
  sheetPartsData: Array<SheetPartGanttData>;
  flowSpeedData: Array<FlowSpeedGanttData>;
}

export interface BendIntradayTableData {
  productionModeData: ProductionModeGanttData[];
  sheetPartsData: SheetPartGanttData[];
  flowSpeedData: FlowSpeedGanttData[];
}

export interface LaserGanttTableData extends GanttTableData<LaserGanttState> {}
export interface ProductionModeGanttData extends GanttTableData<BendState> {}
export interface SheetPartGanttData extends GanttTableData<string> {}
export interface FlowSpeedGanttData extends GanttTableData<FlowSpeedGanttState> {
  flowSpeed: number; // Number value that represents the speed of work of bending machine
  // Note that the state value is calculated from Backend and it'll change depending on the history of each machine.
}

export interface LaserGroupedGanttTableData extends GroupedGanttTableData<LaserGanttTableData> {}

export interface LaserOEEData extends DataWithTimeSpan {
  oee: number; // Overall Equipment Efficiency
}

export interface LaserCuttingTimeData extends DataWithTimeSpan {
  cutting_time: number; // hours
}

export interface BendingTopPartsData extends DataWithTimeSpan {
  PartName: string;
  PartCount: number;
}

export interface BendingNumberOfBendsTimelineData {
  date: string; // formats: 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY'
  bends: number;
}

export interface BendPerformanceData extends DataWithTimeSpan {
  parts_time: number;
  kg_toolset: number;
  tool_changes: number;
  part_types: number;
  bends_part: number;
  bends: number;
}

export interface DiodesAndDrawerCyclesTimelineData {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  //
  date: string;

  // Diodes chart
  lowerProtectiveGlassPD: number | null;
  topProtectiveGlassPD: number | null;
  mirrorPD: number | null;

  // Drawer cycles chart
  lowerDrawerRemovedSeconds: number | null;
  upperDrawerRemovedSeconds: number | null;

  lowerDrawerRemovedTimes: number | null;
  upperDrawerRemovedTimes: number | null;
}

export interface BendingTimeBetweenBendData extends DataWithTimeSpan {
  category_name: string;
  time_between_bend: number;
}

export interface ModuleEventsIndicatorData {
  module: string;

  totalErrors: number;
  totalWarnings: number;

  errors: number;
  warnings: number;
}

export interface HealthChartsData {
  lowerProtectiveGlass: HealthChartDataEntry | null;
  upperProtectiveGlass: HealthChartDataEntry | null;
  drawerCyclesTime: HealthChartDataEntry | null;
  lensDrive: HealthChartDataEntry | null;
  temperatures: HealthChartDataEntry | null;
}

export interface CareOverviewHealthData {
  deviceElement: string;
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  value: number;
}

export interface HealthChartDataEntry {
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  timelineData: HealthTimelineData[];
}

export interface HealthTimelineData {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  date: string;

  max: number;
  // HACK: Optional because HealthChartsData.drawerCyclesTime lacks this field
  filteredMax?: number;
  average: number;
  median: number;
  min: number;
}

export interface DeviceElementHealthData {
  deviceElement: string;

  a: number;
  b: number;
  c: number;
  d: number;
  e: number;

  max: number;
  filteredMax: number | null; // null for deviceElement === 'drawerCycleTimes'
  average: number;
  median: number;
  min: number;
}

export interface HealthScoresData {
  healthid: number;
  tenantid: number;
  deviceid: string;
  date: string;

  Lower_Protection_Glass_Health: number; // unit: photodiode
  Lower_Protection_Glass_Health_Score: number; // unit: photodiode

  Upper_Protection_Glass_Health: number; // unit: photodiode
  Upper_Protection_Glass_Health_Score: number; // unit: photodiode

  Drive_Current_Health: number; // mA
  Drive_Current_Health_Score: number; // mA

  Drawer_Cycles_Health: number; // seconds
  Drawer_Cycles_Health_Score: number; // seconds
}

export interface HealthRangesData {
  tenantid: number;
  deviceid: string;
  date: string;
  Indicator_name: string;
  min: number;
  A: number;
  B: number;
  C: number;
  D: number;
  E: number;
}

export interface HealthData {
  scores: HealthScoresData;
  ranges: HealthRangesData[];
}

export interface LaserAvailabilityData extends DataWithTimeSpan {
  // hours
  work: number;
  error: number;
  wait: number;
  idle: number;
  stop: number;
  offline: number;
}

export interface LaserStarveAndBlockData extends DataWithTimeSpan {
  starve: number; // minutes
  block: number; // minutes
}

export interface BendingAvailabilityData extends DataWithTimeSpan {
  // hours
  bend: number;
  program: number;
  setup: number;
  warning: number;
  error: number;
}

export interface LaserUsedVsNotUsedTimesData {
  cuttingHours: number; // hours
  totalHours: number; // hours
}

export interface LaserAvailableVsUsedTimelineData {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * It has this format: YYYY-MM-DDTHH:mm:ss.sss (ISO UTC without
   * Z [local time zone]).
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;
  availablePowerKwh: number;
  usedPowerKwh: number;
}

export interface LaserConsumptionTimelineData {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;
  totalConsumptionKwh: number; // kWh

  /** Power level in kW. */
  kPower: number;

  /** Accumulated power in KWh for K_power power level. */
  kPowerConsumptionKwh: number;
}

export interface LaserPowerUtilizationTimesData {
  /** Power level in kW. */
  kPower: number;
  hours: number;
}

export interface LaserProductivityHistoricalData {
  date: string; // YYYY-MM
  productivity: number; // percentage
}

export interface LaserAvailabilityHistoricalData {
  date: string;

  // hours
  work: number;
  error: number;
  wait: number;
  idle: number;
  stop: number;
  offline: number;
}

export interface LaserOeeHistoricalData {
  date: string;

  // percentages
  oee: number;
  availability: number;
  performance: number;
}

export interface LaserCuttingTimeHistoricalData {
  date: string;

  // hours
  unknown: number | null;
  channel0: number | null;
  channel1: number | null;
  channel2: number | null;
}

export interface LaserStarveAndBlockHistoricalData {
  date: string;

  // minutes
  starve: number;
  block: number;
}

export interface BendingPerformancePartData {
  date: string;

  // minutes
  simple: number | null;
  medium: number | null;
  complex: number | null;
}

export interface BendingAvailabilityHistoricalData {
  date: string;

  // hours
  bend: number;
  program: number;
  setup: number;
  warning: number;
  error: number;
}

export interface BendingTimeBetweenBendHistoricalData {
  date: string;

  // seconds
  simple: number | null;
  medium: number | null;
  complex: number | null;
}

export interface BendingPerformancePartHistoricalData {
  date: string;

  // minutes
  simple: number | null;
  medium: number | null;
  complex: number | null;
}

export interface BendingPerformanceHistoricalData {
  date: string;
  tool_changes: number;
  part_types: number;
}

export interface BendProductivityFiguresData {
  /** Name of the figure ('bend-count', 'tool-changes-count'...). */
  name: BendProductivityFiguresName;
  // In customer's unit, depending on the unit of the figure
  currentTotalValue: number;
  // In customer's unit, depending on the unit of the figure
  previousTotalValue: number;
  chartData: BendProductivityFiguresChartData[];
}

export interface BendShiftStatisticsData {
  shiftId: number;
  shiftName: string;
  shiftModelName: string;
  deviceStatistics: BendDeviceShiftStatistics[];
  deviceStatisticsTotals?: ShiftStatistics;
}

export interface ShiftStatistics {
  // Productivity
  toolChanges: number;
  weight: number;
  weightHandled: number;
  bendsHour: number;

  // Flow speed (seconds)
  highFlow: number;
  lowFlow: number;
  noFlow: number;
	highFlowPercent: number;
  lowFlowPercent: number;
  noFlowPercent: number;

  // ProductionMode (seconds)
  automatic: number;
  semiAutomatic: number;
	nonProductive: number;
  automaticPercent: number;
  semiAutomaticPercent: number;
  nonProductivePercent: number;
}

export interface BendDeviceShiftStatistics extends ShiftStatistics {
  deviceId: string;
  deviceName: string;
}

export interface RankingDataOfBendPart {
  bestFlowData: BendPartDataEntry[];
  worstFlowData: BendPartDataEntry[];
}

export interface BendPartDataEntry {
  deviceId: string;
  partName: string;
  deviceName: string;
  materialName: string;
  weight: number;
  totalBends: number;
  flowPercent: number;
}

export enum BendProductivityFiguresName {
  BendCount = 'bend-count',
  ToolChangesCount = 'tool-changes-count',
  ProducedWeight = 'produced-weight',
  WeightHandledByOperator = 'weight-handled-by-operator',
}

export interface BendProductivityFiguresChartData {
  /**
   * ISO Date-time in the customer's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;
  // In customer's unit, depending on the unit of the figure
  value: number;
}

export enum BendStateOverview {
  ProductionMode = 'productionMode',
  FlowSpeed = 'flowSpeed',
}

export interface BendStatesOverviewData {
  current_period: BendStatePeriodData;
  previous_period: BendStatePeriodData;
}

export interface BendStatePeriodData {
  productionMode: ProductionModeStatesData[];
  flowSpeed: FlowSpeedStatesData[];
}

// These numerical values represent the duration in seconds that the machine has been in each state in that time period
export interface BendStateDurationData<T> {
  state: T;
  seconds: number;
}

export type ProductionModeStatesData = BendStateDurationData<BendState>;
export type FlowSpeedStatesData = BendStateDurationData<BendSpeedFlowStates>;

export interface LaserOutputScrapData extends DataWithTimeSpan {
  group_name: string;
  output: number; // Kg/Lb
  scrap: number; // Kg/Lb
}

export interface LaserOutputScrapHistoricalData {
  date: string;
  group_name: string;
  output: number; // Kg/Lb
  scrap: number; // Kg/Lb
}

export interface OpenSalesFunnelData {
  open_quotes: number;
  quotes: number;

  open_sold: number;
  sold: number;

  open_invoiced: number;
  invoiced: number;
}

export interface LaserProductivityData extends DataWithTimeSpan {
  work: number; // hours
  rest: number; // hours
}

export enum POStatus {
  Planned = 'Planned',
  FirmPlanned = 'Firm Planned',
  Released = 'Released',
  Finished = 'Finished',
}

export enum ReleasedPOStatus {
  Planned = 'Planned',
  FirmPlanned = 'Firm Planned',
  Prepared = 'Prepared',
  InShopFloor = 'In Shop Floor',
  InProgress = 'In Progress',
  Produced = 'Produced',
}

// Must be sorted by status for generator to work
export enum POLStatus {
  Planned = 'Planned',
  FirmPlanned = 'Firm Planned',
  Prepared = 'Prepared',
  InShopFloor = 'In Shop Floor',
  InProgressDone = 'In Progress (done)',
  InProgressRemaining = 'In Progress (remaining)',
  Produced = 'Produced',
}

export interface ManufacturingPOLStatusDueDateData {
  status: POLStatus;
  range_start: number;
  range_end: number;
  estimated_time_hours: number;
}

export interface ManufacturingPOFinishedDueDateData {
  range_start: number;
  range_end: number;
  estimated_time_hours: number;
}

export interface POTreemapData {
  estimated_time: number; // hours
  status: POStatus | ReleasedPOStatus;
  routing: string;
  material: string;
  thickness: number;
  nPOs: number;
  nPOLs: number;
}

export enum Category {
  A = 'A',
  B = 'B',
  C = 'C',
}

export interface QuotesAbcCustomersData {
  name: string;
  no_: string;
  converted_amount: number;
  amount: number;
  n_quotes: number;
  n_accepted: number;
  category: Category;
  conversion_rate: number;
  new_customer: boolean;
  overdue: boolean;
}

// Used for the gauge in client details too
export interface QuotesConversionRatesData {
  category: Category;
  amount_conv_rate: number;
  quotes_conv_rate: number;
}

export interface QuotesCustomerEvolutionData {
  date: string; // YYYY-MM
  converted_amount: number;
  amount: number;
  bill_to_customer_no_: string;
}

export interface QuotesMaterialsData {
  date: string; // YYYY-MM
  mat_group: string;
  weight: number; // Kg/Lb
  converted_weight: number; // Kg/Lb
}

export interface QuotedMaterialsListData {
  mat_group: string;
  weight: number; // Kg/Lb
  converted_weight: number; // Kg/Lb
}

export interface QuotesSizeData {
  bin_start: number;
  bin_end: number;

  not_open_quotes: number;
  open_quotes: number;

  divisor: number | null;
}

export interface QuotesSizeFilterData {
  group_limit: number;
}

export interface SalesCustomerGroupsData {
  customer: string;
  category_paid: Category;

  quotes_converted: number;
  quotes_not_converted: number;

  sales: number;
  sales_invoiced: number;
  sales_not_invoiced: number;
  category_sales: Category;

  invoices_paid: number;
  invoices_not_paid: number;
  category_invoice: Category;

  new_customer: number;
  payments_pending: number;
  invoices_expired: number;
}

export interface SalesCustomerGroupsDetailedData {
  customer: string;
  new_customer: boolean;
  category_paid: Category;

  quotes_converted: number;
  quotes_converted_perc: number;
  quotes_not_converted: number;

  sales: number;
  sales_invoiced: number;
  sales_invoiced_perc: number;
  sales_not_invoiced: number;
  category_sales: Category;

  invoices_paid: number;
  invoices_paid_perc: number;
  invoices_not_paid: number;
  category_invoice: Category;

  payments_pending: boolean;
  invoices_expired: boolean;
}

export interface SalesFunnelData {
  invoiced: number;
  invoiced_target: number;

  paid: number;
  paid_target: number;

  quotes: number;
  quotes_target: number;

  quotes_converted: number;
  quotes_converted_target: number;

  sold: number;
  sold_target: number;
}

export interface SalesTopBySalesData {
  Salesperson: string;
  total_sales: number;
  total_converted: number;
  total_invoice: number;

  // detailed modal fields
  amount_conversion: number | null;
  quotes_conversion: number | null;
  sales_item_changes: number;
  number_of_sales: number;
}

export interface SalesPerSalespersonTimelineData {
  salesperson: string;
  category: Category;
  amount: number;
  date: string;
}

export interface UnpaidSalesDistributionData {
  bin_start: number;
  bin_end: number;
  freq_not_invoiced: number;
  freq_not_paid_expired: number;
  freq_not_paid_not_expired: number;
  expired_days: number;
}

export interface TemperaturesData extends CuttingHeadVersionInformation {
  /**
   * ISO Date-time in the tenant's time zone but faked as local (*).
   *
   * Formats: YYYY-MM-ddTHH:mm:ss (ISO UTC without Z [local time zone]),
   * 'YYYY-MM-dd', 'YYYY-W1', 'YYYY-MM', 'YYYY-Q1', 'YYYY', depending on the
   * date grouping.
   *
   * (*) See explanation at the end of the file
   */
  bucket: string;

  // ºC.
  // Nullable because there might be a head version change but not temperature data.
  capacitiveTemperature: number | null;
  capacitiveTemperatureDifference: number | null;

  controllerConnectionTemperature: number | null;
  controllerConnectionTemperatureDifference: number | null;

  mirrorConnectionRHTTemperature: number | null;
  mirrorConnectionRHTTemperatureDifference: number | null;

  // Only available for cutting head version >= 4
  opticsModuleTemperature: number | null;
  opticsModuleTemperatureDifference: number | null;
}

export interface QuotesFiguresData {
  nQuotes: number;
  nAccepted: number;
  nRejected: number;
  nCustomersPerGroup: string;
  nCustomers: number;
  newCustomers: number;
  avgConversionTime: number;
  avgAmount: number;
  avgConvertedAmount: number;
}

export interface SalesFiguresData {
  nSalesOrders: number;
  to_be_paid_amount: number;
  overdue_invoices_amount: number;
  nCustomersPerGroup: string;
  nCustomers: number;
  newCustomers: number;
  maxAmount: number;
  avgAmount: number;
  invoiced_amount: number;
}

export interface BusinessOverviewFiguresData {
  open_quotes: string;
  open_orders: string;
  open_invoices: string;
  quotes_amount: number;
  quotes_converted_amount: number;
  orders_amount: number;
  newCustomers: number;
  nCustomers: number;
  overdue_invoices: string;
}

export interface ManufacturingFiguresData {
  total_estimated_hours: number;
  total_executed_hours: number;
  ratio_est_actual: number;
  'delayPO/delayPOL': string;
  scrap_ratio: number;
  estimated_capacity: number;
  actual_capacity: number;
}

export interface CutCurrentWorkloadDataMessage {
  id: number;
  messageLevel: MessageType;
  messageName: string;
  messageSource: string;
  messageText: string;
  tenantId: number;
  timestamp: Date;
  timestampUtc: Date;
}

export interface CutCurrentWorkloadDataPlanInfo {
  material: string;
  estimatedCuttingTimeSeconds: number;
  /** Always in millimeters. */
  materialWidth: number; // x
  /** Always in millimeters. */
  materialHeight: number;
  /** Always in millimeters. */
  materialThickness: number;
  name: string;
  completedParts: number;
  progress: number;
  completedRuns: number;
  sequence: number;
  startTimestamp: Date | null;
  totalParts: number;
  totalRuns: number;
  deviation: number | null;
  expectedEndDate: Date | null;
  ncFilePath: string | null; // NC: numeric control
}

export interface CutDeviceCurrentWorkloadData {
  deviceId: string;
  deviceName: string;
  deviceModel: string;
  lastStatus: MachineState;
  cuttingPlanInfo: CutCurrentWorkloadDataPlanInfo[];
  currentMessages: CutCurrentWorkloadDataMessage[];
}

export type CutDeviceCurrentWorkloadResponseEntry = Omit<
  CutDeviceCurrentWorkloadData,
  'deviceName' | 'deviceModel'
>;

/**
 * About date-times in the Technology and Care consoles:
 *
 * We show the chart date-times in these consoles as if the user was in
 * the time zone of the tenant (customer). This way it's easier to match the
 * moment something happened with the time it happened from the point of view of
 * the customer.
 *
 * As ECharts doesn't support specifying the time zone used in a chart, we have
 * to transform the date-times so they are shown in the customer's time zone.
 * The backend sends the date-times as is if they were in the local time zone
 * but with the offset difference applied.
 */
