
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { dateRangeToDateStrings } from '@/utils/dates';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LayoutSlot } from '@/views/dashboard/layout-grid';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class Laser extends Vue {
  @Prop()
  private deviceId!: string;

  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  private gridLayout: LayoutSlot[] = [
    { column: 0, row: 0, width: 8, height: 8 },
    { column: 8, row: 0, width: 4, height: 4 },
    { column: 8, row: 4, width: 4, height: 4 },
  ];

  private get widgetDefinitions(): WidgetDefinition[] {
    return [
      this.consumptionTimelineWidgetDefinition,
      this.availableVsUsedTimesWidgetDefinition,
      this.powerUtilizationTimesWidgetDefinition,
    ].map((wd, index) => wd.withIndex(index));
  }

  private get powerUtilizationTimesWidgetDefinition() {
    return new WidgetDefinition(
      WidgetEnum.TechnologyLaserPowerUtilizationTimes,
      this.deviceId!,
      this.stringDateRange,
      [],
    );
  }

  private get consumptionTimelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.TechnologyLaserConsumptionTimeline,
      this.deviceId!,
      this.stringDateRange,
      [],
      undefined,
      this.dateGrouping,
    );
  }

  private get availableVsUsedTimesWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.TechnologyLaserUsedVsNotUsedTimes,
      this.deviceId!,
      this.stringDateRange,
      [],
    );
  }

  private get stringDateRange(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }

  private getWidgetHelpPosition(index: number): 'is-bottom-left' | 'is-bottom-right' {
    if (index === 0) {
      return 'is-bottom-right';
    }
    return 'is-bottom-left';
  }
}
