// --------------------------------------------------------------------------------
// <copyright file="validation.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import {
  ValidationRuleWithParams,
  ValidatorFn,
  ValidationRule,
  ValidationRuleWithoutParams,
} from '@vuelidate/core';
import { ValidationRule as VeeValidateValidationRule } from 'vee-validate/dist/types/types';
import { User, UserAuthenticationType } from '@/models/user';
import i18n from '@/i18n';
import { createI18nMessage, helpers, TranslationFunction } from '@vuelidate/validators';
import * as validators from '@vuelidate/validators';
import { isEmpty } from './misc';
import { Ref, unref } from 'vue';

export const password_repeat: VeeValidateValidationRule = {
  validate: (value, args) => (args as any[])[0] === value,
};

const t = i18n.t.bind(i18n) as typeof TranslationFunction;
const withI18nMessage = createI18nMessage({
  t,
  messagePath: ({ $validator }) => `validation.${$validator}`,
});

export const required = withI18nMessage(validators.required);
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const email = withI18nMessage(validators.email);
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });
export const or = withI18nMessage(validators.or, { withArguments: true });
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true });

export type ValidationRules<T = any> = Record<string, ValidationRule<T>>;

export const withLocalizedValidators = (
  key: string,
  validators: ValidationRules,
): ValidationRules => {
  return Object.fromEntries(
    Object.entries(validators).map(([name, validator]) => [
      name,
      helpers.withParams({ fieldName: i18n.t(key).toString() }, validator),
    ]),
  );
};

export function passwordsMatch(otherPassword: Ref<string> | string): ValidationRuleWithParams {
  const $validator: ValidatorFn<string, User> = (value, siblings) => {
    return (
      siblings.authenticationType !== UserAuthenticationType.Local ||
      (unref(value) === unref(otherPassword) && !isEmpty(value))
    );
  };
  return {
    $validator,
    $message: () => i18n.t('validation.password_repeat').toString(),
    $params: {
      otherPassword,
    },
  };
}

export function optional<T, P extends object = object>(
  validator: ValidationRuleWithParams<P, T> | ValidationRuleWithoutParams<T>,
): ValidationRule {
  const $params = {
    ...('$params' in validator ? validator.$params : {}),
    validator,
  };
  return {
    ...validator,
    $validator: (value, siblings, vm) => {
      if (!helpers.req(value)) {
        return true;
      }
      return validator.$validator(value, siblings, vm);
    },
    $params,
  };
}
