// --------------------------------------------------------------------------------
// <copyright file="bendRankingOfPartsRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { RankingDataOfBendPart } from '../chartsData';
import { mockBendRankingOfPartsData } from '../mockWidgetSelectorData';
import { devicesService } from '@/services/devices.service';

export class BendRankingOfPartsRetriever extends DataRetriever<RankingDataOfBendPart> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<RankingDataOfBendPart | null> {
    return await mockBendRankingOfPartsData(
      selectedDevices.length === 0
        ? devicesService.store.bendDevices().map((b) => b.deviceId)
        : selectedDevices,
    );
    // Pending implementation in the backend.
    // return metricsService.getDevicesMetrics<BendShiftStatisticsData[]>(
    //   this.procedure,
    //   {
    //     tenantIdDh: this.tenantIdDh,
    //     deviceIds: selectedDevices,
    //     shifts: selectedShifts,
    //     timeSpan: timeSpan as FilterTimeSpanEnum,
    //   },
    //   this.controller,
    // );
  }
}
