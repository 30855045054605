
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import ShiftFilter from '@/components/ShiftFilter.vue';
import { MachineType } from '@/models/enums/MachineType';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import TimeSpanFilter from '@/components/TimeSpanFilter.vue';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import { Shift } from '@/models/shift';
import moment from 'moment';
import { devicesService } from '@/services/devices.service';
import CutLayout from '@/components/common/CutLayout.vue';
import MultipleSelectionFiltersHeader from '@/components/MultipleSelectionFiltersHeader.vue';
import { isEmpty, isNil } from '@/utils/misc';
import EquipmentFilter from '@/components/EquipmentFilter.vue';
import { Route } from 'vue-router';
import { CutLayoutTab } from '@/components/common/CutLayoutTab';
import { getActiveShifts } from '@/utils/page-filters';

@Component({
  computed: {
    MachineType: () => MachineType,
  },
  components: {
    EquipmentFilter,
    MultipleSelectionFiltersHeader,
    ShiftFilter,
    DateGroupingFilter,
    TimeSpanFilter,
    CutLayout,
  },
})
export default class Cut extends Vue {
  @Prop()
  private selectedDeviceStringId?: string;

  private activeTab = CutLayoutTab.CutOverview;
  private dateRangeSelection: [Date, Date] = [moment().subtract(6, 'days').toDate(), new Date()];
  private selectedTimeAxisSpan = FilterTimeAxisSpanEnum.Day;
  private selectedTimespan = FilterTimeSpanEnum.Day;
  private selectedShiftIds: number[] = [];
  private selectedDeviceIds: string[] = [];

  @Ref('dateRangePicker')
  private dateRangePicker: any;

  // Updates the active tab when the hash changes by pasting a new URL or editing it
  private beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    // .substring removes initial '#' from hash
    const hash = to.hash?.substring(1);
    if (hash !== CutLayoutTab[this.activeTab]) {
      this.activeTab = CutLayoutTab[hash as keyof typeof CutLayoutTab];
    }
    return next();
  }

  private async created() {
    this.selectedDeviceIds = this.selectedDeviceStringId ? [this.selectedDeviceStringId] : [];
    await this.syncActiveTabWithRouteHash();
  }

  private get showTimeSpanFilter(): boolean {
    return this.activeTab === CutLayoutTab.CutOverview;
  }

  private get showDateRangeFilter(): boolean {
    return this.activeTab === CutLayoutTab.CutTrends;
  }

  private get showDateGroupingFilter(): boolean {
    return this.activeTab === CutLayoutTab.CutTrends;
  }

  private get showShiftFilter(): boolean {
    return this.activeTab !== CutLayoutTab.CutCurrentWorkload;
  }

  private async syncActiveTabWithRouteHash() {
    if (isEmpty(this.$route.hash)) {
      await this.updateRouteHashWithActiveTab();
    } else {
      this.activeTab = CutLayoutTab[this.$route.hash.substring(1) as keyof typeof CutLayoutTab];

      if (isNil(this.activeTab)) {
        this.activeTab = CutLayoutTab.CutOverview;
        await this.updateRouteHashWithActiveTab();
      }
    }
  }

  private async updateRouteHashWithActiveTab() {
    await this.$router.replace({
      hash: CutLayoutTab[this.activeTab],
    });
  }

  private async onActiveTabChanged(tab: CutLayoutTab) {
    this.activeTab = tab;

    // Avoid 'redundant navigation' error on page reload
    if (!this.$route.hash.includes(CutLayoutTab[this.activeTab])) {
      await this.updateRouteHashWithActiveTab();
    }
  }

  private get activeDeviceIds(): string[] {
    return this.selectedDeviceIds.length !== 0 ? this.selectedDeviceIds : this.deviceIds;
  }

  private get activeDevices(): Device[] {
    return this.devices.filter((device) => this.activeDeviceIds.includes(device.deviceId));
  }

  private get activeShifts(): Shift[] {
    return getActiveShifts(this.selectedShiftIds, this.selectedDeviceIds, this.devices);
  }

  private toggleDatepicker() {
    this.dateRangePicker.toggle();
  }

  private get isTrendsTabActive(): boolean {
    return this.$route.hash.toLowerCase().includes('trends');
  }

  private get devices(): Device[] {
    return devicesService.store.cutDevices();
  }

  private get deviceIds(): string[] {
    return this.devices.map((device: Device) => device.deviceId);
  }
}
