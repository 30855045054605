// --------------------------------------------------------------------------------
// <copyright file="lineChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import i18n from '@/i18n';
import { Color, getStateColour } from '@/utils/color';
import { WidgetEnum } from '../enums/WidgetEnum';
import { Tenant } from '../tenant';
import {
  EChartsOption,
  LineSeriesOption,
  MarkAreaComponentOption as MarkAreaOption,
  MarkLineComponentOption as MarkLineOption,
  XAXisComponentOption as XAXisOption,
} from 'echarts';
import { GeneratorParams } from './generatorParams';
import { Logger } from '@/utils/logger';
import { LineChartMode } from '../enums/LineChartMode';
import {
  mockLaserAvailabilityHistData,
  mockLaserProductivityHistData,
  mockLaserOeeHistData,
  mockLaserCuttingTimeHistData,
  mockLaserStarveBlockHistData,
  mockBendingPerformancePartData,
  mockBendingAvailabilityHistData,
  mockBendingTimeBetweenBendHistData,
  mockBendingPerformancePartHistData,
  mockBendingPerformanceHistData,
  mockBendingNumberOfBendsTimelineData,
} from './mockWidgetSelectorData';
import { isEmpty } from '@/utils/misc';
import { isCategoryXAxis } from '@/utils/charts';
import { abbreviateNumber } from '@/utils/number';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { AxisPointerLabelFormatter } from '@/models/Charts/axisPointerLabelFormatter';
import { areArraysEqual } from '@/utils/array';
import { metricsService } from '@/services/metrics.service';
import { tenantsService } from '@/services/tenants.service';
import { Aggregate } from '@/models/metrics/aggregates';

/**
 * Generic chart generator for line and bar (stacked columns) charts.
 *
 * Although the name implies it's only for line charts, it's also used for
 * stacked bar charts. Usually charts that can switch between the two modes,
 * like the Availability chart in Work center console > Cut > Trends.
 *
 * Returns Echarts options to draw a line/bar chart with different parameters
 * that change how it is drawn:
 *
 * - connectNulls: boolean. Links nearby line dots (symbols) when true.
 *   Otherwise, the line is drawn with a gap when a null is found.
 * - xAxisName: Name (title) to show on the x-axis.
 * - yAxisName: Name (title) to show on the y-axis.
 * - dateKey: Name of the key from the data structure to get the date for the
 *   x-axis (`data[dateKey]`).
 * - roundValues: boolean. Rounds the numeric values shown in the tooltip when
 *   true.
 * - timeAxisSpan: FilterTimeAxisSpanEnum. Defines the date grouping used.
 * - aggregates: string[]. Aggregates to show in the chart. Possible values:
 *   'Max', 'Min', 'Average'.
 * - targets: Defines targets to show in the chart as Echart's mark line or area.
 */
export class LineChartGenerator extends ChartGenerator<any[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ) {
    if (Array.isArray(timeSpan)) {
      if (this.procedure === WidgetEnum.CareCuttingHeadTemperatures) {
        return metricsService.getSSCMetrics<any[]>(
          this.procedure,
          this.tenantIdDh,
          selectedDevices[0],
          {
            dateFrom: timeSpan[0],
            dateTo: timeSpan[1],
            dateGrouping: timeAxisSpan,
          },
          this.controller,
        );
      } else {
        return metricsService.getDevicesMetrics<any[]>(
          this.procedure,
          {
            tenantIdDh: this.tenantIdDh,
            deviceIds: selectedDevices,
            shifts: selectedShifts,
            startDate: timeSpan[0],
            endDate: timeSpan[1],
            timeAxisSpan,
          },
          this.controller,
        );
      }
    } else {
      return metricsService.getDevicesMetrics<any[]>(
        this.procedure,
        {
          tenantIdDh: this.tenantIdDh,
          deviceIds: selectedDevices,
          shifts: selectedShifts,
          timeSpan,
        },
        this.controller,
      );
    }
  }

  marker(
    targets: number[],
    key: string,
    index: number,
  ): { markLine?: MarkLineOption; markArea?: MarkAreaOption } {
    switch (this.procedure) {
      case WidgetEnum.LaserStarveBlockHist:
      case WidgetEnum.LaserProductivityHist:
      case WidgetEnum.TubeStarveBlockHist:
      case WidgetEnum.TubeProductivityHist: {
        const targetIndex = key === 'starve' || key === 'productivity' ? 0 : 1;
        return {
          markLine: {
            data: [{ yAxis: targets[targetIndex] }],
            lineStyle: { type: 'dotted' },
            symbol: 'none',
          },
        };
      }
      case WidgetEnum.LaserOeeHist:
      case WidgetEnum.LaserCuttingTimeHist:
      case WidgetEnum.TubeOeeHist:
      case WidgetEnum.TubeCuttingTimeHist:
        return index === 0
          ? {
              markArea: {
                itemStyle: {
                  color: Color.Green,
                  opacity: 0.1,
                },
                data: [
                  [
                    {
                      yAxis: targets[0],
                    },
                    {
                      yAxis: Number.MAX_SAFE_INTEGER,
                    },
                  ],
                ],
              },
            }
          : {};
      default:
        return {};
    }
  }

  override updateOptions(
    data: any[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const dateKey = parameters.dateKey ?? 'date';
    const series: LineSeriesOption[] = [];
    const keys: string[] = Object.keys(data[0]);
    const seriesNames: string[] = keys.filter((x) => x !== dateKey);

    const roundValues = parameters.roundValues ?? false;

    const lineChartMode = parameters.paramValues?.mode ?? LineChartMode.NonStackedLines;
    const type =
      lineChartMode === LineChartMode.StackedColumns ? ('bar' as const) : ('line' as const);
    const stack = lineChartMode === LineChartMode.StackedColumns ? 'total' : undefined;
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);

    const averageMark: any[] = [];
    const markPointData: any[] = [];

    if (parameters.aggregates) {
      for (const aggregate of parameters.aggregates) {
        if (aggregate === Aggregate.Max || aggregate === Aggregate.Min) {
          markPointData.push({ name: '', type: aggregate });
        } else {
          averageMark.push({ type: 'average' });
        }
      }
    }

    const seriesName = (key: string) => {
      if (this.procedure === WidgetEnum.LaserCuttingTimeHist) {
        if (
          data.every(
            (x) =>
              x.channel0 === null &&
              x.channel1 === null &&
              x.channel2 === null &&
              x.channel3 === null,
          )
        ) {
          return i18n.t('report.cutting_time').toString();
        }
        return Tenant.gasName(this.tenant, key);
      } else if (this.procedure === WidgetEnum.TubeCuttingTimeHist) {
        if (data.every((x) => areArraysEqual(Object.keys(x), ['date', 'unknown']))) {
          return i18n.t('report.cutting_time').toString();
        } else {
          return key === 'Unknown' ? i18n.t('report.unknown').toString() : key;
        }
      } else {
        return i18n.t(`report.${key}`).toString();
      }
    };
    seriesNames.forEach((key: string, index: number) => {
      const marker: { markLine?: MarkLineOption; markArea?: MarkAreaOption } = parameters.targets
        ? this.marker(parameters.targets, key, index)
        : {};
      if (!isEmpty(averageMark) && marker.markLine !== undefined) {
        marker.markLine.data!.push(averageMark[0]);
      }

      series.push({
        data: this.getSeriesData(key, dateKey, data, isCategoryAxis),
        type: type as any,
        stack,
        connectNulls: parameters.connectNulls,
        name: seriesName(key),
        emphasis: { focus: 'series' },
        markPoint: {
          data: markPointData,
          label: {
            formatter: (params: any) => i18n.n(params.value, { maximumFractionDigits: 2 }),
          },
        },
        markLine: {
          data: averageMark,
          label: {
            formatter: (params: any) => i18n.n(params.value, { maximumFractionDigits: 2 }),
          },
        },
        itemStyle:
          this.procedure !== WidgetEnum.LaserAvailabilityHist &&
          this.procedure !== WidgetEnum.TubeAvailabilityHist
            ? undefined
            : {
                color: getStateColour(key),
              },
        ...marker,
      });
    });

    return {
      title: {
        show: false,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
            formatter: new AxisPointerLabelFormatter(
              parameters.timeAxisSpan,
              isCategoryAxis,
              roundValues,
            ).get(),
          },
          animation: false,
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: TooltipFormatter.build(parameters.timeAxisSpan, roundValues)
          .withSeriesTranslationPrefix(undefined)
          .get(),
      },
      legend: {
        bottom: 0,
        type: 'scroll',
        selectedMode: series.length === 1 ? false : 'multiple',
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          show: false,
          realtime: true,
          height: '2',
          top: '95%',
        },
        {
          show: true,
          type: 'inside',
          realtime: true,
        },
      ],
      grid: {
        top: 50,
        bottom: 40,
        left: 5,
        containLabel: true,
      },
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        data: isCategoryAxis ? data.map((x: any) => x[dateKey]) : undefined,
        name: parameters.xAxisName ?? '',
        axisLabel: {
          hideOverlap: true,
        },
      } as XAXisOption, // type=category doesn't have data property
      yAxis: {
        type: 'value',
        name: parameters.yAxisName ?? '',
        nameTextStyle: {
          // Avoid long names being cut
          align: (parameters.yAxisName?.length ?? 0) > 3 ? 'left' : undefined,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f4f4f4',
            width: 1,
          },
        },
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },
      series,
    };
  }

  override getMockData(): any[] | null {
    switch (this.procedure) {
      case WidgetEnum.LaserAvailabilityHist:
      case WidgetEnum.TubeAvailabilityHist:
        return mockLaserAvailabilityHistData();
      case WidgetEnum.LaserProductivityHist:
      case WidgetEnum.TubeProductivityHist:
        return mockLaserProductivityHistData();
      case WidgetEnum.LaserOeeHist:
      case WidgetEnum.TubeOeeHist:
        return mockLaserOeeHistData();
      case WidgetEnum.LaserCuttingTimeHist:
      case WidgetEnum.TubeCuttingTimeHist:
        return mockLaserCuttingTimeHistData();
      case WidgetEnum.LaserStarveBlockHist:
      case WidgetEnum.TubeStarveBlockHist:
        return mockLaserStarveBlockHistData();
      case WidgetEnum.BendingPerformancePart:
        return mockBendingPerformancePartData();
      case WidgetEnum.BendingAvailabilityHist:
        return mockBendingAvailabilityHistData();
      case WidgetEnum.BendingTimeBetweenBendHist:
        return mockBendingTimeBetweenBendHistData();
      case WidgetEnum.BendingPerformancePartHist:
        return mockBendingPerformancePartHistData();
      case WidgetEnum.BendingPerformanceHist:
        return mockBendingPerformanceHistData();
      case WidgetEnum.BendingNumberOfBendsTimeline:
        return mockBendingNumberOfBendsTimelineData();
      default:
        Logger.error(`Missing mock data for ${this.procedure}`);
        return null;
    }
  }

  private getSeriesData(key: string, dateKey: string, dataArray: any[], isCategoryAxis: boolean) {
    return isCategoryAxis
      ? dataArray.map((x: any) => x[key])
      : dataArray.map((x: any) => [x[dateKey], x[key]]);
  }

  private get tenant() {
    return tenantsService.store.current()!;
  }
}
