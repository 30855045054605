
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { DeviceModelType } from '@/models/deviceBaseModel';
import { getAllDevicesByCustomerId } from '@/use-cases/devices';

@Component
export default class DevicesSelect extends Vue {
  @Prop({ default: '' })
  private value!: string;

  @Prop({ required: true })
  private customerId!: number;

  @Prop({ default: false })
  private required!: boolean;

  @Prop({ default: false })
  private disabled!: boolean;

  @Prop({ default: false })
  private includeAll!: boolean;

  @Prop()
  private deviceType?: DeviceModelType;

  private items: Device[] = [];

  private isLoading = false;

  private mounted(): void {
    this.getItems();
  }
  private async getItems() {
    this.isLoading = true;
    try {
      const devices = await getAllDevicesByCustomerId(this.customerId);
      switch (this.deviceType) {
        case DeviceModelType.Laser:
          this.items = devices.filter((x) => x.isCut());
          break;
        case DeviceModelType.Bending:
          this.items = devices.filter((x) => x.isBend());
          break;
        case DeviceModelType.Tubes:
          this.items = devices.filter((x) => x.isTube());
          break;
        default:
          this.items = devices;
          break;
      }
      if (this.includeAll) {
        const allDevices = Device.GetEmpty();
        allDevices.name = 'All';
        this.items.unshift(allDevices);
      }
    } finally {
      this.isLoading = false;
    }
  }

  private handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
