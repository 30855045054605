
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableActionsList from './TableActionsList.vue';
import { addAutoRemovedEventListener } from '@/utils/misc';

@Component({
  components: {
    TableActionsList,
  },
})
export default class TableColumnActionsWrapper extends Vue {
  /**
   * Defines how the row actions are displayed (only on hover/ always).
   *
   * See `TableWithActions2.rowActionsDisplayMode` for more details.
   */
  @Prop({ default: 'on-hover' })
  private displayMode!: 'on-hover' | 'always';

  private showMobileVersion = this.shouldShowMobileVersion();

  private shouldShowMobileVersion() {
    return window.innerWidth <= 768;
  }

  private updateMobileVersionSetting() {
    this.showMobileVersion = this.shouldShowMobileVersion();
  }

  private mounted() {
    addAutoRemovedEventListener(this, 'resize', this.updateMobileVersionSetting);
  }
}
