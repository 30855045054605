
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { dateRangeToDateStrings } from '@/utils/dates';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { LayoutSlot } from '@/views/dashboard/layout-grid';
import { defaultDateRange } from '@/views/careConsole/commons/utils';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class LaserConsumption extends Vue {
  @Prop({ required: true, default: () => defaultDateRange() })
  private dateRange!: [Date, Date];

  @Prop({ required: true, default: FilterTimeAxisSpanEnum.Month })
  private timeAxisSpan!: FilterTimeAxisSpanEnum;

  @Prop()
  private deviceId!: string;

  private gridLayout: LayoutSlot[] = [
    { column: 0, row: 0, width: 8, height: 8 },
    { column: 8, row: 0, width: 4, height: 4 },
    { column: 8, row: 4, width: 4, height: 4 },
  ];

  private get widgetDefinitions(): WidgetDefinition[] {
    return [
      this.consumptionTimelineWidgetDefinition,
      this.availableVsUsedTimesWidgetDefinition,
      this.powerUtilizationTimesWidgetDefinition,
    ].map((wd, index) => wd.withIndex(index));
  }

  private get consumptionTimelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareLaserConsumptionTimeline,
      this.deviceId!,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.timeAxisSpan,
    );
  }

  private get availableVsUsedTimesWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareLaserConsumptionAvailableVsUsedTimeline,
      this.deviceId!,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.timeAxisSpan,
    );
  }

  private get powerUtilizationTimesWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareLaserConsumptionPowerUtilizationTimeline,
      this.deviceId!,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.timeAxisSpan,
    );
  }

  private getWidgetHelpPosition(index: number): 'is-bottom-left' | 'is-bottom-right' {
    if (index === 0) {
      return 'is-bottom-right';
    }
    return 'is-bottom-left';
  }
}
